import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LaborTime, OtherItem, Reference } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Estimate';
import { pdf } from '@react-pdf/renderer';
import { ROUTER_CART } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { CarIcon, InfoIcon, PrintIcon } from 'assets/icons';
import { Dialog, InfoDialog, useDisclosure } from 'components/Dialog';
import SendEmailDialog from 'components/SendEmailDialog';
import { get750ChunkReferences, getChunkReferences } from 'domains/appContext/AppContext.store';
import {
  getEmailFromGarageData,
  setEmailFromGarageAttachments,
  setEmailFromGarageData,
} from 'domains/email/Email.store';
import {
  addCatalogReferencesToBasket,
  addVehicle,
  getCatalogReferencesLength,
  getCatalogTiresLength,
  getEstimateById,
  getEstimateLogo,
  getEstimateVehicle,
  sendEstimateToDMSRequest,
} from 'domains/estimate/Estimate.store';
import { getDmsWorkshopId, getUserCommercialLink, getUserDisplayMode } from 'domains/user';
import { CatalogButton, ChangeVehiclePopup } from 'pages/CatalogPage/common';
import { PdfFilterProps } from 'pages/EstimatePage/EstimateModifyTab';
import { useEstimatePdfData } from 'pages/EstimatePage/useEstimateData';
import { EstimatePage } from 'pages/EstimatePdf/EstimatePdf';
import {
  CartArrowDownButtonRound,
  Checkbox,
  DMSExportButtonRound,
  Flex,
  MarginBox,
  MessageButtonRound,
  NotificationLink,
  notifyTop,
  PrintButtonRound,
  Text,
  YellowButton,
} from 'UI';
import { ERROR, getData, hasData, LOADING } from 'utils';
import {
  TRACKING_EVENT_GO_TO_CART_SHORTCUT,
  TRACKING_EVENT_TRANSFER_ESTIMATE_TO_CART,
} from 'utils/eventTracker/EventTracker.types';

export interface EstimateModifyTabButtonsSectionProps {
  estimateId: string;
  renderAddVehicle?: boolean;
  isDMSAvailable: boolean | typeof LOADING;
}

const EstimatePrintWrapper = ({ estimateId }: { estimateId: string }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [printPdfFilter, setPrintPdfFilter] = useState<PdfFilterProps>({
    hideReferenceNumbers: false,
    hideDiscounts: false,
  });
  const emailData = useSelector(getEmailFromGarageData);
  const commercialLink = useSelector(getUserCommercialLink);
  const estimateLogo = useSelector(getEstimateLogo);
  const isR3Motrio = useSelector(getUserDisplayMode) === 'R3_MOTRIO';
  const disclosurePrint = useDisclosure();
  const { onOpen: onOpenPrint } = disclosurePrint;
  const pdfData = useEstimatePdfData(estimateId);
  const pdfDataCheckChanges = JSON.stringify({
    ...pdfData,
    key: null,
    date: null,
    text: null,
  });

  useEffect(() => {
    const recipient = pdfData.client?.email ?? '';
    const clientName = pdfData.client?.name ?? '';
    const brandModel = `${pdfData.vehicleDetail?.vehicleBrand ?? ''} ${pdfData.vehicleDetail?.model ?? ''}`;
    const garageName = commercialLink?.name ?? '';

    dispatch(
      setEmailFromGarageData({
        ...emailData,
        recipients: [recipient],
        subject: t('estimate.email.subject.your_estimate', 'Your estimate'),
        body:
          `${t('estimate.email.dear_client_name', 'Dear {{clientName}},', { clientName })}\n` +
          `${t('estimate.email.estimate_for_model', 'Please find attached your estimate for your {{brandModel}}.', {
            brandModel,
          })}\n` +
          `${t('common.best_regards', 'Best regards,')}\n` +
          `${garageName}`,
      }),
    );
    // eslint-disable-next-line
  }, [pdfData.client, estimateId]);

  useEffect(() => {
    const setPdfAttachment = async () => {
      const pdfBlob = await getPdfDataBlob();
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = () => {
        const base64data = reader?.result?.toString().replace(/^.*,/, '');
        if (base64data) {
          dispatch(
            setEmailFromGarageAttachments([
              {
                fileName: `${t('estimate', 'Estimate')}.pdf`,
                fileBase64: base64data,
              },
            ]),
          );
        }
      };
    };
    setPdfAttachment().catch(() => setEmailFromGarageAttachments(ERROR));
    // eslint-disable-next-line
  }, [pdfDataCheckChanges, printPdfFilter.hideDiscounts, printPdfFilter.hideReferenceNumbers]);
  const getHideReferencesCheckbox = () => (
    <Checkbox
      checked={printPdfFilter.hideReferenceNumbers}
      onChange={() => {
        dispatch(setEmailFromGarageAttachments(LOADING));
        setPrintPdfFilter((prevState) => {
          return {
            ...prevState,
            hideReferenceNumbers: !prevState.hideReferenceNumbers,
          };
        });
      }}
      label={
        <>
          <MarginBox ml={10} />
          <Text type={'text'}>{t('estimate.hide_information.references', 'Hide all references')}</Text>
        </>
      }
    />
  );

  const getHideDiscountsCheckbox = () => (
    <Checkbox
      checked={printPdfFilter.hideDiscounts}
      onChange={() => {
        dispatch(setEmailFromGarageAttachments(LOADING));
        setPrintPdfFilter((prevState) => {
          return {
            ...prevState,
            hideDiscounts: !prevState.hideDiscounts,
          };
        });
      }}
      label={
        <>
          <MarginBox ml={10} />
          <Text type={'text'}>{t('estimate.hide_information.discounts', 'Hide all discounts')}</Text>
        </>
      }
    />
  );

  const getAdditionalOptions = () => (
    <Flex justify={'flex-end'} align={'flex-end'} size={1}>
      {getHideReferencesCheckbox()}
      {getHideDiscountsCheckbox()}
    </Flex>
  );

  const isEmailEnabled = () => {
    const hasDesignation = (data: LaborTime[] | Reference[] | OtherItem[]) => data.some((d) => d.designation);

    const isAnyValidReference = hasDesignation(pdfData.reference);
    const isAnyValidLaborTime = hasDesignation(pdfData.laborTime);
    const isAnyValidOther = hasDesignation(pdfData.other);
    const isAnyValidTire = hasDesignation(pdfData.tire);

    return isAnyValidReference || isAnyValidLaborTime || isAnyValidOther || isAnyValidTire;
  };

  const handlePrint = async () => {
    const pdfBlob = await getPdfDataBlob();

    const url = URL.createObjectURL(pdfBlob);
    if (url) {
      window.open(url);
    }
  };

  const openSendEmail = {
    element: <MessageButtonRound disabled={!isEmailEnabled()} />,
    disabled: !isEmailEnabled(),
  };

  const getPdfDataBlob = async () => {
    return pdf(
      <>
        <EstimatePage data={pdfData} pdfFilter={printPdfFilter} estimateLogo={isR3Motrio ? estimateLogo : undefined} />
      </>,
    ).toBlob();
  };

  return (
    <>
      <MarginBox my={'auto'} mr={30}>
        <SendEmailDialog
          title={t('estimate.email.title', 'Send estimate by email')}
          triggerElement={openSendEmail}
          additionalOptions={getAdditionalOptions()}
        />
      </MarginBox>
      <MarginBox my={'auto'} mr={30}>
        <PrintButtonRound onClick={onOpenPrint} />
        <Dialog
          disclosure={disclosurePrint}
          title={t('estimate.hide_information.title', 'Would you like to hide any information?')}
          icon={PrintIcon}
          content={
            <>
              <Flex justify={'flex-start'}>{getHideReferencesCheckbox()}</Flex>
              <MarginBox mt={15}>
                <Flex>{getHideDiscountsCheckbox()}</Flex>
              </MarginBox>
            </>
          }
          handleConfirm={handlePrint}
        />
      </MarginBox>
    </>
  );
};
const EstimateModifyTabButtonsSection = ({
  estimateId,
  renderAddVehicle,
  isDMSAvailable,
}: EstimateModifyTabButtonsSectionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const dmsWorkshopId = useSelector(getDmsWorkshopId);
  const [activeAddToCart, setActiveAddToCart] = useState(false);
  const disclosure = useDisclosure();
  const { onOpen, onClose } = disclosure;
  const chunkReferences = useSelector(getChunkReferences);
  const chunk750References = useSelector(get750ChunkReferences);
  const [isAddVehiclePopupVisible, setIsAddVehiclePopupVisible] = useState(false);
  const catalogReferencesCount = useSelector((state: RootState) => getCatalogReferencesLength(state, estimateId));
  const tireReferencesCount = useSelector((state: RootState) => getCatalogTiresLength(state, estimateId));
  const vehicle = useSelector((state: RootState) => getEstimateVehicle(state, estimateId));
  const estimate = useSelector((state: RootState) => getEstimateById(state, estimateId));
  useEffect(() => {
    setActiveAddToCart(catalogReferencesCount + tireReferencesCount > 0);
  }, [catalogReferencesCount, tireReferencesCount]);

  const handleExportToDMS = () => {
    dispatch(sendEstimateToDMSRequest());
  };

  const isAnyChunkOilReference = () => {
    return getData(estimate)?.referenceSubsection.references.some(
      (ref) => chunkReferences.get(ref.referenceNumber) || chunk750References.includes(ref.referenceNumber),
    );
  };

  const handleAddToCartClick = () => {
    if (isAnyChunkOilReference()) {
      onOpen();
    }
    if (catalogReferencesCount + tireReferencesCount === 0) {
      return;
    }
    dispatch(addCatalogReferencesToBasket());
    setActiveAddToCart(false);
    notifyTop(
      'success',
      <Trans i18nKey={'catalog.reference_card.added_to_basket.description'}>
        {'Reference has been added to your cart'}
      </Trans>,
      undefined,
      <NotificationLink
        onClick={() => {
          trackAppEvent(TRACKING_EVENT_GO_TO_CART_SHORTCUT);
          history.push(ROUTER_CART);
        }}
      >
        {t('catalog.reference_card.added_to_basket.go_to_cart', 'Go to cart')}
      </NotificationLink>,
    );
    trackAppEvent(TRACKING_EVENT_TRANSFER_ESTIMATE_TO_CART);
  };

  return (
    <Flex size={1} justify={'flex-end'}>
      <InfoDialog
        title={'Information'}
        handleConfirm={() => undefined}
        disclosure={disclosure}
        icon={InfoIcon}
        description={[
          t('catalog.universal_products.estimate_add_to_cart.first_line', 'Castrol Oil quantities have been changed'),
          t('catalog.universal_products.estimate_add_to_cart.second_line', 'to comply with minimum order'),
        ]}
        content={
          <Flex minWidth={400} direction={'row-reverse'}>
            <YellowButton onClick={onClose}>
              <Flex minWidth={80} justify={'center'}>
                {t('common.ok', 'OK')}
              </Flex>
            </YellowButton>
          </Flex>
        }
      />
      {vehicle === undefined && renderAddVehicle && (
        <MarginBox my={'auto'} mr={30}>
          <CatalogButton
            id={'change_vehicle_button_estimate'}
            title={t('estimate.action.add_vehicle', 'Add vehicle')}
            icon={CarIcon}
            content={
              <ChangeVehiclePopup
                visible={isAddVehiclePopupVisible}
                setIsChangeVehiclePopupVisible={setIsAddVehiclePopupVisible}
                onFound={() => dispatch(addVehicle())}
              />
            }
            contentTitle={t('catalog.parts.action.change_vehicle', 'Change vehicle')}
            isPopoverVisible={isAddVehiclePopupVisible}
            onPopoverVisibleChange={(visible: boolean) => setIsAddVehiclePopupVisible(visible)}
          />
        </MarginBox>
      )}
      {dmsWorkshopId && hasData(estimate) && estimate.dmsOrderNumber && isDMSAvailable && (
        <MarginBox my={'auto'} mr={30}>
          <DMSExportButtonRound onClick={handleExportToDMS} />
        </MarginBox>
      )}
      <MarginBox my={'auto'} mr={30}>
        <CartArrowDownButtonRound onClick={handleAddToCartClick} disabled={!activeAddToCart} />
      </MarginBox>
      <EstimatePrintWrapper estimateId={estimateId} />
    </Flex>
  );
};
export default EstimateModifyTabButtonsSection;
