/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getReturnsFeatureSettings,
  getReturnsFeatureSettingsRequestSaga,
  ReturnsFeatureSettings,
  saveReturnsFeatureSettingsRequestSaga,
  setReturnsFeatureSettings,
} from 'domains/r1Network/R1Network.store';
import { theme } from 'styles';
import { Box, CleverInput, Flex, Text, YellowButton } from 'UI';
import { Switch } from 'UI/Switch';

export const SInput = styled(CleverInput)<{ isHighlighted?: boolean }>`
  height: 40px;
  width: 350px;
  border-color: ${({ isHighlighted }) => (isHighlighted ? theme.color.red : null)};
`;

const useR1Network = (): ReturnsFeatureSettings => {
  const dispatch = useDispatch();
  const r1NetworkStore = useSelector(getReturnsFeatureSettings);
  useEffect(() => {
    dispatch(getReturnsFeatureSettingsRequestSaga());
  }, [dispatch]);
  return r1NetworkStore;
};

const R1Network = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const returnsFeatureSettings = useR1Network();

  const updateFeatureSettings = () => {
    dispatch(saveReturnsFeatureSettingsRequestSaga());
  };

  const setEmail = (email: string | undefined) => {
    dispatch(setReturnsFeatureSettings({ isEnabled: returnsFeatureSettings.isEnabled, email }));
  };

  const setIsEnabled = (isEnabled: boolean) => {
    dispatch(setReturnsFeatureSettings({ isEnabled, email: returnsFeatureSettings.email }));
  };

  return (
    <>
      <Text type={'h2'}>{t('backoffice.r1_network.returns_feature.title', 'Return feature')}</Text>
      <Text type={'section'}>
        {t(
          'backoffice.r1_network.returns_feature.description',
          'If something is not quite right with ordered parts, hassle-free, you can easily return the items directly trough our Rpartstore. You can activate or disable the feature down below. Make sure to provide a dedicated e-mail address',
        )}
      </Text>
      <Box height={30} />
      <Text type={'h3'}>{t('backoffice.r1_network.allow_returns.title', 'Allow returns')}</Text>
      <Box height={16} />
      <Flex justify={'space-between'}>
        <Text type={'section'}>
          {t('backoffice.r1_network.allow_returns.description', 'Your network will be able to return car parts')}
        </Text>
        <Switch checked={returnsFeatureSettings.isEnabled} onChange={setIsEnabled} />
      </Flex>
      <Box height={30} />
      <Text type={'h3'}>{t('backoffice.r1_network.e-mail_address.title', 'e-mail address')}</Text>
      <Box height={16} />
      <Text type={'section'}>
        {t(
          'backoffice.r1_network.e-mail_address.description',
          'Returns from your network will be send to this address.',
        )}
      </Text>
      <Box height={16} />
      <Flex>
        <SInput
          maxLength={256}
          onBlur={(val) => {
            setEmail(val);
          }}
          isHighlighted={returnsFeatureSettings.isEnabled && !returnsFeatureSettings.email}
          bordered
          placeholder={t('common.email.address.placeholder', 'Type your e-mail address here')}
          initialValue={returnsFeatureSettings.email}
        />
        <Flex />
        <YellowButton
          onClick={() => {
            updateFeatureSettings();
          }}
        >
          {t('common.action.save', 'Save')}
        </YellowButton>
      </Flex>
    </>
  );
};
export default R1Network;
