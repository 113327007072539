import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import { ROUTER_ORDER_LIST, ROUTER_PROMOTION, ROUTER_TIRES, ROUTER_UNIVERSAL_PRODUCTS } from 'app/AppRouter';
import { AtomicIcon, CubeIcon, DiscountIcon, TireMudIcon } from 'assets/icons';
import { getCurrentPromotionsData } from 'domains/promotion/Promotion.store';
import { getUserCountry } from 'domains/user';
import { CREATE_NEW } from 'pages/BackOfficePage/BackOfficePage.type';
import { theme } from 'styles';
import { Flex, Icon, Link, MarginBox, Text } from 'UI';
import { getCondArrayItem, useExtraExtraLarge } from 'utils';
import { Country } from 'utils/i18n/Country';

export const getLinkItems = (t: TFunction, showTires: boolean, showCurrentPromotions: boolean) => {
  return [
    ...getCondArrayItem(
      showTires && {
        link: ROUTER_TIRES,
        icon: TireMudIcon,
        text: t('catalog.tires', 'Tires'),
      },
    ),
    ...getCondArrayItem({
      link: ROUTER_UNIVERSAL_PRODUCTS,
      icon: AtomicIcon,
      text: t('catalog.universal_products', 'Universal products'),
    }),
    ...getCondArrayItem(
      showCurrentPromotions && {
        link: ROUTER_PROMOTION,
        icon: DiscountIcon,
        text: t('promotions.current_promotions', 'Current promotions'),
      },
    ),
    {
      link: `${ROUTER_ORDER_LIST}/${CREATE_NEW}`,
      icon: CubeIcon,
      text: t('catalog.order_by_reference', 'Order by reference'),
    },
  ];
};

const Sdiv = styled.div<{ isHovered: boolean }>`
  background-color: ${({ isHovered }) => (isHovered ? theme.color.white : null)};
`;

const LinkTabs = () => {
  const { t } = useTranslation();
  const country = useSelector(getUserCountry);
  const showTires = country === Country.FR.key || country === Country.ES.key;
  const promotionsData = useSelector(getCurrentPromotionsData);
  const showCurrentPromotions = promotionsData?.length > 0;
  const linkItems = getLinkItems(t, showTires, showCurrentPromotions);
  const [hoveredIcon, setHoveredIcon] = useState<string | undefined>(undefined);
  const extraExtraLarge = useExtraExtraLarge();

  return (
    <Flex minWidth={extraExtraLarge ? 700 : 320}>
      {linkItems.map((item) => {
        const isHoveredIcon = hoveredIcon === item.link;
        return (
          <Link to={item.link} key={item.link}>
            <Sdiv
              onMouseEnter={() => setHoveredIcon(item.link)}
              onMouseLeave={() => setHoveredIcon(undefined)}
              isHovered={isHoveredIcon}
            >
              <MarginBox mx={7}>
                <Flex align={'center'} gap={10} wrap={'nowrap'} minHeight={90} maxHeight={90}>
                  <Icon
                    IconComponent={item.icon}
                    size={26}
                    color={isHoveredIcon ? theme.color.blue_dark_3 : theme.color.white}
                    display={'block'}
                  />
                  {(extraExtraLarge || isHoveredIcon) && (
                    <Text type={isHoveredIcon ? 'h6_dark_blue' : 'h6_white'} noWrap>
                      {item.text}
                    </Text>
                  )}
                </Flex>
              </MarginBox>
            </Sdiv>
          </Link>
        );
      })}
    </Flex>
  );
};

export default LinkTabs;
