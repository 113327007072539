import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { matchRoute, ROUTER_TIRES } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import {
  fetchIAMTiresRequestSaga,
  fetchUniqueTireDimensionsRequestSaga,
  getTireBrandCategoriesMap,
  getTireSearchDimensions,
  tireBrandCategoriesRequestSaga,
} from 'domains/tires/Tire.store';
import { getUserRights, UserRole } from 'domains/user';
import { getData, hasUserAnyRight } from 'utils';

export const useFetchIAMTires = (versionCode: string | undefined) => {
  const dispatch = useDispatch();
  const userRights = getData(useSelector(getUserRights));

  useEffect(() => {
    if (
      versionCode &&
      hasUserAnyRight(
        [UserRole.IAM_ACCESS, UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
        userRights,
      )
    ) {
      dispatch(fetchIAMTiresRequestSaga({ versionCode }));
    }
  }, [dispatch, versionCode, userRights]);
};

export const useFetchUniqueTireDimensions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const tireSearchDimensions = useSelector(getTireSearchDimensions);

  useEffect(() => {
    if (matchRoute(location.pathname, ROUTER_TIRES) && !tireSearchDimensions) {
      dispatch(fetchUniqueTireDimensionsRequestSaga());
    }
  }, [dispatch, tireSearchDimensions, location.pathname]);
};

export const useFetchTireBrandCategories = () => {
  const dispatch = useDispatch();
  const brandCategoriesMap = useSelector((state: RootState) => getTireBrandCategoriesMap(state));

  useEffect(() => {
    if (!brandCategoriesMap.searchStatus) {
      dispatch(tireBrandCategoriesRequestSaga());
    }
  }, [dispatch, brandCategoriesMap.searchStatus]);
};
