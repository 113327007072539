const PREFIX = "/estimate/";
export const GET_LATEST_URL = PREFIX + "get-latest";
export const GET_BY_ID_URL = PREFIX + "get-by-id";
export const GET_HISTORY_URL = PREFIX + "history/get";
export const GET_SETTINGS_URL = PREFIX + "settings/get";
export const ADD_CATALOG_LABOR_TIME_URL = PREFIX + "items/add-catalog-labor-times";
export const ADD_CATALOG_REFERENCE_URL = PREFIX + "items/add-catalog-reference";
export const ADD_CATALOG_TIRE_URL = PREFIX + "items/add-catalog-tire";
export const ADD_CATALOG_LABOR_TIME_BY_CODE_URL = PREFIX + "items/add-labor-time-by-code";
export const ADD_CATALOG_TIRE_BY_NUMBER_URL = PREFIX + "items/add-tire-by-number";
export const ADD_CATALOG_REFERENCE_BY_NUMBER_URL = PREFIX + "items/add-reference-by-number";
export const ADD_CUSTOM_ITEM_URL = PREFIX + "items/add-custom-item";
export const ADD_BUNDLES_FROM_MY_STORE_URL = PREFIX + "items/add-bundles-from-my-store";
export const ADD_REFERENCE_NUMBER_URL = PREFIX + "items/add-reference-number";
export const UPDATE_LABOR_TIME_URL = PREFIX + "items/update-labor-time";
export const UPDATE_REFERENCE_URL = PREFIX + "items/update-reference";
export const UPDATE_TIRE_URL = PREFIX + "items/update-tire";
export const UPDATE_OTHER_ITEM_URL = PREFIX + "items/update-other-item";
export const UPDATE_WASTE_RECYCLING_URL = PREFIX + "items/update-waste-recycling";
export const UPDATE_FREE_BUNDLE_URL = PREFIX + "items/update-free-bundle";
export const REMOVE_ITEM_URL = PREFIX + "items/remove";
export const CLEAR_ITEMS_URL = PREFIX + "items/clear";
export const ADD_VEHICLE_URL = PREFIX + "vehicle/add";
export const UPDATE_VEHICLE_URL = PREFIX + "vehicle/update";
export const UPDATE_CLIENT_URL = PREFIX + "client/update";
export const UPDATE_OBSERVATIONS_URL = PREFIX + "observations/update";
export const UPDATE_SETTINGS_URL = PREFIX + "settings/update";
export const ADD_SETTINGS_ITEM_URL = PREFIX + "settings/add-item";
export const REMOVE_SETTINGS_ITEM_URL = PREFIX + "settings/remove-item";
export const CLEAR_SETTINGS_ITEMS_URL = PREFIX + "settings/clear-items";
export const UPDATE_SETTINGS_WASTE_RECYCLING_URL = PREFIX + "settings/update-waste-recycling";
export const ADD_CATALOG_REFERENCES_TO_BASKET_URL = PREFIX + "items/add-catalog-references-to-basket";
export const ESTIMATE_FROM_MAINTENANCE_PLAN = PREFIX + "from-maintenance-plan";
export const DELETE_ESTIMATE = PREFIX + "delete";
export const CANCEL_DELETION = PREFIX + "cancel-deletion";
