/* eslint-disable max-len */
import { GetIAMAvailableModulesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/available_modules/response/GetIAMAvailableModulesResponse';
import { GetIAMRepairMethodDetailResponse } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/response/GetIAMRepairMethodDetailResponse';
import { GetIAMFamilyReferencesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMFamilyReferencesResponse';
import { GetIAMServiceChecklistResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceChecklistResponse';
import { GetIAMServiceOperationsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceOperationsResponse';
import { GetIAMServiceProposalOperationsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceProposalOperationsResponse';
import { GetIAMServiceProposalResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceProposalResponse';

import {
  GET_IAM_AVAILABLE_MODULES_RESPONSE,
  GET_IAM_OPERATIONS_FAMILIES_RESPONSE,
  GET_IAM_OPERATIONS_TREE_RESPONSE,
  GET_IAM_REPAIR_METHOD_DETAIL_RESPONSE,
  GET_IAM_SERVICE_CHECKLIST_RESPONSE,
  GET_IAM_SERVICE_PROPOSAL_OPERATIONS_RESPONSE,
  GET_IAM_SERVICE_PROPOSAL_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { GET_IAM_SERVICE_OPERATIONS_REQUEST } from 'domains/catalog/Catalog.types';
import {
  ADD_OPERATION,
  ADD_REFERENCES,
  GET_IAM_AVAILABLE_MODULES_REQUEST,
  GET_IAM_REPAIR_METHOD_DETAIL_REQUEST,
  GET_IAM_SERVICE_CHECKLIST_REQUEST,
  GET_IAM_SERVICE_PROPOSAL_OPERATIONS_REQUEST,
  GET_IAM_SERVICE_PROPOSALS_REQUEST,
  MAINTENANCE_PLAN_NAMESPACE,
  MaintenancePlanState,
  REMOVE_OPERATION,
  REMOVE_REFERENCES,
  ServiceReferenceLocal,
  SET_OPERATIONS,
  SET_PROPOSAL_MILEAGE_INPUT,
  SET_PROPOSAL_OPTION_INPUT,
  SET_PROPOSAL_REGISTRATION_DATE_INPUT,
  SET_REFERENCES,
} from 'domains/maintenancePlan/MaintenancePlan.types';
import { FOUND, getData, hasData, NO_DATA, NOT_FOUND, SEARCH_STATUS, SearchData } from 'utils';

const initialState: MaintenancePlanState = {
  vehicleCode: undefined,
  vehicleKey: undefined,
  serviceOperationsFamiliesIAM: undefined,
  serviceOperationsIAM: undefined,
  serviceOperationsReferences: new Map<string, ServiceReferenceLocal[]>(),
  availableModules: undefined,
  serviceProposalsIAM: undefined,
  serviceProposalsOperationsIAM: undefined,
  registrationDate: undefined,
  mileage: undefined,
  option: undefined,
  serviceChecklist: undefined,
  repairMethodDetails: new Map<string, SearchData<string> | undefined>(),
};

// Saga actions
export const fetchIAMServiceOperationsRequestSaga = createAction(GET_IAM_SERVICE_OPERATIONS_REQUEST);
export const fetchIAMServiceOperationsResponseGaga = createAction(GET_IAM_OPERATIONS_TREE_RESPONSE);
export const fetchIAMServiceFamiliesResponseSaga = createAction(GET_IAM_OPERATIONS_FAMILIES_RESPONSE);
export const fetchIAMServiceProposalsRequestSaga = createAction<{
  registrationDate: string;
}>(GET_IAM_SERVICE_PROPOSALS_REQUEST);
export const fetchIAMServiceProposalsResponseSaga = createAction(GET_IAM_SERVICE_PROPOSAL_RESPONSE);
export const fetchIAMServiceProposalOperationsRequestSaga = createAction<{
  versionCode: string;
  proposalId: string;
  mileage: string;
  registrationDate: string;
}>(GET_IAM_SERVICE_PROPOSAL_OPERATIONS_REQUEST);
export const fetchIAMServiceProposalOperationsResponseSaga = createAction(GET_IAM_SERVICE_PROPOSAL_OPERATIONS_RESPONSE);
export const fetchIAMAvailableModulesRequestSaga = createAction(GET_IAM_AVAILABLE_MODULES_REQUEST);
export const fetchIAMAvailableModulesResponseSaga = createAction(GET_IAM_AVAILABLE_MODULES_RESPONSE);
export const fetchIAMChecklistRequestSaga = createAction(GET_IAM_SERVICE_CHECKLIST_REQUEST);
export const fetchIAMChecklistResponseSaga = createAction(GET_IAM_SERVICE_CHECKLIST_RESPONSE);
export const selectOperationRequest = createAction<{
  operationId: string;
}>(ADD_OPERATION);
export const removeOperationRequest = createAction<{
  operationId: string;
}>(REMOVE_OPERATION);
export const setOperationsRequest = createAction<{
  operationsIds: string[];
}>(SET_OPERATIONS);
export const setReferencesRequest = createAction<{
  operationId: string;
  family: string;
  references: string[];
}>(SET_REFERENCES);
export const addReferencesRequest = createAction<{
  operationId: string;
  family: string;
  references: string[];
}>(ADD_REFERENCES);
export const removeReferencesRequest = createAction<{
  operationId: string;
  references: string[];
}>(REMOVE_REFERENCES);
export const setRegistrationDateInputRequest = createAction<{
  registrationDate: moment.Moment;
}>(SET_PROPOSAL_REGISTRATION_DATE_INPUT);
export const setMileageInputRequest = createAction<{
  mileage: number;
}>(SET_PROPOSAL_MILEAGE_INPUT);
export const setOptionInputRequest = createAction<{
  option: string;
}>(SET_PROPOSAL_OPTION_INPUT);

export const fetchIAMRepairMethodDetail = createAction<{
  categoryId: string;
}>(GET_IAM_REPAIR_METHOD_DETAIL_REQUEST);

export const fetchIAMRepairMethodDetailResponseSaga = createAction(GET_IAM_REPAIR_METHOD_DETAIL_RESPONSE);

// Slice
const slice = createSlice({
  name: MAINTENANCE_PLAN_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setAvailableModulesStatus: (state, { payload }: PayloadAction<{ status: NO_DATA }>) => {
      state.availableModules = payload.status;
    },
    setAvailableModules: (state, { payload }: PayloadAction<GetIAMAvailableModulesResponse>) => {
      state.availableModules = payload;
    },
    setServiceOperationsStatus: (
      state,
      { payload }: PayloadAction<{ vehicleKey: string; versionCode: string | undefined; status: NO_DATA }>,
    ) => {
      const { vehicleKey, status } = payload;
      state.vehicleKey = vehicleKey;
      state.serviceOperationsIAM = status;
    },
    setServiceOperationsTree: (state, { payload }: PayloadAction<GetIAMServiceOperationsResponse>) => {
      state.serviceOperationsIAM = payload;
    },
    setFamilyReferences: (state, { payload }: PayloadAction<GetIAMFamilyReferencesResponse>) => {
      state.serviceOperationsFamiliesIAM = payload;
    },
    setServiceProposalsStatus: (
      state,
      { payload }: PayloadAction<{ vehicleKey: string | undefined; versionCode: string | undefined; status: NO_DATA }>,
    ) => {
      const { status } = payload;
      state.serviceProposalsIAM = status;
    },
    setServiceProposals: (state, { payload }: PayloadAction<GetIAMServiceProposalResponse>) => {
      state.serviceProposalsIAM = payload;
    },
    setServiceProposalOperationsStatus: (
      state,
      { payload }: PayloadAction<{ vehicleKey: string; versionCode: string | undefined; status: NO_DATA }>,
    ) => {
      const { status } = payload;
      state.serviceProposalsOperationsIAM = status;
    },
    setServiceProposalOperations: (state, { payload }: PayloadAction<GetIAMServiceProposalOperationsResponse>) => {
      state.serviceProposalsOperationsIAM = payload;
    },
    selectOperation: (state, action: PayloadAction<{ operationId: string }>) => {
      if (state.serviceOperationsIAM && hasData(state.serviceOperationsIAM)) {
        state.serviceOperationsIAM.mainOperations?.forEach((category) => {
          category.operations.forEach((operation) => {
            if (operation.id === action.payload.operationId) {
              operation.selected = true;
            }
          });
        });
        state.serviceOperationsIAM.additionalOperations?.forEach((category) => {
          category.operations.forEach((operation) => {
            if (operation.id === action.payload.operationId) {
              operation.selected = true;
            }
          });
        });
      }
    },
    unselectOperation: (state, action: PayloadAction<{ operationId: string }>) => {
      if (state.serviceOperationsIAM && hasData(state.serviceOperationsIAM)) {
        state.serviceOperationsIAM.mainOperations?.forEach((category) => {
          category.operations.forEach((operation) => {
            if (operation.id === action.payload.operationId) {
              operation.selected = false;
            }
          });
        });
        state.serviceOperationsIAM.additionalOperations?.forEach((category) => {
          category.operations.forEach((operation) => {
            if (operation.id === action.payload.operationId) {
              operation.selected = false;
            }
          });
        });
      }
    },
    setSelectedOperations: (state, action: PayloadAction<{ operationsIds: string[] }>) => {
      if (state.serviceOperationsIAM && hasData(state.serviceOperationsIAM)) {
        state.serviceOperationsIAM.mainOperations?.forEach((category) => {
          category.operations.forEach((operation) => {
            operation.selected = action.payload.operationsIds.includes(operation.id);
          });
        });
        state.serviceOperationsIAM.additionalOperations?.forEach((category) => {
          category.operations.forEach((operation) => {
            operation.selected = action.payload.operationsIds.includes(operation.id);
          });
        });
      }
    },
    addSelectedReferences: (
      state,
      action: PayloadAction<{
        operationId: string;
        family: string;
        references: string[];
      }>,
    ) => {
      const existingReferences = state.serviceOperationsReferences.get(action.payload.operationId) ?? [];
      const toBeAdded = action.payload.references
        .filter((item) => !existingReferences.some((ref) => ref.referenceNumber === item))
        .map((referenceNumber) => {
          return { quantity: 1, referenceNumber, family: action.payload.family };
        });
      const newReferences = [...existingReferences, ...toBeAdded];
      state.serviceOperationsReferences.set(action.payload.operationId, newReferences);
    },
    removeSelectedReferences: (
      state,
      action: PayloadAction<{
        operationId: string;
        references: string[];
      }>,
    ) => {
      const existingReferences = state.serviceOperationsReferences.get(action.payload.operationId);
      const newReferences =
        existingReferences?.filter((r) => !action.payload.references.includes(r.referenceNumber)) ?? [];
      state.serviceOperationsReferences.set(action.payload.operationId, newReferences);
    },
    setSelectedReferences: (
      state,
      action: PayloadAction<{
        operationId: string;
        family: string;
        references: string[];
      }>,
    ) => {
      const existingReferences = state.serviceOperationsReferences.get(action.payload.operationId);
      const newReferences = action.payload.references.map(
        (r) =>
          existingReferences?.find((existing) => existing.referenceNumber === r) ?? {
            referenceNumber: r,
            quantity: 1,
            family: action.payload.family,
          },
      );
      state.serviceOperationsReferences.set(action.payload.operationId, newReferences);
    },
    setSelectedReferenceQuantity: (
      state,
      action: PayloadAction<{ operationId: string; referenceNumber: string; newQuantity: number }>,
    ) => {
      const existingReferences = state.serviceOperationsReferences.get(action.payload.operationId);

      const newReferences =
        existingReferences?.map((ref) =>
          ref.referenceNumber === action.payload.referenceNumber
            ? {
                ...ref,
                quantity: action.payload.newQuantity,
              }
            : ref,
        ) ?? ([] as ServiceReferenceLocal[]);
      state.serviceOperationsReferences.set(action.payload.operationId, newReferences);
    },
    setRegistrationDateInput: (state, action: PayloadAction<{ registrationDate: moment.Moment }>) => {
      state.registrationDate = action.payload.registrationDate;
    },
    setMileageInput: (state, action: PayloadAction<{ mileage: number }>) => {
      state.mileage = action.payload.mileage;
    },
    setOptionInput: (state, action: PayloadAction<{ option: string }>) => {
      state.option = action.payload.option;
    },

    setServiceChecklistStatus: (state, { payload }: PayloadAction<{ status: NO_DATA }>) => {
      state.serviceChecklist = {
        searchStatus: payload.status,
      };
    },
    setServiceChecklist: (state, { payload }: PayloadAction<GetIAMServiceChecklistResponse>) => {
      const result: SearchData<GetIAMServiceChecklistResponse> = {
        searchStatus: payload.checklist ? FOUND : NOT_FOUND,
        data: payload,
      };
      state.serviceChecklist = result;
    },
    setRepairMethodDetails: (state, { payload }: PayloadAction<GetIAMRepairMethodDetailResponse>) => {
      const result: SearchData<string> = {
        searchStatus: payload.repairMethodDetail ? FOUND : NOT_FOUND,
        data: payload.repairMethodDetail,
      };
      state.repairMethodDetails.set(payload.categoryId, result);
    },
    setRepairMethodDetailsStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        categoryId: string;
        status: SEARCH_STATUS;
      }>,
    ) => {
      const result: SearchData<string> = {
        searchStatus: payload.status,
      };
      state.repairMethodDetails.set(payload.categoryId, result);
    },
  },
});

// Actions
export const {
  setInitialState,
  selectOperation,
  unselectOperation,
  setSelectedReferences,
  setFamilyReferences,
  setServiceOperationsStatus,
  setServiceOperationsTree,
  setSelectedReferenceQuantity,
  addSelectedReferences,
  removeSelectedReferences,
  setServiceProposalOperations,
  setServiceProposals,
  setServiceProposalsStatus,
  setServiceProposalOperationsStatus,
  setSelectedOperations,
  setRegistrationDateInput,
  setOptionInput,
  setMileageInput,
  setAvailableModules,
  setAvailableModulesStatus,
  setServiceChecklistStatus,
  setServiceChecklist,
  setRepairMethodDetails,
  setRepairMethodDetailsStatus,
} = slice.actions;

export const getLastSearchServiceFamilies = createSelector(
  (state: RootState) => state.maintenancePlan.serviceOperationsFamiliesIAM,
  (serviceOperationsFamiliesIAM) => serviceOperationsFamiliesIAM,
);

export const getServiceOperationsReferences = createSelector(
  (state: RootState) => state.maintenancePlan.serviceOperationsReferences,
  (serviceOperationsReferences) => serviceOperationsReferences,
);

export const getLastSearchServiceOperationsData = createSelector(
  (state: RootState) => state.maintenancePlan.serviceOperationsIAM,
  (serviceOperationsIAM) => getData(serviceOperationsIAM),
);

export const getLastSearchServiceOperations = createSelector(
  (state: RootState) => state.maintenancePlan.serviceOperationsIAM,
  (serviceOperationsIAM) => serviceOperationsIAM,
);

export const getServiceProposal = createSelector(
  (state: RootState) => state.maintenancePlan.serviceProposalsIAM,
  (serviceProposals) => serviceProposals,
);

export const getServiceProposalOperations = createSelector(
  (state: RootState) => state.maintenancePlan.serviceProposalsOperationsIAM,
  (proposal) => proposal,
);

export const getRegistrationDateInput = createSelector(
  (state: RootState) => state.maintenancePlan.registrationDate,
  (registrationDate) => registrationDate,
);

export const getMileageInput = createSelector(
  (state: RootState) => state.maintenancePlan.mileage,
  (mileage) => mileage,
);

export const getOptionInput = createSelector(
  (state: RootState) => state.maintenancePlan.option,
  (option) => option,
);

export const getIAMAvailableModules = createSelector(
  (state: RootState) => state.maintenancePlan.availableModules,
  (availableModules) => availableModules,
);

export const getIAMServiceChecklist = createSelector(
  (state: RootState) => state.maintenancePlan.serviceChecklist,
  (serviceChecklist) => serviceChecklist,
);

export const getIAMServiceChecklistStatus = createSelector(
  (state: RootState) => state.maintenancePlan.serviceChecklist?.searchStatus,
  (serviceChecklistStatus) => serviceChecklistStatus,
);

export const getIAMRepairMethodDetailStatus = createSelector(
  (state: RootState) => state.maintenancePlan.repairMethodDetails,
  (_state: RootState, categoryId: string | undefined) => categoryId,
  (repairMethodDetail, categoryId) => {
    if (categoryId) {
      return repairMethodDetail.get(categoryId)?.searchStatus;
    }
  },
);

export const getIAMRepairMethodDetail = createSelector(
  (state: RootState) => state.maintenancePlan.repairMethodDetails,
  (_state: RootState, categoryId: string | undefined) => categoryId,
  (repairMethodDetail, categoryId) => {
    if (categoryId) {
      return repairMethodDetail.get(categoryId)?.data;
    }
  },
);

// Export reducer
export default slice.reducer;
