/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import {
  ROUTER_CATALOG_DH_L3,
  ROUTER_CATALOG_TIRES,
  ROUTER_CATALOG_VEHICLE,
  ROUTER_DETAILS,
  ROUTER_PRODUCT,
} from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import PageViewCounter from 'components/Help/PageViewCounter';
import Loader from 'components/Loader';
import {
  useFetchDHTire,
  useFetchKadensisTechnicalData,
  useFetchMaintenancePlan,
  useFetchVehicle,
  useUpdateVehicleSearchHistory,
} from 'domains/catalog/Catalog.requests';
import {
  getLastSearchedVehicleKey,
  getSearchVehicleResult,
  resetPreviousTechnicalCriteria,
  setLastSearchedVehicleKey,
} from 'domains/catalog/Catalog.store';
import { FLUIDS_ID, TECHNICAL_DATA_TAB, TechnicalDataIdParam, VehicleCountry } from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl, getDHCatalogSourcePaths } from 'domains/catalog/Catalog.utils';
import { useFetchEstimateSettingsSubscription } from 'domains/estimate/Estimate.requests';
import { getDhCatalogTabsView } from 'domains/user';
import { CatalogTabs, renderVehicleTabContentSticky } from 'pages/CatalogPage/common';
import CatalogBreadcrumb from 'pages/CatalogPage/common/CatalogBreadcrumb';
import { ProductSection } from 'pages/CatalogPage/DH/Product/ProductSection';
import { Container, defaultPadding, Empty, Flex, indentPadding, MarginBox, StickyBoxCustom } from 'UI';
import { ERROR, FOUND, isQueryBMM, NOT_FOUND, SearchData } from 'utils';
import { useIsScrolled } from 'utils/hooks/useIsScrolled';
import { OFFSET_SUB_HEADER, useOffsetTop } from 'utils/hooks/useOffsetTop';
import CarPartGroupsSection from './CarPartGroupsSection';
import FullTextSearchResult from './FullTextSearchResult/FullTextSearchResult';
import SubcategoriesSection from './SubcategoriesSection';
import SubcategorySection from './SubcategorySection';
import Tires from './Tires';
import { VehicleTechnicalDetails } from './VehicleTechnicalDetails/VehicleTechnicalDetails';
import QuickAccess from '../common/QuickAccess';
import { useResetQuickAccessCategories } from '../common/QuickAccess/QuickAccess';

export const CatalogTabsDH = ({ tree }: { tree: SearchData<VehiclePartCategoryTreeItem[]> | undefined }) => {
  const { t } = useTranslation();
  const uparams = new URLSearchParams(location.search);
  uparams.set(TechnicalDataIdParam, FLUIDS_ID);

  return (
    <DataContainer
      data={tree}
      ErrorState={() => (
        <ErrorWithLabel
          label={t('catalog.parts.backend_error', 'Car parts are temporarily unavailable, please try again later.')}
        />
      )}
    >
      <Switch>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}/search/:label`)} exact>
          <FullTextSearchResult />
        </Route>
        <Route
          path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}${ROUTER_PRODUCT}/:referenceNumber`)}
          exact
        >
          <ProductSection />
        </Route>
        <Route
          path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}/:carPartGroupId/:categoryId/:subcategoryId`)}
          exact
        >
          <div>
            <MarginBox mb={15} ml={20} mt={25}>
              <DataContainer data={tree?.searchStatus} ErrorState={() => <></>}>
                <CatalogBreadcrumb tree={tree?.data} />
              </DataContainer>
            </MarginBox>
            <PageViewCounter page={ROUTER_CATALOG_DH_L3} />
            <SubcategorySection />
          </div>
        </Route>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}/:carPartGroupId/:categoryId`)} exact>
          <div>
            <MarginBox mb={15} ml={20} mt={25}>
              <DataContainer data={tree?.searchStatus} ErrorState={() => <></>}>
                <CatalogBreadcrumb tree={tree?.data} />
              </DataContainer>
            </MarginBox>
            <SubcategoriesSection />
          </div>
        </Route>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}/:carPartGroupId?`)} exact>
          <div>
            <MarginBox mb={15} ml={20} mt={25}>
              <DataContainer data={tree?.searchStatus} ErrorState={() => <></>}>
                <CatalogBreadcrumb tree={tree?.data} />
              </DataContainer>
            </MarginBox>
            {/*todo enable when catalog l1 first help*/}
            {/*<PageViewCounter page={ROUTER_CATALOG_DH_L1} />*/}
            <CarPartGroupsSection />
          </div>
        </Route>
      </Switch>
    </DataContainer>
  );
};

const CatalogPage = ({ setCatalogShowMenu }: { setCatalogShowMenu: (b: boolean) => void }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { query } = useParams<{
    query: string;
  }>();
  const [isChangeVehicle, setIsChangeVehicle] = useState(false);
  const result = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const tree = result?.explodedTree;
  const searchStatus = result?.searchVehicleStatus;
  const lastSearchedVehicleKey = useSelector(getLastSearchedVehicleKey);
  const lastSearchedResult = useSelector((state: RootState) => getSearchVehicleResult(state, lastSearchedVehicleKey));
  const params = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const tabKey = useSelector(getDhCatalogTabsView);
  const offsetTop = useOffsetTop('RENAULT_CATALOG');
  const isBMM = isQueryBMM(result?.vehicleDetail?.vehicleKey ?? '');
  const isScrolled = useIsScrolled();

  useEffect(() => {
    if (lastSearchedVehicleKey !== query) {
      dispatch(resetPreviousTechnicalCriteria());
      dispatch(setLastSearchedVehicleKey(query));
    }
  }, [dispatch, query, lastSearchedVehicleKey]);

  useEffect(() => {
    if (isChangeVehicle) {
      if (
        lastSearchedVehicleKey !== query &&
        lastSearchedResult?.searchVehicleStatus === FOUND &&
        lastSearchedResult?.vehicleDetail
      ) {
        setIsChangeVehicle(false);
        history.push(
          `${getCatalogSourceUrl(lastSearchedResult?.vehicleDetail?.catalogSource)}/${
            lastSearchedResult?.vehicleDetail?.vehicleKey
          }${ROUTER_CATALOG_VEHICLE}?${params}`,
        );
      }
    }
    // eslint-disable-next-line
  }, [isChangeVehicle, lastSearchedVehicleKey]);

  useEffect(() => {
    if (tabKey !== TECHNICAL_DATA_TAB) {
      params.delete(TechnicalDataIdParam);
      history.replace(`${location.pathname}?${params}`);
    }
    // eslint-disable-next-line
  }, [tabKey]);

  useEffect(() => {
    const country = result?.vehicleDetail?.country;
    if (params.get(VehicleCountry) !== country && country) {
      params.set(VehicleCountry, country);
      history.replace(`${location.pathname}?${params}`);
    }
  });

  useFetchEstimateSettingsSubscription();
  useFetchVehicle(query, searchStatus);
  useFetchDHTire(result);
  useUpdateVehicleSearchHistory(result?.vehicleDetail);
  useFetchMaintenancePlan();
  useResetQuickAccessCategories();
  useFetchKadensisTechnicalData();

  if (!result || !result.vehicleDetail) {
    switch (searchStatus) {
      case NOT_FOUND:
        return (
          <Container padding={defaultPadding}>
            <MarginBox my={220}>
              <Empty />
            </MarginBox>
          </Container>
        );
      case ERROR:
        return (
          <Container padding={defaultPadding}>
            <MarginBox my={220}>
              <ErrorWithLabel
                label={t(
                  'catalog.parts.search.by_vin_or_vrn.error',
                  'Sorry, we could not find any vehicle associated, please try again later.',
                )}
              />
            </MarginBox>
          </Container>
        );
      default:
        return <Loader />;
    }
  }

  return (
    <Container padding={indentPadding} alignText={'left'} height={'100%'} justify={'flex-start'}>
      <StickyBoxCustom offsetTop={offsetTop + OFFSET_SUB_HEADER} zIndex={100}>
        <CatalogTabs
          result={result.vehicleDetail}
          vehicleImage={result?.vehicleDetail?.imageUrl}
          query={query}
          setCatalogShowMenu={() => undefined}
          setIsChangeVehicle={setIsChangeVehicle}
        />
      </StickyBoxCustom>
      <Switch>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_DETAILS}`)} exact>
          <VehicleTechnicalDetails />
        </Route>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_TIRES}`)}>
          {renderVehicleTabContentSticky(
            offsetTop,
            result.vehicleDetail,
            result?.vehicleDetail?.imageUrl,
            isBMM,
            query,
            isScrolled,
            setIsChangeVehicle,
          )}
          <QuickAccess />
          <Tires setCatalogShowMenu={setCatalogShowMenu} />
        </Route>
        <Route path={getDHCatalogSourcePaths(`/:query/search/:label`)}>
          <CatalogTabsDH tree={tree} />
        </Route>
        <Route
          path={getDHCatalogSourcePaths(
            `/:query${ROUTER_CATALOG_VEHICLE}/:carPartGroupId?/:categoryId?/:subcategoryId?/:plateId?`,
          )}
        >
          <QuickAccess />
          <CatalogTabsDH tree={tree} />
          <Flex />
        </Route>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}${ROUTER_PRODUCT}`)}>
          <CatalogTabsDH tree={tree} />
        </Route>
      </Switch>
    </Container>
  );
};

export default CatalogPage;
