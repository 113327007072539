import React, { PropsWithChildren, ReactNode } from 'react';
import { Collapse as AntCollapse } from 'antd';
import { useTheme } from 'styled-components';
import { AngleDownIcon, AngleUpIcon } from 'assets/icons';
import { SCollapse, SPanel } from './Collapse.styled';

export const Collapse = ({
  children,
  position = 'start',
  iconActive,
  iconInactive,
  noBorder,
  noMargin,
  defaultActiveKey = ['1'],
  noShadow,
  onChange,
}: PropsWithChildren<{
  position?: 'start' | 'end';
  iconActive?: ReactNode;
  iconInactive?: ReactNode;
  noBorder?: boolean;
  noMargin?: boolean;
  defaultActiveKey?: string[] | string | number[] | number;
  noShadow?: boolean;
  onChange?: (key: string | string[]) => void;
}>) => {
  return (
    <SCollapse
      defaultActiveKey={defaultActiveKey}
      expandIconPosition={position}
      expandIcon={iconActive && iconInactive ? ({ isActive }) => (isActive ? iconActive : iconInactive) : undefined}
      noBorder={noBorder}
      noMargin={noMargin}
      noShadow={noShadow}
      onChange={onChange}
    >
      {children}
    </SCollapse>
  );
};

export const SectionCollapse = ({
  children,
  position,
  defaultActiveKey,
  noShadow,
}: PropsWithChildren<{
  position?: 'start' | 'end';
  defaultActiveKey?: string[] | string | number[] | number;
  noShadow?: boolean;
}>) => {
  const theme = useTheme();

  return (
    <Collapse
      defaultActiveKey={defaultActiveKey}
      position={position ?? 'start'}
      iconInactive={<AngleDownIcon height={32} width={32} display={'inline'} color={theme.color.grey35} />}
      iconActive={<AngleUpIcon height={32} width={32} display={'inline'} color={theme.color.grey35} />}
      noBorder
      noShadow={noShadow}
      noMargin
    >
      {children}
    </Collapse>
  );
};

interface AccordionProps {
  children: ReactNode;
  onChange?: (key: string | string[]) => void;
  activeKey?: string;
}

export const Accordion = ({ children, onChange, activeKey }: AccordionProps) => {
  return (
    <AntCollapse onChange={onChange} accordion defaultActiveKey={['1']} activeKey={activeKey}>
      {children}
    </AntCollapse>
  );
};

export const CollapseWithKey = ({
  children,
  position = 'start',
  iconActive,
  iconInactive,
  noBorder,
  noMargin,
  noShadow,
  onChange,
  activeKey,
}: PropsWithChildren<{
  position?: 'start' | 'end';
  iconActive?: ReactNode;
  iconInactive?: ReactNode;
  noBorder?: boolean;
  noMargin?: boolean;
  noShadow?: boolean;
  onChange?: (key: string | string[]) => void;
  activeKey?: Array<string | number> | string | number;
}>) => {
  return (
    <SCollapse
      expandIconPosition={position}
      expandIcon={iconActive && iconInactive ? ({ isActive }) => (isActive ? iconActive : iconInactive) : undefined}
      noBorder={noBorder}
      noMargin={noMargin}
      noShadow={noShadow}
      onChange={onChange}
      activeKey={activeKey}
    >
      {children}
    </SCollapse>
  );
};

export const Panel = SPanel;
