import React from 'react';
import { Row, Col } from 'antd-v5';
import { useBreakpointSelector } from 'utils/hooks/useBreakpoint';

export type ColumnType = 1 | 2 | 3 | 4 | 6 | 12 | 24;
export type GutterType = [number, number] | number;

export interface GridProps {
  children?: React.ReactNode;
  columns: ColumnType | [ColumnType, ColumnType] | undefined;
  gutter: GutterType | [GutterType, GutterType] | undefined;
}

export const Grid = ({ children, columns, gutter = [0, 0] }: GridProps) => {
  const breakpointSelector = useBreakpointSelector();
  const gutterImpl = breakpointSelector(gutter);
  const columnsImpl = breakpointSelector(columns);
  return (
    <Row gutter={gutterImpl}>
      {React.Children.map(children, (column) => (
        <Col span={columnsImpl ? 24 / columnsImpl : undefined}>{column}</Col>
      ))}
    </Row>
  );
};
