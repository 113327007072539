/* eslint-disable max-len */
import { GetCategoriesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/category/request/GetCategoriesRequest';
import { GetVehicleCategoryImagesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/category/request/GetVehicleCategoryImagesRequest';
import { SelectVehicleRequest } from '@1po/1po-bff-fe-spec/generated/catalog/category/request/SelectVehicleRequest';
import { GetIAMLaborTimesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/request/GetIAMLaborTimesRequest';
import { GetMaintenancePlanRequest } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_plan/request/GetMaintenancePlanRequest';
import { GetMarketingReferencesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/request/GetMarketingReferencesRequest';
import { GetPlateReferencesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/request/GetPlateReferencesRequest';
import { GetReferenceDetailsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/request/GetReferenceDetailsRequest';
import { GetIAMReferencesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/request/GetIAMReferencesRequest';
import { GetIAMRepairMethodsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/request/GetIAMRepairMethodsRequest';
import { SearchBMMVehicleBrandsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/request/SearchBMMVehicleBrandsRequest';
import { SearchBMMVehicleEnginesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/request/SearchBMMVehicleEnginesRequest';
import { SearchIAMBMMVehicleModelsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/request/SearchIAMBMMVehicleModelsRequest';
import { SearchIAMBMMVehicleVersionsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/request/SearchIAMBMMVehicleVersionsRequest';
import { SearchVehicleByBMM } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/request/SearchVehicleByBMMRequest';
import { SearchVehicleByVinOrVrn } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/request/SearchVehicleByVinOrVrn';
import { GetIAMServiceOperationsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/request/GetIAMServiceOperationsRequest';
import { GetIAMTechnicalDataDetailsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/request/GetIAMTechnicalDataDetailsRequest';
import { GetIAMTechnicalDataTreeRequest } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/request/GetIAMTechnicalDataTreeRequest';
import { GetFullTextAutocompleteRequest } from '@1po/1po-bff-fe-spec/generated/catalog/text_search/request/GetFullTextAutocompleteRequest';
import { GetTextSearchRequest } from '@1po/1po-bff-fe-spec/generated/catalog/text_search/request/GetTextSearchRequest';
import { GetDetailsOfLevels3Request } from '@1po/1po-bff-fe-spec/generated/catalog/tree/request/GetDetailsOfLevels3Request';
import { GetUniversalProductsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/request/GetUniversalProductsRequest';
import { GetUniversalProductsTreeRequest } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/request/GetUniversalProductsTreeRequest';
import { UserContext } from '@1po/1po-bff-fe-spec/generated/catalog/user_context/UserContext';
import { VehicleContext } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/model/VehicleContext';
import { GetIAMVehicleTechnicalCriteriaRequest } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/request/GetIAMVehicleTechnicalCriteriaRequest';
import { GetVehicleTechnicalOperationRequest } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/request/GetVehicleOTSRequest';
import { GetVehicleTechnicalCriteriaRequest } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/request/GetVehicleTechnicalCriteriaRequest';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import {
  GET_FULL_TEXT_AUTOCOMPLETE_URL,
  GET_IAM_LABOR_TIMES_URL,
  GET_IAM_REFERENCES_URL,
  GET_IAM_REPAIR_METHODS_URL,
  GET_IAM_SERVICE_OPERATIONS_TREE_URL,
  GET_IAM_TECHNICAL_DATA_DETAILS_URL,
  GET_IAM_TECHNICAL_DATA_TREE_URL,
  GET_IAM_VEHICLE_MODELS_URL,
  GET_IAM_VEHICLE_TECHNICAL_CRITERIA_URL,
  GET_IAM_VEHICLE_VERSIONS_URL,
  GET_LEVEL3_URL,
  GET_MAINTENANCE_PLAN_URL,
  GET_MARKETING_REFERENCES_URL,
  GET_PLATE_REFERENCES_URL,
  GET_REFERENCE_DETAILS_URL,
  GET_TEXT_SEARCH_URL,
  GET_UNIVERSAL_PRODUCTS_TREE_URL,
  GET_UNIVERSAL_PRODUCTS_URL,
  GET_VEHICLE_BRANDS_URL,
  GET_VEHICLE_CATEGORIES_URL,
  GET_VEHICLE_CATEGORY_IMAGES_URL,
  GET_VEHICLE_ENGINES_URL,
  GET_VEHICLE_OTS_URL,
  GET_VEHICLE_TECHNICAL_CRITERIA_URL,
  SEARCH_BY_BMM_URL,
  SEARCH_BY_VIN_OR_VRN_URL,
  SELECT_VEHICLE_URL,
  TIRE_DATAHUB_VIN_SEARCH,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { DataHubTireVinRequest } from '@1po/1po-bff-fe-spec/generated/tire/request/DataHubTireVinRequest';
import { HALF_MINUTE, WebSocketAction } from 'utils/domainStore';
import { wsSendAction, wsSendActionNoTimeout } from 'utils/domainStore/api';

/* eslint-enable max-len */

export function sendSearchVehicleRequest(request: SearchVehicleByVinOrVrn): WebSocketAction {
  return wsSendAction(SEARCH_BY_VIN_OR_VRN_URL, request);
}

export function sendGetVehicleCategoriesRequest(request: GetCategoriesRequest): WebSocketAction {
  return wsSendAction(GET_VEHICLE_CATEGORIES_URL, request);
}

export function sendGetVehicleCategoryImagesRequest(request: GetVehicleCategoryImagesRequest): WebSocketAction {
  return wsSendAction(GET_VEHICLE_CATEGORY_IMAGES_URL, request);
}

export function sendSelectVehicleRequest(request: SelectVehicleRequest): WebSocketAction {
  return wsSendAction(SELECT_VEHICLE_URL, request);
}

export function sendFetchOtsInformationRequest(request: GetVehicleTechnicalOperationRequest): WebSocketAction {
  return wsSendAction(GET_VEHICLE_OTS_URL, request);
}

export function sendDataHubTireRequest(request: DataHubTireVinRequest): WebSocketAction {
  return wsSendAction(TIRE_DATAHUB_VIN_SEARCH, request);
}

export function sendGetPlateDetailDataRequest(request: GetDetailsOfLevels3Request): WebSocketAction {
  return wsSendAction(GET_LEVEL3_URL, request);
}

export function sendGetPlateReferencesRequest(
  plateId: string,
  userContext: UserContext,
  vehicleContext: VehicleContext,
  genericParts: string[],
): WebSocketAction {
  const request: GetPlateReferencesRequest = {
    plateId,
    userContext,
    vehicleContext,
    genericParts,
  };
  return wsSendAction(GET_PLATE_REFERENCES_URL, request);
}

export function sendGetMarketingReferencesRequest(
  nodeId: string,
  userContext: UserContext,
  vehicleContext: VehicleContext,
): WebSocketAction {
  const request: GetMarketingReferencesRequest = {
    nodeId,
    userContext,
    vehicleContext,
  };
  return wsSendAction(GET_MARKETING_REFERENCES_URL, request);
}

// BMM DH + IAM
export function sendSearchBMMVehicleBrandsRequest(userContext: UserContext): WebSocketAction {
  const request: SearchBMMVehicleBrandsRequest = {
    userContext,
  };
  return wsSendAction(GET_VEHICLE_BRANDS_URL, request);
}

export function sendSearchBMMVehicleEnginesRequest(
  userContext: UserContext,
  brandCode: string,
  modelCode: string,
  modelTypeCode: string,
): WebSocketAction {
  const request: SearchBMMVehicleEnginesRequest = {
    userContext,
    modelCode,
    modelTypeCode,
    brandCode,
  };
  return wsSendAction(GET_VEHICLE_ENGINES_URL, request);
}

export function sendSearchVehicleByBMMRequest(
  userContext: UserContext,
  brandCode: string,
  engineCode: string,
  modelTypeCode: string,
  modelCode: string,
  gearboxCode: string,
  requestId: string,
  iamVersionCode: string,
  includeEstimate: boolean,
  catalogSource?: CatalogSource,
): WebSocketAction {
  const request: SearchVehicleByBMM = {
    userContext,
    catalogSource,
    brandCode,
    modelTypeCode,
    modelCode,
    engineCode,
    gearboxCode,
    requestId,
    iamVersionCode,
    includeEstimate,
  };
  return wsSendAction(SEARCH_BY_BMM_URL, request);
}

// BMM IAM

export function sendIAMSearchBMMVehicleModelsRequest(userContext: UserContext, brandCode: string): WebSocketAction {
  const request: SearchIAMBMMVehicleModelsRequest = {
    userContext,
    brandCode,
  };
  return wsSendAction(GET_IAM_VEHICLE_MODELS_URL, request);
}

export function sendIAMSearchBMMVehicleVersionsRequest(userContext: UserContext, modelCode: string): WebSocketAction {
  const request: SearchIAMBMMVehicleVersionsRequest = {
    userContext,
    modelCode,
  };
  return wsSendAction(GET_IAM_VEHICLE_VERSIONS_URL, request);
}

export function sendGetReferenceDetailsRequest(
  userContext: UserContext,
  reference: string,
  vehicleContext: VehicleContext | undefined,
): WebSocketAction {
  const request: GetReferenceDetailsRequest = {
    userContext,
    reference,
    vehicleContext,
  };
  return wsSendAction(GET_REFERENCE_DETAILS_URL, request);
}

export function sendIAMLaborTimesRequest(request: GetIAMLaborTimesRequest): WebSocketAction {
  return wsSendAction(GET_IAM_LABOR_TIMES_URL, request);
}

export function sendIAMRepairMethodsRequest(request: GetIAMRepairMethodsRequest): WebSocketAction {
  return wsSendAction(GET_IAM_REPAIR_METHODS_URL, request);
}

export function sendFullTextAutocompleteRequest(
  userContext: UserContext,
  vehicleContext: VehicleContext,
  searchText: string,
): WebSocketAction {
  const request: GetFullTextAutocompleteRequest = {
    searchText,
    userContext,
    vehicleContext,
  };
  return wsSendActionNoTimeout(GET_FULL_TEXT_AUTOCOMPLETE_URL, request);
}

export function sendTextSearchRequest(
  searchId: string,
  userContext: UserContext,
  vehicleContext: VehicleContext | undefined,
  searchText: string,
  queryType: 'REFERENCE',
  catalogSource: CatalogSource | undefined,
): WebSocketAction {
  const request: GetTextSearchRequest = {
    searchId,
    searchText,
    userContext,
    vehicleContext,
    queryType,
    catalogSource,
  };
  return wsSendAction(GET_TEXT_SEARCH_URL, request, HALF_MINUTE);
}

export function sendUniversalProductsTreeRequest(userContext: UserContext): WebSocketAction {
  const request: GetUniversalProductsTreeRequest = {
    userContext,
  };
  return wsSendAction(GET_UNIVERSAL_PRODUCTS_TREE_URL, request);
}

export function sendUniversalProductsRequest(userContext: UserContext, referencesNumber: string[]): WebSocketAction {
  const request: GetUniversalProductsRequest = {
    userContext,
    referencesNumber,
  };
  return wsSendAction(GET_UNIVERSAL_PRODUCTS_URL, request);
}

export function sendVehicleTechnicalCriteriaRequest(vehicleKey: string, userContext: UserContext): WebSocketAction {
  const request: GetVehicleTechnicalCriteriaRequest = {
    vehicleKey,
    userContext,
  };
  return wsSendAction(GET_VEHICLE_TECHNICAL_CRITERIA_URL, request);
}

export function sendIAMVehicleTechnicalCriteriaRequest(
  vehicleContext: VehicleContext,
  userContext: UserContext,
): WebSocketAction {
  const request: GetIAMVehicleTechnicalCriteriaRequest = {
    vehicleContext,
    userContext,
  };
  return wsSendAction(GET_IAM_VEHICLE_TECHNICAL_CRITERIA_URL, request);
}

export function sendIAMReferenceRequest(
  versionCode: string,
  vehicleKey: string,
  userContext: UserContext,
  nodeId: string,
): WebSocketAction {
  const request: GetIAMReferencesRequest = {
    versionCode,
    vehicleKey,
    userContext,
    nodeId,
  };

  return wsSendAction(GET_IAM_REFERENCES_URL, request);
}

export function sendIAMTechnicalDataTreeRequest(request: GetIAMTechnicalDataTreeRequest): WebSocketAction {
  return wsSendAction(GET_IAM_TECHNICAL_DATA_TREE_URL, request);
}

export function sendIAMTechnicalDataDetailsRequest(request: GetIAMTechnicalDataDetailsRequest): WebSocketAction {
  return wsSendAction(GET_IAM_TECHNICAL_DATA_DETAILS_URL, request);
}

export function sendMaintenancePlanRequest(request: GetMaintenancePlanRequest): WebSocketAction {
  return wsSendAction(GET_MAINTENANCE_PLAN_URL, request);
}

export function sendIAMServiceOperationsRequest(request: GetIAMServiceOperationsRequest): WebSocketAction {
  return wsSendAction(GET_IAM_SERVICE_OPERATIONS_TREE_URL, request);
}
