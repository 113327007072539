import React, { Ref, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DiscountType } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/DiscountType';
import { InputRef } from 'antd';
import { useTheme } from 'styled-components';
import { TrashAltIcon } from 'assets/icons';
import QuantityModule from 'components/QuantityModule';
import { getCurrency } from 'domains/user';
import { Box, Flex, Icon, MarginBox, Text, WithTooltip } from 'UI';
import { textFormatter } from 'utils/format';
import {
  SDiscountInputNumber,
  SInput,
  SInputNumber,
  SInputPrice,
  SQuantityModuleFlex,
  SSelect,
  TooltipContent,
} from './TableSection.styled';

export function GetTitle(tr: string, dataCy?: string) {
  return (
    <Text type={'text_bold'} dataCy={dataCy}>
      {tr}
    </Text>
  );
}

export function isEmpty(value: string | number | undefined): boolean {
  return value === undefined || value === '';
}

export interface InputProps {
  saveFunction: (value: string) => void;
}

export interface QuantityModuleProps extends InputProps {
  value: number;
  removeRow: () => void;
  allowDecimal?: boolean;
  precisionMin?: number;
  precisionMax?: number;
  isInvalid?: boolean;
}

export interface TextInputProps extends InputProps {
  value: string | undefined;
  removeRow?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
  minWidth?: number;
  maxLength?: number;
  onBlur?: (v: string) => void;
  passRef?: Ref<InputRef>;
  checkEmpty?: boolean;
  id?: string;
  name?: string;
  autoComplete?: string;
  isInvalid?: boolean;
}

export interface DiscountInputProps extends InputProps {
  value: string | undefined;
  discountType: DiscountType;
  saveDiscountType: (value: DiscountType) => void;
  isInvalid?: boolean;
}

export interface TextInputWithReadOnly extends InputProps {
  value: string | undefined;
  readOnly?: boolean;
  minWidth?: number;
  isInvalid?: boolean;
}

export interface NumberInputWithReadOnly extends InputProps {
  value: number;
  readOnly?: boolean;
  isInvalid?: boolean;
}

export function TextInput({
  id,
  value,
  name,
  autoComplete,
  saveFunction,
  readOnly,
  removeRow,
  minWidth,
  disabled = false,
  checkEmpty = false,
  isInvalid = false,
}: Readonly<TextInputProps>) {
  const theme = useTheme();

  return (
    <Flex justify={'center'} align={'center'} minWidth={minWidth}>
      {removeRow && (
        <Icon IconComponent={TrashAltIcon} onClick={() => removeRow()} size={20} color={theme.color.brand_black} />
      )}
      <MarginBox ml={15} />
      <WithTooltip title={value} placement={'top'}>
        <TooltipContent>
          <SInput
            id={id}
            name={name}
            autoComplete={autoComplete}
            initialValue={value}
            disabled={disabled}
            onChangeDebounced={(value) => saveFunction(value)}
            bordered={!value || value.length === 0}
            readOnly={readOnly}
            checkEmpty={checkEmpty}
            $isEmpty={isEmpty(value)}
            $isInvalid={isInvalid}
            ellipsis
          />
        </TooltipContent>
      </WithTooltip>
    </Flex>
  );
}

export function QuantityInput({
  value,
  removeRow,
  saveFunction,
  precisionMin,
  precisionMax,
  isInvalid,
}: QuantityModuleProps) {
  const onChange = useCallback(
    (val: number) => {
      if (val <= 0) {
        removeRow();
      } else {
        saveFunction(String(val));
      }
    },
    [removeRow, saveFunction],
  );

  return (
    <SQuantityModuleFlex justify={'center'} align={'center'} $isInvalid={isInvalid}>
      <QuantityModule
        value={value}
        onChange={onChange}
        showDelete={true}
        precisionMin={precisionMin}
        precisionMax={precisionMax}
      />
    </SQuantityModuleFlex>
  );
}

export function EstimatePriceInput({
  value,
  saveFunction,
  readOnly,
  minWidth,
  isInvalid,
}: Readonly<TextInputWithReadOnly>) {
  const currency = useSelector(getCurrency);
  return (
    <Flex justify={'center'} align={'center'} minWidth={minWidth ?? 80}>
      <WithTooltip title={value} placement={'top'}>
        <TooltipContent>
          <SInputPrice
            initialValue={value}
            onBlur={(value) => saveFunction(value ?? '')}
            placeholder={'-'}
            currency={currency}
            readOnly={readOnly}
            $isInvalid={isEmpty(value) || isInvalid}
            bordered
          />
        </TooltipContent>
      </WithTooltip>
    </Flex>
  );
}

export function HoursInput({ value, saveFunction, readOnly }: NumberInputWithReadOnly) {
  return (
    <Flex justify={'center'} align={'center'} minWidth={80}>
      <WithTooltip title={value} placement={'top'}>
        <TooltipContent>
          <SInputNumber
            initialValue={value}
            onBlur={(value) => saveFunction(value?.toString() ?? '')}
            $isInvalid={isEmpty(value)}
            bordered
            placeholder={'-'}
            addonAfter={'h'}
            min={0}
            precisionMax={1}
            readOnly={readOnly}
          />
        </TooltipContent>
      </WithTooltip>
    </Flex>
  );
}

export function VatInput({ value, saveFunction, readOnly, isInvalid }: NumberInputWithReadOnly) {
  return (
    <Flex justify={'center'} align={'center'} minWidth={80}>
      <WithTooltip title={value && `${value}%`} placement={'top'}>
        <TooltipContent>
          <SInputNumber
            initialValue={value}
            onBlur={(value) => saveFunction(value?.toString() ?? '')}
            $isInvalid={isEmpty(value) || isInvalid}
            bordered
            placeholder={'-'}
            addonAfter={'%'}
            precisionMax={2}
            min={0}
            max={100}
            readOnly={readOnly}
          />
        </TooltipContent>
      </WithTooltip>
    </Flex>
  );
}

export function DiscountInput({ value, saveFunction, discountType, saveDiscountType, isInvalid }: DiscountInputProps) {
  const currency = useSelector(getCurrency);

  const options = [
    { title: '%', value: 'PERCENT' },
    { title: textFormatter.getCurrencySymbol(currency), value: 'VALUE_TO_EXTRACT' },
  ];
  const maxPercent = 100;

  return (
    <Flex justify={'center'} align={'center'}>
      <Flex minWidth={discountType === 'PERCENT' ? 50 : 60}>
        <SDiscountInputNumber
          initialValue={Number(value)}
          onBlur={(value) => saveFunction(value?.toString() ?? '')}
          bordered
          min={0}
          max={discountType === 'PERCENT' ? maxPercent : undefined}
          precisionMax={2}
          $isInvalid={isInvalid}
        />
      </Flex>
      <Box width={10} />
      <Flex size={0} maxWidth={60} minWidth={60}>
        <SSelect
          initialValue={discountType === 'PERCENT' ? 'PERCENT' : 'VALUE_TO_EXTRACT'}
          onChange={(selectValue) => {
            const disType = selectValue === 'PERCENT' ? 'PERCENT' : 'VALUE_TO_EXTRACT';
            saveDiscountType(disType);
          }}
          options={options}
          $isInvalid={isInvalid}
        />
      </Flex>
    </Flex>
  );
}
