import { AuthConfig } from '@1po/1po-bff-fe-spec/generated/auth/response/AuthConfig';
import { History } from 'history';
import { IdTokenClaims, User as OidcUser, UserManager, UserManagerSettings, UserProfile } from 'oidc-client-ts';
import { Dispatch } from 'redux';
import { ROUTER_HOME, ROUTER_LOGIN_REDIRECT, ROUTER_SILENT_TOKEN_RENEW } from 'app/AppRouter';
import {
  ARCA_LOGIN,
  CREDENTIALS_LOCAL_PARAM,
  IDP,
  ISSUER_LOCAL_PARAM,
  ISSUER_TYPE,
  KEYCLOAK,
  LOGIN_METHOD_LOCAL_PARAM,
  OKTA,
  SELECTED_PROFILE_PARAM,
} from 'domains/auth/Auth.types';
import {
  DealerRepository,
  REDIRECT_URL_STORE_KEY,
  setCurrentUserSaga,
  setTokenExpired,
  TokenProfile,
  User,
} from 'domains/user';
import { storeLoadAndClear } from 'utils';

interface LoginConfig {
  acr: string;
  roleScope: string;
  userDetailScope: string;
}

const ArcaLoginConfig: LoginConfig = {
  acr: 'secure/name/x509-FormLogin-Arca-Rnet-R1-R2-R3/uri',
  roleScope: 'role-1po-irn72795',
  userDetailScope: 'arca arcaX',
};

const RnetLoginConfig: LoginConfig = {
  acr: 'secure/name/x509-FormLogin-Rnet-R1-R2-R3/uri',
  roleScope: 'role-rnet-1po-irn72795',
  userDetailScope: 'vectury vectury-user',
};

let authConfig: {
  userManager: UserManager | undefined;
  issuer: ISSUER_TYPE;
};

export const authenticateAndRedirect = async (dispatch: Dispatch, history: History): Promise<void> => {
  const issuerString = sessionStorage.getItem(ISSUER_LOCAL_PARAM) ?? IDP;
  const issuer = issuerString as ISSUER_TYPE;
  const storedCredentials = sessionStorage.getItem(CREDENTIALS_LOCAL_PARAM);
  const credentials = storedCredentials != null ? (JSON.parse(storedCredentials) as AuthConfig) : undefined;
  const userManager = initUserManager(issuer, credentials);
  authConfig = {
    issuer,
    userManager,
  };
  await userManager.removeUser();
  await userManager.clearStaleState();
  await userManager.signinPopup().catch((error: unknown) => {
    if (error instanceof Error && error.message == 'Popup closed by user') {
      return undefined;
    }
    console.error(error);
  });
  const redirectUrl = storeLoadAndClear(REDIRECT_URL_STORE_KEY);
  if (window.location.pathname + window.location.search != redirectUrl) {
    history.push(redirectUrl || ROUTER_HOME);
  }
  dispatch(setCurrentUserSaga());
  dispatch(setTokenExpired(await getAuthenticatedUserIsExpired()));
};

const parseRoles = (tokenRoles: string | string[]): string[] => {
  if (Array.isArray(tokenRoles)) {
    return tokenRoles.map((role) => role.toUpperCase());
  }
  return tokenRoles.toUpperCase().replace(/[[\]]/g, '').split(',');
};

const parseDealerInfoFromToken = (entityIdentifiers: string | string[] | undefined): string | undefined =>
  Array.isArray(entityIdentifiers) ? entityIdentifiers[1] : parseDealerInfoFromKeycloakToken(entityIdentifiers);

const parseDealerInfoFromKeycloakToken = (entityIdentifiers: string | undefined): string | undefined => {
  const dealerInfo = entityIdentifiers?.match(/(\w+)(;LocationId;)(\d+)/);
  return !dealerInfo ? undefined : dealerInfo[0];
};

const parseRepository = (repositoryString: string | undefined): DealerRepository | undefined => {
  const repository = repositoryString?.toUpperCase();
  switch (repository) {
    case 'BIR':
    case 'VECTURY':
    case 'TRESOR':
    case 'DMD':
      return repository;
    default:
      return undefined;
  }
};

const parseCountry = (countryCode?: string, addressCountry?: string, postOfficeBox?: string): string | undefined => {
  if (countryCode) {
    return countryCode;
  }
  if (addressCountry) {
    return addressCountry;
  }
  return postOfficeBox && postOfficeBox?.length > 2 ? postOfficeBox?.substring(0, 2) : undefined;
};

interface LocaleI {
  lngAndCountry: string;
  q: number;
}

const parseLocale = (locale: string): string | undefined => {
  return locale
    .replace('_', '-')
    .split(/[,]+/)
    .map((l) => l.trim())
    .map(
      (l): LocaleI => {
        const [lngAndCountry, q] = l.split(';q=');
        return { lngAndCountry, q: Number(q ? q : 1) };
      },
    )
    .sort((a, b) => b.q - a.q)
    .find((l) => l)?.lngAndCountry;
};

function selectProfile(sessionProfileId: string | null, selectedProfile: TokenProfile | undefined) {
  if (!sessionProfileId && selectedProfile) {
    sessionStorage.setItem(SELECTED_PROFILE_PARAM, selectedProfile.id);
  }
}

function getRoleScope(issuer: string) {
  if (issuer === OKTA) {
    return 'roles';
  } else {
    const loginConfig = sessionStorage.getItem('loginMethod') === ARCA_LOGIN ? ArcaLoginConfig : RnetLoginConfig;
    return loginConfig.roleScope;
  }
}

function getFirstName(profile: IdTokenClaims) {
  return (profile.given_name as string) || (profile['firstName'] as string) || (profile['firstname'] as string) || '';
}

function getLastName(profile: IdTokenClaims) {
  return (profile.family_name as string) || (profile['lastName'] as string) || (profile['lastname'] as string) || '';
}

function getCountry(profile: IdTokenClaims) {
  return (
    parseCountry(
      profile['countryCode'] as string,
      profile['address_country'] as string,
      profile['postOfficeBox'] as string,
    ) ?? undefined
  );
}

function getRights(profile: IdTokenClaims, roleScope: string) {
  return !profile[roleScope] ? [] : parseRoles(profile[roleScope] as string);
}

function getEmail(profile: IdTokenClaims) {
  return (profile.mail as string) || profile.email || '';
}

export const mapUserFromOidcUser = (oidcUser: OidcUser): User => {
  const { profile } = oidcUser;
  const locale = profile.locale ? parseLocale(profile.locale) : undefined;
  const dealerInfo = parseDealerInfoFromToken(profile['entity_identifiers'] as string);

  const issuer = sessionStorage.getItem(ISSUER_LOCAL_PARAM) ?? IDP;
  const roleScope = getRoleScope(issuer);

  const garageId = (profile['siteSourceId'] ?? dealerInfo?.split(';')[2]) as string;
  const repository = parseRepository(profile['siteSource'] as string) ?? parseRepository(dealerInfo?.split(';')[0]);
  const id = (profile.login as string) || (profile.uid as string) || (profile.preferred_username as string);
  const profiles = mapTokenProfiles(profile, id, garageId, repository);
  const sessionProfileId = sessionStorage.getItem(SELECTED_PROFILE_PARAM);
  const sessionProfile = sessionProfileId ? profiles.find((p) => p.id === sessionProfileId) : undefined;
  const selectedProfile = sessionProfile ?? (profiles.length === 1 ? profiles[0] : undefined);

  selectProfile(sessionProfileId, selectedProfile);

  return {
    email: getEmail(profile),
    firstName: getFirstName(profile),
    lastName: getLastName(profile),
    country: getCountry(profile),
    rights: getRights(profile, roleScope),
    locale,
    garageId,
    repository,
    id,
    profiles,
    selectedProfile,
  };
};

export const mapTokenProfiles = (
  profile: UserProfile,
  id: string,
  garageId?: string,
  repository?: DealerRepository,
): TokenProfile[] => {
  const userContext = profile['userContext'];
  const profilesMap = Array.isArray(userContext)
    ? getTokenProfilesFromUserContexts(userContext)
    : new Map<string, TokenProfile>();
  if (!profilesMap.has(id)) {
    profilesMap.set(id, {
      id,
      garageId,
      repository,
      garageName: profile['siteShortName'] as string,
    });
  }
  // get values, put users ipn first
  return Array.from(profilesMap.values()).sort((a, b) => {
    if (a.id === id && b.id !== id) {
      return -1;
    }
    if (a.id !== id && b.id === id) {
      return 1;
    }
    return 0;
  });
};

const getTokenProfilesFromUserContexts = (contexts: string[]): Map<string, TokenProfile> => {
  const mappedContexts = contexts.map((context) => getLoginOptionsFromUserContext(context));
  return mappedContexts.reduce((acc, next) => {
    if (next) {
      acc.set(next.id, next);
    }
    return acc;
  }, new Map<string, TokenProfile>());
};

const getLoginOptionsFromUserContext = (context: string): TokenProfile | undefined => {
  if (context.length === 0) {
    return undefined;
  }
  const splittedRow = context.split(';');
  if (splittedRow.length < 3) {
    return undefined;
  }
  const ipn = splittedRow[0].split(':')[0];
  const splittedGaragePart = splittedRow[2].split('|');
  if (splittedGaragePart.length < 2) {
    return undefined;
  }
  return {
    id: ipn,
    garageId: splittedGaragePart[0],
    repository: parseRepository(splittedGaragePart[1]),
    garageName: splittedGaragePart[2],
  };
};

export const getUserManager = (): UserManager => {
  if (authConfig?.userManager === undefined) {
    const issuerString = sessionStorage.getItem(ISSUER_LOCAL_PARAM) ?? IDP;
    const issuer = issuerString as ISSUER_TYPE;
    const storedCredentials = sessionStorage.getItem(CREDENTIALS_LOCAL_PARAM);
    const credentials = storedCredentials != null ? (JSON.parse(storedCredentials) as AuthConfig) : undefined;
    const userManager = initUserManager(issuer, credentials);

    if (credentials) {
      authConfig = {
        issuer,
        userManager,
      };
    }
    return userManager;
  }
  return authConfig.userManager;
};

export const getAuthenticatedUser = async (): Promise<OidcUser | null> => getUserManager().getUser();

export const getToken = async (): Promise<string | undefined> => {
  const user = await getAuthenticatedUser();
  return user?.access_token;
};

export async function getAuthenticatedUserIsExpired(): Promise<boolean> {
  return getAuthenticatedUser().then((user) => user?.expired ?? false);
}

const initUserManager = (issuer: ISSUER_TYPE, credentials: AuthConfig | undefined): UserManager => {
  const userManager = new UserManager(userManagerSettings(issuer, credentials));
  let tokenLoading = false;

  userManager.events.addUserLoaded(() => {
    console.info('Access Token loaded');
    tokenLoading = false;
  });

  userManager.events.addAccessTokenExpiring(() => {
    if (!tokenLoading) {
      userManager.signinSilent();
      console.warn('Access Token is about to expire, triggered silent renew');
      tokenLoading = true;
    }
  });

  userManager.events.addAccessTokenExpired(() => {
    console.warn('Access Token expired');
  });

  userManager.events.addSilentRenewError((err) => {
    console.error('Silent renew error:', err);
  });

  return userManager;
};

const userManagerSettings = (issuer: ISSUER_TYPE, credentials: AuthConfig | undefined): UserManagerSettings => {
  const loginMethod = sessionStorage.getItem(LOGIN_METHOD_LOCAL_PARAM);
  const loginConfig = loginMethod === ARCA_LOGIN ? ArcaLoginConfig : RnetLoginConfig;
  switch (issuer) {
    case KEYCLOAK:
      return {
        redirect_uri: `${window.location.origin}${ROUTER_LOGIN_REDIRECT}`,
        response_type: 'code',
        authority: credentials?.keycloakIssuer ?? '',
        client_id: credentials?.keycloakClientId ?? '',
        scope: `openid ${loginConfig.userDetailScope} email entity_identifiers address_country ${loginConfig.roleScope}`,
        acr_values: loginConfig.acr,
        metadata: {
          issuer: credentials?.keycloakIssuer,
          authorization_endpoint: `${credentials?.keycloakIssuer}/protocol/openid-connect/auth`,
          token_endpoint: `${credentials?.keycloakIssuer}/protocol/openid-connect/token`,
          userinfo_endpoint: `${credentials?.keycloakIssuer}/protocol/openid-connect/userinfo`,
          jwks_uri: `${credentials?.keycloakIssuer}/protocol/openid-connect/certs`,
          registration_endpoint: `${credentials?.keycloakIssuer}/clients-registrations/openid-connect`,
          end_session_endpoint: `${credentials?.keycloakIssuer}/protocol/openid-connect/logout`,
        },
        automaticSilentRenew: true,
        silent_redirect_uri: `${window.location.origin}${ROUTER_SILENT_TOKEN_RENEW}`,
        post_logout_redirect_uri: `${window.location.origin}/`,
      };
    case IDP:
      return {
        redirect_uri: `${window.location.origin}${ROUTER_LOGIN_REDIRECT}`,
        response_type: 'code',
        authority: credentials?.idpIssuer ?? '',
        client_id: credentials?.idpClientId ?? '',
        scope: `openid ${loginConfig.userDetailScope} email entity_identifiers address_country ${loginConfig.roleScope}`,
        acr_values: loginConfig.acr,
        metadata: {
          issuer: credentials?.idpIssuer,
          authorization_endpoint: `${credentials?.idpIssuer}/authz`,
          token_endpoint: `${credentials?.idpIssuer}/token`,
          userinfo_endpoint: `${credentials?.idpIssuer}/userinfo`,
          jwks_uri: `${credentials?.idpIssuer}/keys`,
          registration_endpoint: `${credentials?.idpIssuer}/clients`,
          end_session_endpoint: `${window.location.origin}/`,
        },
        automaticSilentRenew: true,
        silent_redirect_uri: `${window.location.origin}${ROUTER_SILENT_TOKEN_RENEW}`,
        post_logout_redirect_uri: `${window.location.origin}/`,
        loadUserInfo: true,
      };
    default:
      return {
        redirect_uri: `${window.location.origin}${ROUTER_LOGIN_REDIRECT}`,
        response_type: 'code',
        authority: credentials?.oktaIssuer ?? '',
        client_id: credentials?.oktaClientId ?? '',
        scope: `openid alliance_profile apis.default`,
        metadata: {
          issuer: credentials?.oktaIssuer,
          authorization_endpoint: `${credentials?.oktaIssuer}/v1/authorize`,
          token_endpoint: `${credentials?.oktaIssuer}/v1/token`,
          userinfo_endpoint: `${credentials?.oktaIssuer}/v1/userinfo`,
          jwks_uri: `${credentials?.oktaIssuer}/v1/keys`,
          end_session_endpoint: `${credentials?.oktaIssuer}/v1/logout`,
        },
        post_logout_redirect_uri: `${window.location.origin}/`,
        automaticSilentRenew: true,
        silent_redirect_uri: `${window.location.origin}${ROUTER_SILENT_TOKEN_RENEW}`,
        accessTokenExpiringNotificationTimeInSeconds: 1920,
      };
  }
};
