import React from 'react';
import { Affix } from 'antd-v5';
import { StickyContainer } from './StickyBox.styled';
import { Box } from '../Box';

export interface StickyBoxProps {
  children?: React.ReactNode;
  background?: string;
  width?: string | number;
  height?: number;
  offsetTop?: number;
  offsetBottom?: number;
  zIndex?: number;
  onChange?: (affixed?: boolean) => void;
}

export const StickyBox = ({ children, background, offsetTop, width, height, zIndex, onChange }: StickyBoxProps) => {
  return (
    <Affix offsetTop={offsetTop} style={{ zIndex, position: 'relative', width }} onChange={onChange}>
      <Box background={background} height={height}>
        {children}
      </Box>
    </Affix>
  );
};

export const StickyBoxBottom = ({ children, background, offsetBottom, width, height, zIndex }: StickyBoxProps) => {
  return (
    <Affix offsetBottom={offsetBottom} style={{ zIndex, position: 'relative', width }}>
      <Box background={background} height={height}>
        {children}
      </Box>
    </Affix>
  );
};

export interface StickyBoxCustomProps {
  children: React.ReactNode;
  offsetTop: number;
  zIndex?: number;
}

export const StickyBoxCustom = ({ children, offsetTop, zIndex }: StickyBoxCustomProps) => {
  return (
    <StickyContainer offsetTop={offsetTop} zIndex={zIndex}>
      {children}
    </StickyContainer>
  );
};
