import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FreeBundleField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateFreeBundle';
import { FreeBundle } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { RootState } from 'app/AppStore';
import { getFreeBundles, removeFreeBundle, updateFreeBundle } from 'domains/estimate/Estimate.store';
import { getCurrency } from 'domains/user';
import {
  DiscountInput,
  EstimatePriceInput,
  GetTitle,
  QuantityInput,
  TextInput,
  VatInput,
} from 'pages/EstimatePage/TableSection/tableComponents';
import { TableProps } from 'pages/EstimatePage/TableSection/TableSection';
import { Flex } from 'UI';
import { textFormatter } from 'utils';
import { STable } from './TableSection.styled';

const FreeBundlesTable = ({ estimateId }: TableProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currency = useSelector(getCurrency);
  const data = useSelector((state: RootState) => getFreeBundles(state, estimateId));

  function removeRow(rowId: string) {
    dispatch(removeFreeBundle(rowId));
  }

  const handleUpdate = (itemId: string, field: FreeBundleField) => (newValue: string) => {
    dispatch(updateFreeBundle({ itemId, field, newValue }));
  };

  const tableColumns = [
    {
      title: GetTitle(t('common.code', 'Code')),
      dataIndex: 'code',
      render: function rowSelector(code: string, row: FreeBundle) {
        return (
          <TextInput
            value={code}
            saveFunction={handleUpdate(row.itemId, 'CODE')}
            removeRow={() => removeRow(row.itemId)}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.designation', 'Designation')),
      dataIndex: 'name',
      render: function rowSelector(name: string, row: FreeBundle) {
        return <TextInput value={name} saveFunction={handleUpdate(row.itemId, 'NAME')} />;
      },
    },
    {
      title: GetTitle(t('common.price.unit_price', 'Unit Price')),
      dataIndex: 'unitPrice',
      render: function rowSelector(unitPrice: string, row: FreeBundle) {
        return <EstimatePriceInput value={unitPrice} saveFunction={handleUpdate(row.itemId, 'UNIT_PRICE')} />;
      },
    },
    {
      title: GetTitle(t('common.quantity', 'Quantity')),
      dataIndex: 'quantity',
      render: function rowSelector(quantity: string, row: FreeBundle) {
        return (
          <QuantityInput
            value={Number(quantity)}
            saveFunction={handleUpdate(row.itemId, 'QUANTITY')}
            removeRow={() => removeRow(row.itemId)}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.discount', 'Discount')),
      dataIndex: 'discount',
      render: function rowSelector(discount: string, row: FreeBundle) {
        return (
          <DiscountInput
            value={discount}
            saveFunction={handleUpdate(row.itemId, 'DISCOUNT')}
            discountType={row.discountType}
            saveDiscountType={handleUpdate(row.itemId, 'DISCOUNT_TYPE')}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat_excl_price', 'VAT excl.Price')),
      dataIndex: 'priceVatExcluded',
      render: function rowSelector(vatExclPrice: string) {
        return (
          <Flex justify={'center'} align={'center'}>
            {textFormatter.formatCurrency(isNaN(Number(vatExclPrice)) ? 0 : vatExclPrice, currency)}
          </Flex>
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat.title', 'VAT')),
      dataIndex: 'vatPercentage',
      render: function rowSelector(vat: number, row: FreeBundle) {
        return <VatInput value={vat} saveFunction={handleUpdate(row.itemId, 'VAT_PERCENTAGE')} />;
      },
    },
  ];

  if (data.length === 0) {
    return null;
  }
  return (
    <>
      <STable<FreeBundle>
        columns={tableColumns}
        dataSource={data}
        pagination={false}
        rowKey={(row) => row.itemId}
        size={'large'}
      />
    </>
  );
};

export default FreeBundlesTable;
