import React from 'react';
import { Box, Flex, MarginBox, StickyBoxBottom, Text, WhiteButton, YellowButton } from 'UI';
import { StickCard } from './MaintenancePlan.styled';

export const BottomStickyBox = ({
  text1,
  text2,
  callback,
  buttonLabel,
  callback2,
  buttonLabel2,
  buttonLabel2Enabled = true,
}: {
  text1: string;
  text2: string;
  callback: () => void;
  buttonLabel: string;
  callback2?: () => void;
  buttonLabel2?: string;
  buttonLabel2Enabled?: boolean;
}) => {
  return (
    <MarginBox mt={15} mx={-30}>
      <StickyBoxBottom offsetBottom={15} background={'white'} zIndex={501}>
        <StickCard>
          <Flex direction={'row'} align={'center'}>
            <Flex direction={'column'}>
              <Text type={'h4_paragraph'}>{text1}</Text>
              <MarginBox mt={12} />
              <Text type={'light_14_black_75'}>{text2}</Text>
            </Flex>
            {buttonLabel2 && callback2 && (
              <Flex direction={'column'} justify={'center'} align={'flex-end'}>
                <MarginBox mr={25}>
                  <Box width={170}>
                    <WhiteButton size={'large'} stretch onClick={callback2} disabled={!buttonLabel2Enabled}>
                      {buttonLabel2}
                    </WhiteButton>
                  </Box>
                </MarginBox>
              </Flex>
            )}
            <Flex direction={'column'} justify={'center'} align={'flex-end'} size={0}>
              <Box width={150}>
                <YellowButton size={'large'} stretch onClick={callback}>
                  {buttonLabel}
                </YellowButton>
              </Box>
            </Flex>
          </Flex>
        </StickCard>
      </StickyBoxBottom>
    </MarginBox>
  );
};
