import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Promotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/CurrentPromotionsResponse';
import { RootState } from 'app/AppStore';
import { FOUND, getData, hasData, isLoading, NO_DATA, SEARCH_STATUS } from 'utils';
import {
  fetchCurrentPromotionsRequestSaga,
  fetchFullPromotionRequestSaga,
  fetchPromotionBannersRequestSaga,
  fetchPromotionRequestSaga,
  fetchSubscribeToNumberOfNotYetAdjustedPromotionsRequestSaga,
  getCurrentPromotionDetails,
  getCurrentPromotions,
  getFullPromotion,
  getPromotionBanners,
  getUnadjustedPromoCount,
  subscribeCurrentPromotionsRequestSaga,
} from './Promotion.store';
import {
  getUserContext,
  getUserProfileSearchStatus,
  getUserRights,
  isDelegationSessionStatusLoading,
  UserRole,
} from '../user';

export const useFetchPromotionDetails = (promotionId: string, isPromotionAlreadyFetched: boolean): void => {
  const dispatch = useDispatch();
  const userContext = useSelector(getUserContext);
  const isDelegationLoading = useSelector(isDelegationSessionStatusLoading);
  const promotionData = useSelector((state: RootState) => getCurrentPromotionDetails(state, promotionId));

  useEffect(() => {
    if (isDelegationLoading || isPromotionAlreadyFetched) {
      return;
    }
    if (promotionId && !hasData(promotionData)) {
      dispatch(fetchPromotionRequestSaga({ promotionId, userContext }));
    }
  }, [dispatch, promotionId, userContext, isDelegationLoading, isPromotionAlreadyFetched, promotionData]);
};

export const useFetchFullPromotion = (promotionId: string): void => {
  const dispatch = useDispatch();
  const isDelegationLoading = useSelector(isDelegationSessionStatusLoading);

  const fullPromotionData = useSelector((state: RootState) => getFullPromotion(state, promotionId));
  const promotionData = useSelector((state: RootState) => getCurrentPromotionDetails(state, promotionId));
  const userContext = useSelector(getUserContext);

  useEffect(() => {
    if (isDelegationLoading || !promotionId) {
      return;
    }
    if (!fullPromotionData) {
      dispatch(fetchFullPromotionRequestSaga({ promotionId }));
    }
    if (!promotionData) {
      dispatch(fetchPromotionRequestSaga({ promotionId, userContext }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, promotionId, isDelegationLoading, fullPromotionData, promotionData]);
};

export const useFetchPromoNotificationNumber = (): void => {
  const dispatch = useDispatch();
  const userContext = useSelector(getUserContext);
  const currentAmount = useSelector(getUnadjustedPromoCount);
  const userRights = useSelector(getUserRights);
  const roles = getData(userRights) ?? [];
  useEffect(() => {
    if (currentAmount === undefined && roles.includes(UserRole.R1)) {
      dispatch(fetchSubscribeToNumberOfNotYetAdjustedPromotionsRequestSaga({ userContext }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, roles]);
};

export const useFetchPromotionBanners = (): void => {
  const dispatch = useDispatch();
  const searchStatus = useSelector(getUserProfileSearchStatus);
  const bannerStatus = useSelector(getPromotionBanners);
  const isDelegationLoading = useSelector(isDelegationSessionStatusLoading);

  useEffect(() => {
    if (isDelegationLoading) {
      return;
    }
    if (searchStatus === FOUND && !bannerStatus) {
      dispatch(fetchPromotionBannersRequestSaga());
    }
  }, [dispatch, searchStatus, bannerStatus, isDelegationLoading]);
};

interface UsePromotionsReturn {
  promotions: {
    data: Map<string, Promotion | NO_DATA>;
    cursor: string | undefined;
    hasNextPage: boolean;
    status: SEARCH_STATUS;
  };
  loadMore: () => void;
}

export const useLoadMoreCurrentPromotions = (): UsePromotionsReturn => {
  const dispatch = useDispatch();
  const promotions = useSelector(getCurrentPromotions);

  const requestPromotions = (shouldLoadMore = false) => {
    dispatch(
      fetchCurrentPromotionsRequestSaga({
        cursor: shouldLoadMore ? promotions.cursor : undefined,
      }),
    );
  };

  const loadMore = () => {
    if (isLoading(promotions.status)) {
      return;
    }
    requestPromotions(true);
  };

  useEffect(() => {
    requestPromotions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return { promotions, loadMore };
};

export const useSubscribeCurrentPromotions = (): void => {
  const dispatch = useDispatch();
  const promotions = useSelector(getCurrentPromotions);
  const searchStatus = useSelector(getUserProfileSearchStatus);
  const isDelegationLoading = useSelector(isDelegationSessionStatusLoading);
  useEffect(() => {
    if (isDelegationLoading) {
      return;
    }
    if (searchStatus === FOUND && promotions.status === undefined) {
      dispatch(subscribeCurrentPromotionsRequestSaga());
    }
  }, [dispatch, isDelegationLoading, searchStatus, promotions.status]);
};
