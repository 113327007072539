import React from 'react';
import { useHistory } from 'react-router-dom';
import { VehicleIAMPartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehicleIAMPartCategoryTreeItem';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { SIDEBAR_WIDTH } from 'components/Page/PageSideBar/PageSideBar';
import { CategoriesWrapper, CategoryCard, Level3Category } from 'components/Page/PageSideBar/PageSideBar.styled';
import { CarPartsSubmenuCategoriesType } from 'components/Page/PageSideBar/SideBarCarCatalog/PartsCatalog';
import { formatCategoryLabelToDataCy, getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { Flex, Text } from 'UI';

interface SubCategoryCardProps {
  vehicleDetail: VehicleDetail;
  level2Label: string;
  level2NodeId: string;
  level1NodeId: string;
  items: VehiclePartCategoryTreeItem[] | VehicleIAMPartCategoryTreeItem[];
  setActiveCategory: (s: string) => void;
}

const SubCategoryCard = ({
  vehicleDetail,
  level2Label,
  level2NodeId,
  items,
  level1NodeId,
  setActiveCategory,
}: SubCategoryCardProps) => {
  const history = useHistory();
  const isIAM = vehicleDetail.catalogSource === 'IAM';
  const handleRedirect = (level1: string, level2: string, level3: string | undefined) => {
    const link = `${getCatalogSourceUrl(vehicleDetail.catalogSource)}/${
      vehicleDetail.vehicleKey
    }${ROUTER_CATALOG_VEHICLE}/${level1}/${level2}${level3 ? `/${level3}` : ''}`;
    if (level3 || !isIAM) {
      setActiveCategory('');
      history.push(link);
    }
  };

  return (
    <CategoryCard direction={'column'} gap={15}>
      <Text
        type={'h4_paragraph'}
        cursor={isIAM ? 'initial' : 'pointer'}
        onClick={() => handleRedirect(level1NodeId, level2NodeId, undefined)}
        transform={'uppercase'}
        dataCy={formatCategoryLabelToDataCy(level2Label, 'subtab')}
      >
        {level2Label}
      </Text>
      <Flex direction={'column'} gap={2}>
        {items.map((level3) => (
          <Level3Category
            key={level3.nodeId}
            align={'center'}
            onClick={() => handleRedirect(level1NodeId, level2NodeId, level3.nodeId)}
          >
            <Text
              type={'text_dim'}
              disableGutter
              transform={'capitalize'}
              dataCy={formatCategoryLabelToDataCy(level3.label, 'subtab')}
            >
              {level3.label}
            </Text>
          </Level3Category>
        ))}
      </Flex>
    </CategoryCard>
  );
};

const calculateWidth = () => {
  const space = window.innerWidth - SIDEBAR_WIDTH - 150;
  const cardWidth = 332;
  const fitCount = Math.floor(space / cardWidth);
  return cardWidth * fitCount;
};

const Megamenu = ({
  treeData,
  vehicleDetail,
  level1Label,
  level1NodeId,
  setActiveCategory,
}: CarPartsSubmenuCategoriesType) => {
  return (
    <CategoriesWrapper direction={'column'} gap={32}>
      <Text type={'h2'} transform={'uppercase'} dataCy={formatCategoryLabelToDataCy(level1Label, 'tab')}>
        {level1Label}
      </Text>
      <Flex wrap={'wrap'} maxWidth={calculateWidth()} gap={32}>
        {treeData.map((level2) => (
          <SubCategoryCard
            key={level2.nodeId}
            vehicleDetail={vehicleDetail}
            level2Label={level2.label}
            level2NodeId={level2.nodeId}
            items={level2.items}
            level1NodeId={level1NodeId}
            setActiveCategory={setActiveCategory}
          />
        ))}
      </Flex>
    </CategoriesWrapper>
  );
};

export default Megamenu;
