import { ReactNode } from 'react';
import { Collapse as CollapseAnt } from 'antd';
import styled from 'styled-components';

const { Panel: PanelAnt } = CollapseAnt;

export const SCollapse = styled(CollapseAnt)<{ noBorder?: boolean; noMargin?: boolean; noShadow?: boolean }>`
  border: ${({ noBorder }) => (noBorder ? 'none' : undefined)};
  background-color: ${({ theme }) => theme.color.white};

  .ant-collapse-item {
    border-top: ${({ theme }) => `1px solid ${theme.color.shadow_6}`};
  }

  .ant-collapse-item {
    border-bottom: ${({ noBorder }) => (noBorder ? 'none' : undefined)};
  }

  .ant-collapse-content {
    padding: ${({ noMargin }) => (noMargin ? '0 0 0 0' : '16px 16px 16px 16px')};
  }

  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: ${({ noMargin }) => (noMargin ? '0 0 0 0' : '16px 16px 16px 16px')};
  }
`;

export const SPanel = styled(PanelAnt)<{ headerBorder?: boolean; children?: ReactNode }>`
  background-color: ${({ theme }) => theme.color.white};

  .ant-collapse-content {
    border: none;
  }

  .ant-slider-track {
    background-color: ${({ theme }) => theme.color.grey_very_light_2};
  }

  .ant-slider:hover .ant-slider-rail {
    background-color: ${({ theme }) => theme.color.grey_very_light_2};
  }

  .ant-slider-handle {
    background-color: white;
    border-color: ${({ theme }) => theme.color.grey20};
  }

  .ant-slider-handle:focus {
    box-shadow: none;
    border-color: ${({ theme }) => theme.color.clear_blue};
  }
`;
