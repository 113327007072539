import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { WasteRecyclingField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateWasteRecycling';
import { Settings } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetSettings';
import { WasteRecycling } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Estimate';
import {
  addCustomSetting,
  removeCustomSetting,
  updateSettings,
  updateWasteRecyclingSettings,
} from 'domains/estimate/Estimate.store';
import { PlusCircleButton, Text } from 'UI';
import { EstimatePriceInput, GetTitle, TextInput } from '../TableSection/tableComponents';
import { STable } from '../TableSection/TableSection.styled';

const WasteRecyclingSettings = ({
  data,
  settingsState,
}: {
  data: WasteRecycling[];
  settingsState: Settings | undefined;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function removeRow(rowId: string) {
    dispatch(removeCustomSetting({ itemId: rowId, itemType: 'WASTE_RECYCLING' }));
    if (
      !data.filter((d) => d.itemId !== rowId).some((d) => d.priceVatExcluded) &&
      settingsState?.autoAddWasteRecycling === 'true'
    ) {
      dispatch(updateSettings({ field: 'AUTO_ADD_WASTE_RECYCLING', newValue: 'false' }));
    }
  }

  const handleUpdate = (itemId: string, field: WasteRecyclingField) => (newValue: string) => {
    dispatch(updateWasteRecyclingSettings({ itemId, field, newValue }));
  };

  const tableColumns = [
    {
      title: GetTitle(t('common.designation', 'Designation')),
      dataIndex: 'designation',
      render: function rowSelector(designation: string, row: WasteRecycling) {
        return (
          <TextInput
            value={designation}
            saveFunction={handleUpdate(row.itemId, 'DESIGNATION')}
            removeRow={() => removeRow(row.itemId)}
          />
        );
      },
      width: 900,
    },
    {
      title: GetTitle(t('common.price.vat_excl_price', 'VAT excl.Price')),
      dataIndex: 'priceVatExcluded',
      render: function rowSelector(unitPrice: string, row: WasteRecycling) {
        return <EstimatePriceInput value={unitPrice} saveFunction={handleUpdate(row.itemId, 'VAT_EXCL_PRICE')} />;
      },
      width: 200,
    },
  ];

  const addRow = () => {
    dispatch(addCustomSetting({ itemType: 'WASTE_RECYCLING' }));
  };

  return (
    <>
      {data.length > 0 && (
        <STable<WasteRecycling>
          columns={tableColumns}
          dataSource={data}
          pagination={false}
          rowKey={(row) => row.itemId}
          size={'large'}
        />
      )}
      <PlusCircleButton onClick={addRow}>
        <Text type={'link_bold'}>
          <Trans i18nKey={'common.action.add_waste_recycling'}>{'Add Waste recycling'}</Trans>
        </Text>
      </PlusCircleButton>
    </>
  );
};

export default WasteRecyclingSettings;
