/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { OrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { ROUTER_IN_PROGRESS_ORDERS } from 'app/AppRouter';
import { ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon } from 'assets/icons';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import Popover from 'components/Popover';
import StopPropagation from 'components/StopPropagation/StopPropagation';
import { OrderItemLocal } from 'domains/order/Order.types';
import { toDate } from 'domains/order/OrderDate';
import { OrderStatus } from 'domains/order/OrderStatus';
import { SCardCheckbox, SOrderCard } from 'pages/MyOrdersPage/OrderCards/OrderCard.styled';
import { OrderReferencesSection } from 'pages/MyOrdersPage/OrderReferencesSection/OrderReferencesSection';
import { theme } from 'styles';
import { Box, CenterFlex, Flex, Icon, Image, Link, Lowercase, MarginBox, Text, URL, Checkbox } from 'UI';
import { textFormatter } from 'utils';

const OrderCardVehicleDetail = ({ vehicleDetail }: { vehicleDetail: VehicleDetail | undefined }) => {
  const { t } = useTranslation();
  const vehicle = vehicleDetail?.catalogSource === 'DATAHUB' ? vehicleDetail.dataHubVehicle : vehicleDetail?.iamVehicle;
  const vehicleLabel =
    vehicleDetail?.catalogSource === 'DATAHUB' && vehicleDetail.vin
      ? vehicleDetail.dataHubVehicle?.name
      : `${vehicle?.name} ${vehicle?.modelType}`;

  if (vehicleDetail) {
    return (
      <Flex direction={'row'} align={'center'}>
        <Text type={'light_14_black_85'} transform={'capitalize'}>
          <strong>
            <Lowercase>{vehicleLabel}</Lowercase>
          </strong>
        </Text>
        <MarginBox mr={5} />
        <Text type={'light_14_black_85'}>
          {`${t('catalog.vehicle.vin', 'VIN')}: ${vehicleDetail.vin ? vehicleDetail.vin : '-'}`}
          {vehicleDetail.vrn && ` / ${t('catalog.vehicle.vrn', 'VRN')}: ${vehicleDetail.vrn}`}
        </Text>
      </Flex>
    );
  }
  return (
    <Flex direction={'row'} align={'center'}>
      <Text type={'light_14_black_85'}>
        <strong>{t('order.order_detail.no_vehicle_context', 'No vehicle context')}</strong>
      </Text>
    </Flex>
  );
};

interface OrderRowProps {
  order: OrderItemLocal;
  setSelectedOrders: React.Dispatch<React.SetStateAction<string[]>>;
  selectedOrders: string[];
  setUnselect: React.Dispatch<React.SetStateAction<string>>;
  index: number;
}

export const SubstituteStatus = ({ order }: { order: OrderItem }) => {
  const { t } = useTranslation();
  const areSubstitutes =
    order.orderedReferences.filter((r) => r.deliveredSubstitutedQuantity && r.deliveredSubstitutedQuantity > 0).length >
    0;

  return areSubstitutes ? (
    <Flex direction={'row'}>
      <MarginBox mt={2} mr={2}>
        <ExclamationCircleIcon width={16} height={16} fill={theme.color.warning} />
      </MarginBox>

      <Text type={'light_12'} displayStyle={'warning'}>
        {t('order.order_detail.reference_issue', 'Issue on one or several references.')}
      </Text>
    </Flex>
  ) : (
    <></>
  );
};
export const OrderCard = ({ order, setSelectedOrders, selectedOrders, setUnselect, index }: OrderRowProps) => {
  const { t } = useTranslation();
  const referencesCount = order?.orderedReferences?.length;
  const [expanded, setExpanded] = useState<boolean>(false);

  const areSubstitutes =
    order.orderedReferences.filter((r) => r.deliveredSubstitutedQuantity && r.deliveredSubstitutedQuantity > 0).length >
    0;

  const orderNumber = order.externalOrderId ?? order.internalOrderId;
  const displayOrderNumber =
    order.orderStatus === 'MAYBE_PLACED'
      ? t('my_orders.details.order_processing_after_timeout', 'Processing')
      : orderNumber;

  return (
    <Flex direction={'column'}>
      <SOrderCard
        direction={'row'}
        onClick={() => setExpanded((prev) => !prev)}
        expanded={expanded}
        dataCy={'item-order'}
      >
        <Box width={100} height={100}>
          <SCardCheckbox>
            <StopPropagation>
              <Checkbox
                checked={selectedOrders && selectedOrders.indexOf(order.internalOrderId) !== -1}
                onChange={(checked) => {
                  setSelectedOrders(
                    checked
                      ? (l: string[]) => [...l, order.internalOrderId]
                      : (l: string[]) => l && l.filter((cl) => cl !== order.internalOrderId),
                  );
                  setUnselect(order.internalOrderId);
                }}
                dataCy={'checkbox-order'}
              />
            </StopPropagation>
          </SCardCheckbox>
          <CenterFlex>
            <Image
              alt={'order-image'}
              maxWidth={100}
              maxHeight={100}
              src={order.vehicleDetail?.imageUrl}
              type={URL}
              catalogSource={order.vehicleDetail?.catalogSource}
              fallbackComponent={
                <Image alt={'order-default-image'} src={`order/order-default-image.png`} dataCy={'image-basket'} />
              }
              cursor={'pointer'}
              dataCy={'image-vehicle'}
            />
          </CenterFlex>
        </Box>
        <MarginBox mr={15} />
        <Flex direction={'column'}>
          <Flex direction={'column'}>
            <Flex direction={'column'} align={'flex-start'} minHeight={26} justify={'flex-start'}>
              <FirstHelpPopin
                streamId={ROUTER_IN_PROGRESS_ORDERS}
                popinId={`${ROUTER_IN_PROGRESS_ORDERS}_order_detail`}
                placement={'top'}
                skip={index !== 0}
              >
                <Popover
                  id={`${order.internalOrderId}-status-popover`}
                  placement={'top'}
                  trigger={'hover'}
                  content={
                    <CenterFlex>
                      <MarginBox mx={10} my={10}>
                        <OrderStatus orderStatus={order.presentableOrderStatus} />
                      </MarginBox>
                    </CenterFlex>
                  }
                >
                  <Link to={`${ROUTER_IN_PROGRESS_ORDERS}/${order.internalOrderId}`}>
                    <Text
                      type={'text_bold'}
                      cursor={'pointer'}
                      displayStyle={selectedOrders && selectedOrders.indexOf(order.internalOrderId) !== -1 && 'link'}
                      hoverUnderLine
                      dataCy={'link-order-number'}
                    >
                      {t('order.order_detail.order_number.value', 'Order Number: {{ orderNumber }}', {
                        orderNumber: displayOrderNumber,
                      })}
                    </Text>
                  </Link>
                </Popover>
              </FirstHelpPopin>
            </Flex>
            <Flex direction={'column'} align={'flex-start'} minHeight={23} justify={'flex-start'}>
              <Text type={'text'}>
                {t('order.order_detail.references', '{{ count }} references', {
                  count: referencesCount,
                })}
              </Text>
            </Flex>
          </Flex>
          <MarginBox mt={7} />
          <OrderCardVehicleDetail vehicleDetail={order.vehicleDetail} />
          <Flex direction={'row'}>
            <Text type={'text_bold'}>{t('order.order_detail.order_date', 'Order date:')}</Text>
            <MarginBox mr={5} />
            {toDate(order.orderDate)}
          </Flex>
        </Flex>
        {order.orderMark && (
          <Flex direction={'column'}>
            <Flex direction={'column'}>
              <Text type={'text_bold'}>{t('order.order_detail.order_mark', 'Order mark')}</Text>
              <Text type={'text'}>{`${order.orderMark ?? ''}`}</Text>
            </Flex>
          </Flex>
        )}
        <Flex maxWidth={250} direction={'column'} align={'flex-end'}>
          <Text type={'h5_bold'}>
            {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
              vat_exclude_price: textFormatter.formatCurrency(Number(order.priceVatExcluded), order.currency),
            })}
          </Text>
          <MarginBox mt={5} />
          <SubstituteStatus order={order} />
          <Flex align={'flex-start'}>
            <OrderStatus orderStatus={order.presentableOrderStatus} />
          </Flex>
        </Flex>
        <Flex direction={'row'} justify={'flex-end'} align={'center'} maxWidth={60}>
          {expanded ? (
            <Icon IconComponent={ChevronUpIcon} height={17} width={17} color={theme.color.grey10} />
          ) : (
            <Icon IconComponent={ChevronDownIcon} height={17} width={17} color={theme.color.grey10} />
          )}
        </Flex>
      </SOrderCard>
      {expanded && (
        <Flex direction={'column'} background={theme.color.grey_very_light_2}>
          <MarginBox mt={15} mx={15}>
            <OrderReferencesSection order={order} substituteInlineInfo={areSubstitutes ? true : undefined} />
          </MarginBox>
        </Flex>
      )}
      <MarginBox mt={15} />
    </Flex>
  );
};
