/* eslint-disable max-len */
import { VehicleSearchHistoryResponse } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/VehicleSearchHistoryResponse';
import { VehicleSearchHistoryUpdateResponse } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/VehicleSearchHistoryUpdateResponse';
import { GetAvailableDMSServicesResponse } from '@1po/1po-bff-fe-spec/generated/dms/response/GetAvailableDMSServicesResponse';
import { ConnectAsUserProfileResponse } from '@1po/1po-bff-fe-spec/generated/user/response/ConnectAsUserProfileResponse';
import { GetPossibleBuyersForSellerResponse } from '@1po/1po-bff-fe-spec/generated/user/response/GetPossibleBuyersForSellerResponse';
import { GetPossibleSellersResponse } from '@1po/1po-bff-fe-spec/generated/user/response/GetPossibleSellersResponse';
import { GetUserSellersResponse } from '@1po/1po-bff-fe-spec/generated/user/response/GetUserSellersResponse';
import { PendingSellerWarningResponse } from '@1po/1po-bff-fe-spec/generated/user/response/PendingSellerWarningResponse';
import { SearchUserProfileResponse } from '@1po/1po-bff-fe-spec/generated/user/response/SearchUserProfileResponse';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { ISSUER_LOCAL_PARAM, LOGIN_METHOD_LOCAL_PARAM, SELECTED_PROFILE_PARAM } from 'domains/auth/Auth.types';
import { getAuthenticatedUser, getUserManager, mapUserFromOidcUser } from 'domains/auth/Auth.util';
import { setInitialState as setInitialBasketState } from 'domains/basket/Basket.store';
import { fetchVehicleFunction } from 'domains/catalog/Catalog.requests';
import { getLastVehicleDetail, setInitialState as setInitialCatalogState } from 'domains/catalog/Catalog.store';
import { setInitialState as setInitialEPRState } from 'domains/electronicPartsRepair/ElectronicPartsRepair.store';
import { sendGetEstimateById } from 'domains/estimate/Estimate.api';
import { getCurrentEstimateId, setInitialState as setInitialEstimateState } from 'domains/estimate/Estimate.store';
import { setInitialState as setInitialGarageState } from 'domains/garage/Garage.store';
import { setInitialState as setInitialInformationState } from 'domains/information/Information.store';
import { setInitialState as setMaintenancePlanInitialState } from 'domains/maintenancePlan/MaintenancePlan.store';
import { setInitialState as setMaintenanceValuesState } from 'domains/maintenanceValues/MaintenanceValues.store';
import { setInitialState as setInitialOrderState } from 'domains/order/Order.store';
import { setInitialState as setInitialOrderValidationState } from 'domains/orderValidation/OrderValidation.store';
import { setInitialState as setInitialPicturesState } from 'domains/pictures/Pictures.store';

import {
  setInitialState as setInitialPromotionState,
  setResubscribeableFieldsUndefined as setPromoResubscribeableFieldsUndefined,
} from 'domains/promotion/Promotion.store';
import {
  resetReferences,
  resetReferencesDiscounts,
  resetReferencesPriceData,
  setInitialState as setInitialReferencesState,
} from 'domains/references';
import { setInitialState as setInitialTireState } from 'domains/tires/Tire.store';
import {
  addVehicleToSearchHistoryRequest,
  connectToDelegateSession,
  disconnectFromConnectAsUser,
  getPossibleBuyersForUser,
  getPossibleSellersForUser,
  sendAddFavoriteTireBrandForUserRequest,
  sendClearSearchHistoryRequest,
  sendConfigureWorkshopIdRequest,
  sendGetAvailableDMSServicesRequest,
  sendGetUserProfileRequest,
  sendGetUserSellersRequest,
  sendGetVehicleHistorySubscriptionRequest,
  sendRemoveFavoriteTireBrandForUserRequest,
  sendRemoveVehicleFromSearchHistoryRequest,
  sendResetFavoriteTireBrandForUserRequest,
  sendUpdateDhCatalogReferenceViewRequestSaga,
  sendUpdateUserProfileLanguagesRequest,
  sendUpdateUserSellerRequest,
} from 'domains/user/User.api';
import { mapRoles, mapUserProfileResponse } from 'domains/user/User.mapper';
import * as actions from 'domains/user/User.store';
import {
  getDelegationSessionStatus,
  getDocumentLanguage,
  getDocumentLanguageFallback,
  getIsReload,
  getTradingProfile,
  getUserCommercialLink,
  getUserProfileSearchStatus,
  getWebLanguage,
  initialState as userStoreInitialState,
  resetDelegationSession,
  setAvailableDMSServices,
  setCatalogReferenceView,
  setCommercialLink,
  setDelegationSession,
  setDelegationSessionStatus,
  setDocumentLanguages,
  setFavoriteTireBrandList,
  setInitialState,
  setPendingSellerWarning,
  setPendingSellerWarningStatus,
  setPossibleBuyersForSellerToDelegate,
  setPossibleBuyersForSellerToDelegateNoData,
  setPossibleSellersToDelegate,
  setPossibleSellersToDelegateNoData,
  setResubscribeableFieldsUndefined,
  setShowSellerPopupFalse,
  setUser,
  setUserDisconnectFrom,
  setUserIsLoading,
  setUserProfileSearchStatus,
  setUserRoles,
  setUserSellersToChoose,
  setVehicleSearchHistory,
  setVehicleSearchHistoryNoDataStatus,
  setVehicleSearchHistoryUpdate,
  setWebLanguage,
  setWorkshopId,
} from 'domains/user/User.store';
import { DEFAULT_COUNTRY } from 'domains/user/User.types';
import { wsDisconnect } from 'domains/webSockets/WebSocket.store';
import { WsResponse } from 'domains/webSockets/WebSocket.types';
import { notifyTop } from 'UI/Notification/notification';
import { FOUND, LOADING, NOT_FOUND, sagaGuard, select } from 'utils';
import { endSagaWithSuccess, startSaga } from 'utils/domainStore';
import { AppTranslation, changeLanguage } from 'utils/i18n';
import { Language } from 'utils/i18n/Language';
import { logAndSetGTMLanguage } from 'utils/remoteLogger/remoteLogMetaData';

export function* setCurrentUserSaga(action: AnyAction): SagaIterator {
  yield call(startSaga, action);

  yield put(setUserIsLoading());
  const fetchedUser = yield call(getAuthenticatedUser);
  const user = fetchedUser && !fetchedUser.expired ? mapUserFromOidcUser(fetchedUser) : undefined;

  yield put(setUser(user));
  yield call(endSagaWithSuccess, action, user);
}

export function* updateUserProfileLanguagesRequestSaga({
  payload,
}: ReturnType<typeof actions.updateUserProfileLanguagesRequestSaga>): SagaIterator {
  const { webLanguage, documentLanguage, documentLanguageFallback } = payload;
  yield put(sendUpdateUserProfileLanguagesRequest(webLanguage, documentLanguage, documentLanguageFallback));
}

export function* getUserProfileRequestSaga(): SagaIterator {
  const isReload = yield* select(getIsReload);
  yield put(setUserProfileSearchStatus(LOADING));
  yield put(setPendingSellerWarningStatus(LOADING));
  yield put(sendGetUserProfileRequest({ isLoggedIn: isReload }));
}

export function* resetDelegationSessionSaga(): SagaIterator {
  yield put(resetDelegationSession());
}

export function* getUserProfileResponseSaga(action: WsResponse<SearchUserProfileResponse>): SagaIterator {
  const webLang = yield* select(getWebLanguage);
  const docLangPrimary = yield* select(getDocumentLanguage);
  const docLangSecondary = yield* select(getDocumentLanguageFallback);
  const searchStatus = yield* select(getUserProfileSearchStatus);
  const currentCommercialLink = yield* select(getUserCommercialLink);
  const delegationStatus = yield* select(getDelegationSessionStatus);

  const userProfile = mapUserProfileResponse(action);

  if (userProfile?.commercialLink) {
    if (currentCommercialLink && currentCommercialLink?.seller?.id !== userProfile.commercialLink.seller?.id) {
      yield call(reconnectUserToDifferentSeller);
    }
    yield put(setCommercialLink(userProfile.commercialLink));
  }

  if (action.payload?.workshopId) {
    yield put(setWorkshopId(action.payload.workshopId));
  }
  if (action.payload?.favoriteTireBrandList) {
    yield put(setFavoriteTireBrandList(action.payload.favoriteTireBrandList));
  }
  const userRoles = mapRoles(action.payload.roles);
  yield put(setUserRoles(userRoles));

  if (action.payload.catalogDisplay) {
    yield put(setCatalogReferenceView(action.payload.catalogDisplay));
  }

  if (userProfile?.webLanguage && !Language.languagesAreEqual(webLang, userProfile.webLanguage)) {
    yield put(actions.changeWebLanguageSaga(userProfile.webLanguage));
  }
  if (
    userProfile?.documentLanguage &&
    userProfile?.documentLanguageFallback &&
    (!Language.languagesAreEqual(docLangPrimary, userProfile.documentLanguage) ||
      !Language.languagesAreEqual(docLangSecondary, userProfile.documentLanguageFallback))
  ) {
    yield put(
      actions.changeDocumentLanguageSaga({
        documentLanguage: userProfile.documentLanguage,
        documentLanguageFallback: userProfile.documentLanguageFallback,
      }),
    );
  }
  if (searchStatus !== FOUND) {
    yield put(setUserProfileSearchStatus(FOUND));
  }

  if (delegationStatus === undefined) {
    yield put(setDelegationSessionStatus(NOT_FOUND));
  }
}

export function* logoutUser(action: AnyAction): SagaIterator {
  yield call(startSaga, action);
  const webLanguageBeforeLogout = yield* select(getWebLanguage);

  getUserManager()
    .signoutRedirect()
    .finally(() => {
      // removeUser after signout otherwise got 400 error
      getUserManager().removeUser();
    });

  sessionStorage.removeItem(LOGIN_METHOD_LOCAL_PARAM);
  sessionStorage.removeItem(ISSUER_LOCAL_PARAM);
  sessionStorage.removeItem(SELECTED_PROFILE_PARAM);

  yield put(setInitialState());
  yield put(setInitialCatalogState());
  yield put(setInitialReferencesState());
  yield put(setInitialBasketState());
  yield put(setInitialEPRState());
  yield put(setInitialEstimateState());
  yield put(setInitialOrderValidationState());
  yield put(setInitialOrderState());
  yield put(setInitialGarageState());
  yield put(setInitialPicturesState());
  yield put(setInitialPromotionState());
  yield put(setInitialTireState());
  yield put(setMaintenancePlanInitialState());
  yield put(setMaintenanceValuesState());
  yield put(wsDisconnect());

  if (!Language.languagesAreEqual(webLanguageBeforeLogout, userStoreInitialState.webLanguage)) {
    yield put(actions.changeWebLanguageSaga(webLanguageBeforeLogout));
  }

  yield call(endSagaWithSuccess, action, null);
}

export function* disconnectUser(action: AnyAction): SagaIterator {
  yield call(startSaga, action);

  getUserManager().removeUser();
  yield put(wsDisconnect());
  yield call(endSagaWithSuccess, action, null);
}

export function* connectAsUserSaga({ payload }: ReturnType<typeof actions.connectAsUserSaga>): SagaIterator {
  yield put(setDelegationSessionStatus(LOADING));
  yield put(setInitialEPRState());
  yield put(setInitialGarageState());
  yield put(setResubscribeableFieldsUndefined());
  yield call(reconnectUserToDifferentSeller);
  yield put(connectToDelegateSession(payload.buyerId, payload.sellerId));
}

function* reconnectUserToDifferentSeller() {
  yield put(setInitialCatalogState());
  yield put(setInitialPicturesState());
  yield put(setInitialReferencesState());
  yield put(setInitialBasketState());
  yield put(setInitialOrderValidationState());
  yield put(setInitialPromotionState());
  yield put(setInitialTireState());
  yield put(setMaintenancePlanInitialState());
  yield put(setMaintenanceValuesState());
  yield put(setResubscribeableFieldsUndefined());
}

export function* reloadSubscriptionBeUserSaga(): SagaIterator {
  yield put(setResubscribeableFieldsUndefined());
  yield put(setPromoResubscribeableFieldsUndefined());
  yield put(setInitialBasketState());
  yield put(setInitialEPRState());
  yield put(resetReferencesDiscounts());
  yield put(resetReferencesPriceData());
  const estimateId = yield* select(getCurrentEstimateId);
  const tradingProfile = yield* select(getTradingProfile);
  yield put(setInitialEstimateState());
  if (estimateId && tradingProfile?.buyerId) {
    yield put(sendGetEstimateById({ estimateId, garageId: tradingProfile.buyerId }));
  }
}

export function* connectAsUserResponseSaga(action: WsResponse<ConnectAsUserProfileResponse>): SagaIterator {
  const { commercialLink, roles } = action.payload;

  const userRoles = mapRoles(roles);
  yield put(setVehicleSearchHistory(undefined));
  yield put(setInitialBasketState());
  yield put(setInitialEPRState());
  yield put(setInitialEstimateState());
  yield put(setInitialOrderState());

  yield put(setDelegationSession({ commercialLink, userRoles }));
}

export function* disconnectFromConnectAsUserSaga(): SagaIterator {
  yield put(disconnectFromConnectAsUser());
}

export function* notifyAboutDelegationDisconnectResponseSaga(): SagaIterator {
  const delegationStatus = yield* select(getDelegationSessionStatus);
  if (delegationStatus === FOUND) {
    yield put(setDelegationSessionStatus(LOADING));
    yield put(disconnectFromConnectAsUser());
  }
}

export function* disconnectFromConnectAsUserResponseSaga(): SagaIterator {
  yield put(setDelegationSessionStatus(NOT_FOUND));
  yield put(setInitialCatalogState());

  yield put(setInitialReferencesState());
  yield put(setInitialBasketState());
  yield put(setInitialEPRState());
  yield put(setInitialEstimateState());
  yield put(setInitialOrderValidationState());
  yield put(setInitialOrderState());
  yield put(setInitialGarageState());
  yield put(setInitialPicturesState());
  yield put(setInitialPromotionState());
  yield put(setInitialTireState());
  yield put(setMaintenancePlanInitialState());
  yield put(setMaintenanceValuesState());
  yield put(setUserDisconnectFrom());
  yield put(setResubscribeableFieldsUndefined());
}

export function* getPossibleSellersForDelegationSaga(): SagaIterator {
  yield put(setPossibleSellersToDelegateNoData(LOADING));
  yield put(getPossibleSellersForUser());
}

export function* getPossibleSellersForDelegationResponseSaga(
  action: WsResponse<GetPossibleSellersResponse>,
): SagaIterator {
  const { sellers } = action.payload;
  yield put(setPossibleSellersToDelegate({ sellers }));
}

export function* getPossibleBuyersForDelegationSaga({
  payload,
}: ReturnType<typeof actions.getPossibleBuyersForDelegationSaga>): SagaIterator {
  yield put(
    setPossibleBuyersForSellerToDelegateNoData({
      sellerId: payload,
      status: LOADING,
    }),
  );
  yield put(getPossibleBuyersForUser(payload));
}

export function* getPossibleBuyersForDelegationResponseSaga(
  action: WsResponse<GetPossibleBuyersForSellerResponse>,
): SagaIterator {
  const { buyers, sellerId } = action.payload;
  yield put(setPossibleBuyersForSellerToDelegate({ sellerId, buyers }));
}

export function* getPendingSellerWarningResponseSaga(action: WsResponse<PendingSellerWarningResponse>): SagaIterator {
  const { pendingWarning } = action.payload;
  yield put(setPendingSellerWarning({ pendingWarning }));
}

export function* changeWebLanguageSaga({ payload }: ReturnType<typeof actions.changeWebLanguageSaga>): SagaIterator {
  const toChangeLanguage = payload.key;
  const languageUsed = yield call(changeLanguage, toChangeLanguage);

  if (languageUsed !== toChangeLanguage) {
    const usedLanguageName = Language.findInWebLang(languageUsed)?.name;
    if (usedLanguageName) {
      notifyTop(
        'warning',
        AppTranslation.t('errors.translations_not_found', 'Translations not found'),
        `Using ${usedLanguageName} as fallback language`,
      );
    }
  }

  const webLang = Language.findInWebLang(toChangeLanguage);
  logAndSetGTMLanguage(webLang);

  yield put(setWebLanguage(payload));
  yield put(setInitialInformationState());
}

export function* configureWorkshopId({ payload }: ReturnType<typeof actions.configureWorkshopId>): SagaIterator {
  yield put(sendConfigureWorkshopIdRequest({ workshopId: payload }));
}

export function* getAvailableDMSServicesRequestSaga({
  payload,
}: ReturnType<typeof actions.getAvailableDMSServicesRequestSaga>): SagaIterator {
  const { workshopId } = payload;
  yield put(setAvailableDMSServices(LOADING));
  yield put(sendGetAvailableDMSServicesRequest({ workshopId }));
}

export function* getAvailableDMSServicesResponseSaga(
  action: WsResponse<GetAvailableDMSServicesResponse>,
): SagaIterator {
  const { services } = action.payload;
  yield put(setAvailableDMSServices(services));
}

export function* changeDocumentLanguageSaga({
  payload,
}: ReturnType<typeof actions.changeDocumentLanguageSaga>): SagaIterator {
  const { documentLanguage, documentLanguageFallback } = payload;
  yield put(setDocumentLanguages({ lang: documentLanguage, fallback: documentLanguageFallback }));
  const vehicle = yield* select(getLastVehicleDetail);
  yield call(
    fetchVehicleFunction,
    put,
    vehicle?.vehicleKey ?? '',
    false,
    vehicle?.country ?? DEFAULT_COUNTRY.key,
    vehicle?.catalogSource,
  );
  yield put(setInitialCatalogState());
  yield put(resetReferences());
}

export function* getUserSellersRequestSaga(): SagaIterator {
  yield put(setUserSellersToChoose(LOADING));
  yield put(sendGetUserSellersRequest());
}

export function* updateDhCatalogReferenceViewRequestSaga({
  payload,
}: ReturnType<typeof actions.updateDhCatalogReferenceViewRequestSaga>): SagaIterator {
  yield put(sendUpdateDhCatalogReferenceViewRequestSaga({ catalogDisplay: payload }));
}

export function* updateUserSellerSaga({ payload }: ReturnType<typeof actions.updateUserSellerSaga>): SagaIterator {
  yield put(setShowSellerPopupFalse());
  yield put(sendUpdateUserSellerRequest({ sellerId: payload }));
}

export function* addFavoriteTireBrandRequestSaga({
  payload,
}: ReturnType<typeof actions.addFavoriteTireBrandRequestSaga>): SagaIterator {
  yield put(sendAddFavoriteTireBrandForUserRequest({ tireBrand: payload.tireBrand }));
}

export function* removeFavoriteTireBrandRequestSaga({
  payload,
}: ReturnType<typeof actions.removeFavoriteTireBrandRequestSaga>): SagaIterator {
  yield put(sendRemoveFavoriteTireBrandForUserRequest({ tireBrand: payload.tireBrand }));
}

export function* resetFavoriteTireBrandRequestSaga(): SagaIterator {
  yield put(sendResetFavoriteTireBrandForUserRequest({}));
}

export function* getUserSellersResponseSaga(action: WsResponse<GetUserSellersResponse>): SagaIterator {
  const { sellers } = action.payload;
  yield put(setUserSellersToChoose(sellers));
}

export function* fetchVehicleSearchHistorySubscriptionSaga(): SagaIterator {
  yield put(sendGetVehicleHistorySubscriptionRequest());
  yield put(setVehicleSearchHistoryNoDataStatus(LOADING));
}

export function* fetchAddVehicleToSearchHistoryRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchAddVehicleToSearchHistoryRequestSaga>): SagaIterator {
  const { vehicle } = payload;
  yield put(addVehicleToSearchHistoryRequest(vehicle));
}

export function* fetchRemoveVehicleFromSearchHistoryRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchRemoveVehicleFromSearchHistoryRequestSaga>): SagaIterator {
  const { vehicleKey } = payload;
  yield put(sendRemoveVehicleFromSearchHistoryRequest(vehicleKey));
}

export function* fetchClearVehicleSearchHistoryRequestSaga(): SagaIterator {
  yield put(sendClearSearchHistoryRequest());
}

export function* fetchVehicleSearchHistoryResponseSaga(action: WsResponse<VehicleSearchHistoryResponse>): SagaIterator {
  const { vehicles } = action.payload;
  if (vehicles?.length > 0) {
    yield put(setVehicleSearchHistory(vehicles));
  } else {
    yield put(setVehicleSearchHistoryNoDataStatus(NOT_FOUND));
  }
}

export function* fetchVehicleSearchHistoryUpdateResponseSaga(
  action: WsResponse<VehicleSearchHistoryUpdateResponse>,
): SagaIterator {
  yield put(setVehicleSearchHistoryUpdate(action.payload));
}

export function* UsersSagas(): SagaIterator {
  yield takeEvery(actions.setCurrentUserSaga.type, sagaGuard(setCurrentUserSaga));
  yield takeEvery(actions.getUserProfileRequestSaga.type, sagaGuard(getUserProfileRequestSaga));
  yield takeEvery(actions.getUserProfileResponseSaga.type, sagaGuard(getUserProfileResponseSaga));
  yield takeEvery(actions.updateUserProfileLanguagesRequestSaga.type, sagaGuard(updateUserProfileLanguagesRequestSaga));
  yield takeEvery(actions.logoutUser.type, sagaGuard(logoutUser));
  yield takeEvery(actions.changeWebLanguageSaga.type, sagaGuard(changeWebLanguageSaga));
  yield takeEvery(actions.changeDocumentLanguageSaga.type, sagaGuard(changeDocumentLanguageSaga));
  yield takeEvery(actions.configureWorkshopId.type, sagaGuard(configureWorkshopId));
  yield takeEvery(actions.getAvailableDMSServicesRequestSaga.type, sagaGuard(getAvailableDMSServicesRequestSaga));
  yield takeEvery(actions.getAvailableDMSServicesResponseSaga.type, sagaGuard(getAvailableDMSServicesResponseSaga));
  yield takeEvery(actions.getUserSellersRequestSaga.type, sagaGuard(getUserSellersRequestSaga));
  yield takeEvery(actions.updateUserSellerSaga.type, sagaGuard(updateUserSellerSaga));
  yield takeEvery(actions.getUserSellersResponseSaga.type, sagaGuard(getUserSellersResponseSaga));
  yield takeEvery(
    actions.notifyAboutDelegationDisconnectResponseSaga.type,
    sagaGuard(notifyAboutDelegationDisconnectResponseSaga),
  );
  yield takeEvery(actions.disconnectUser.type, sagaGuard(disconnectUser));
  yield takeEvery(actions.connectAsUserSaga.type, sagaGuard(connectAsUserSaga));
  yield takeEvery(actions.resetDelegationSessionSaga.type, sagaGuard(resetDelegationSessionSaga));
  yield takeEvery(actions.connectAsUserResponseSaga.type, sagaGuard(connectAsUserResponseSaga));
  yield takeEvery(actions.addFavoriteTireBrandRequestSaga.type, sagaGuard(addFavoriteTireBrandRequestSaga));
  yield takeEvery(actions.removeFavoriteTireBrandRequestSaga.type, sagaGuard(removeFavoriteTireBrandRequestSaga));
  yield takeEvery(actions.resetFavoriteTireBrandRequestSaga.type, sagaGuard(resetFavoriteTireBrandRequestSaga));
  yield takeEvery(actions.disconnectFromConnectAsUserSaga.type, sagaGuard(disconnectFromConnectAsUserSaga));
  yield takeEvery(
    actions.disconnectFromConnectAsUserResponseSaga.type,
    sagaGuard(disconnectFromConnectAsUserResponseSaga),
  );
  yield takeEvery(
    actions.fetchVehicleSearchHistorySubscriptionSaga.type,
    sagaGuard(fetchVehicleSearchHistorySubscriptionSaga),
  );
  yield takeEvery(
    actions.fetchAddVehicleToSearchHistoryRequestSaga.type,
    sagaGuard(fetchAddVehicleToSearchHistoryRequestSaga),
  );
  yield takeEvery(
    actions.fetchRemoveVehicleFromSearchHistoryRequestSaga.type,
    sagaGuard(fetchRemoveVehicleFromSearchHistoryRequestSaga),
  );
  yield takeEvery(
    actions.fetchClearVehicleSearchHistoryRequestSaga.type,
    sagaGuard(fetchClearVehicleSearchHistoryRequestSaga),
  );
  yield takeEvery(actions.fetchVehicleSearchHistoryResponseSaga.type, sagaGuard(fetchVehicleSearchHistoryResponseSaga));
  yield takeEvery(
    actions.fetchVehicleSearchHistoryUpdateResponseSaga.type,
    sagaGuard(fetchVehicleSearchHistoryUpdateResponseSaga),
  );
  yield takeEvery(actions.reloadSubscriptionBeUserSaga.type, sagaGuard(reloadSubscriptionBeUserSaga));
  yield takeEvery(
    actions.updateDhCatalogReferenceViewRequestSaga.type,
    sagaGuard(updateDhCatalogReferenceViewRequestSaga),
  );
  yield takeEvery(actions.getPossibleSellersForDelegationSaga.type, sagaGuard(getPossibleSellersForDelegationSaga));
  yield takeEvery(
    actions.getPossibleSellersForDelegationResponseSaga.type,
    sagaGuard(getPossibleSellersForDelegationResponseSaga),
  );
  yield takeEvery(actions.getPossibleBuyersForDelegationSaga.type, sagaGuard(getPossibleBuyersForDelegationSaga));
  yield takeEvery(
    actions.getPossibleBuyersForDelegationResponseSaga.type,
    sagaGuard(getPossibleBuyersForDelegationResponseSaga),
  );
  yield takeEvery(actions.getPendingSellerWarningResponseSaga.type, sagaGuard(getPendingSellerWarningResponseSaga));
}
