import { Table } from 'antd';
import styled from 'styled-components';
import { Box } from 'UI';

export const OverviewTable = styled(Table)`
  flex: 1;

  table {
    position: relative;
  }

  tbody td {
    position: relative;
    top: 7px;
  }

  thead > tr > th {
    text-align: center;
    background-color: ${({ theme }) => theme.color.grey_04};
  }

  thead > tr {
    box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.15), 0 3px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 2px rgba(0, 0, 0, 0.16);
  }

  tbody > tr {
    border-bottom: solid ${({ theme }) => theme.color.grey85} 1px;
  }

  .ant-table-row {
    :hover {
      cursor: pointer;
    }
  }

  .ant-table-expanded-row > td > div > div > div > div > div > div > div > table > tbody > tr {
    :hover {
      cursor: default;
    }
  }

  :active {
    background-color: ${({ theme }) => theme.color.grey95} 8px;
  }

  tbody > tr.ant-table-expanded-row > td {
    padding: 0 0 0 0;
  }
` as typeof Table;

export const OverviewReferencesDetailTable = styled(Table)`
  flex: 1;

  thead > tr {
    box-shadow: 0 8px 4px 4px rgba(0, 0, 0, 0.02) inset, 0 3px 3px 0 rgba(0, 0, 0, 0.04) inset,
      0 2px 2px 2px rgba(0, 0, 0, 0.02) inset;
  }

  thead > tr > th {
    text-align: center;
  }

  tbody > tr {
    box-shadow: 0 8px 4px 4px rgba(0, 0, 0, 0.03) inset, 0 3px 3px 0 rgba(0, 0, 0, 0.02) inset,
      0 2px 2px 2px rgba(0, 0, 0, 0.01) inset;
  }

  tbody > tr > td {
    border-bottom: solid ${({ theme }) => theme.color.grey85} 1px;
  }

  :active {
    background-color: ${({ theme }) => theme.color.grey95} 8px;
  }

  tbody > tr.ant-table-expanded-row > td {
    padding: 0 0 0 0;
  }
` as typeof Table;

export const TotalHeader = styled(Box)`
  height: 54px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  background-color: ${({ theme }) => theme.color.grey_very_light_2};
  background-size: cover;
  background-position: center;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
`;

export const TotalDetails = styled(Box)`
  width: 500px;
  height: 202px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  border: solid 2px ${({ theme }) => theme.color.grey_very_light_2};
`;

export const EstimateTotal = styled(Box)`
  width: 330px;
  height: 202px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  border: solid 2px ${({ theme }) => theme.color.grey_very_light_2};
`;

export const TotalSeparator = styled(Box)`
  height: 1px;
  width: 290px;
  margin: 20px 20px 20px 20px;
  padding: 0 0 0 0;
  background-color: ${({ theme }) => theme.color.grey_very_light_2};
`;
