import { TextFacetItem } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/response/GetIAMReferencesResponse';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { TireItem } from '@1po/1po-bff-fe-spec/generated/tire/response/TireSearchResponse';
import { TFunction } from 'i18next';
import {
  ENTRY_BRAND_LABEL,
  EXTRA_LOAD_LABEL,
  FILTER_BRAND,
  FILTER_DELIVERY,
  FILTER_FEATURES,
  FILTER_FUEL_EFFICIENCY,
  FILTER_NOISE_LEVEL,
  FILTER_PRODUCT_RANGE,
  FILTER_SEASON,
  FILTER_WET_GRIP,
  Filters,
  isFilteredStocks,
  isInRange,
  MEDIUM_BRAND_LABEL,
  PMSF_LABEL,
  POSITIVE_FEATURE_VALUES,
  PREMIUM_BRAND_LABEL,
  RangeFacetLocal,
  RUN_FLAT_LABEL,
  SELF_SEAL_LABEL,
  TextFacetLocal,
} from 'components/Filter/Filter.types';
import { ReferencePriceType } from 'domains/references';
import { TireBrandDetailLocal } from 'domains/tires/Tire.types';
import { GarageView, SparePartsViewType } from 'domains/user';
import { FOUND, getData, hasData, NO_DATA, SearchData } from 'utils';

export const getUpdatedTireTextFilters = (
  textFilters: TextFacetLocal[],
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  filteredTires: TireItem[],
  brandCategoriesMap: SearchData<Map<string, TireBrandDetailLocal>>,
  t: TFunction,
): TextFacetLocal[] => {
  return textFilters.map((tf) => {
    return {
      ...tf,
      items: tf.items.map((tfi) => {
        const resultItems = filteredTires.filter((tire) => {
          const stock = stocks.get(tire.partNumber);
          if (tf.id === FILTER_BRAND) {
            return tfi.label === tire?.brandName;
          }
          if (tf.id === FILTER_SEASON) {
            return tfi.label === tire?.season;
          }
          if (tf.id === FILTER_DELIVERY) {
            const tfiTextFilter = new Map();
            tfiTextFilter.set(FILTER_DELIVERY, [tfi.label]);
            return isFilteredStocks(stock, { textFilters: tfiTextFilter, rangeFilters: new Map() }, t);
          }
          if (tf.id === FILTER_FUEL_EFFICIENCY) {
            return tfi.label === tire?.fuelConsumption;
          }
          if (tf.id === FILTER_WET_GRIP) {
            return tfi.label === tire?.wetGrip;
          }
          if (tf.id === FILTER_NOISE_LEVEL) {
            return tfi.label === tire?.exteriorSound;
          }
          if (tf.id === FILTER_FEATURES) {
            switch (tfi.label) {
              case SELF_SEAL_LABEL:
                return tire.selfSeal ? POSITIVE_FEATURE_VALUES.includes(tire.selfSeal) : false;
              case PMSF_LABEL:
                return tire.pmsf ? POSITIVE_FEATURE_VALUES.includes(tire.pmsf) : false;
              case EXTRA_LOAD_LABEL:
                return tire.extraLoad ? tire.extraLoad.length > 0 : false;
              case RUN_FLAT_LABEL:
                return tire.runFlat ? tire.runFlat.length > 0 : false;
              default:
                return false;
            }
          }
          if (tf.id === FILTER_PRODUCT_RANGE && tire.brandId && brandCategoriesMap.searchStatus === FOUND) {
            const tireBrandDetail = brandCategoriesMap.data?.get(tire.brandId);
            switch (tfi.label) {
              case PREMIUM_BRAND_LABEL:
                return tireBrandDetail?.category === PREMIUM_BRAND_LABEL;
              case MEDIUM_BRAND_LABEL:
                return tireBrandDetail?.category === MEDIUM_BRAND_LABEL;
              case ENTRY_BRAND_LABEL:
                return tireBrandDetail?.category === ENTRY_BRAND_LABEL;
              default:
                return false;
            }
          }
        });
        return { ...tfi, numberOfItems: resultItems.length };
      }),
    };
  });
};

export const getUpdatedTireRangeFilters = (
  rangeFilters: RangeFacetLocal[],
  filteredTires: TireItem[],
): RangeFacetLocal[] => {
  return rangeFilters.map((rf) => {
    if (rf.id === 'prices') {
      return { ...rf, numberOfItems: filteredTires.length };
    } else {
      return rf;
    }
  });
};

export const getTireDeliveryFilterOptions = (
  stocksFilterOptions: TextFacetLocal,
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  filteredTires: TireItem[],
  t: TFunction,
): TextFacetLocal => {
  const getItems: TextFacetItem[] = stocksFilterOptions.items.filter((soi) => {
    return [...stocks.values()].some((s) => {
      const soiTextFilter = new Map();
      soiTextFilter.set('delivery', [soi.label]);
      return isFilteredStocks(s, { textFilters: soiTextFilter, rangeFilters: new Map() }, t);
    });
  });
  const newItemsMap = new Map<string, TextFacetItem>();
  getItems.forEach((tfi) => {
    const resultItems = filteredTires.filter((reference) => {
      const stock = stocks.get(reference.partNumber);
      const tfiTextFilter: Map<string, string[]> = new Map();
      tfiTextFilter.set('delivery', [tfi.label]);
      return isFilteredStocks(stock, { textFilters: tfiTextFilter, rangeFilters: new Map() }, t);
    });
    newItemsMap.set(tfi.label, { label: tfi.label, numberOfItems: resultItems.length });
  });

  return {
    ...stocksFilterOptions,
    items: Array.from([...newItemsMap], ([label, value]) => ({
      numberOfItems: value.numberOfItems,
      label,
    })),
  };
};

const refPrice = (
  sparePartsView: SparePartsViewType,
  item: { reference: string; prices?: ReferencePriceType | NO_DATA },
) => {
  return Number(
    sparePartsView === GarageView
      ? getData(item.prices)?.garageView?.vatExcludedPrice
      : getData(item.prices)?.clientView?.recommendedPriceVatIncluded,
  );
};

export const getFilteredTires = (
  availableTires: TireItem[],
  filters: Filters,
  prices: { prices: NO_DATA | ReferencePriceType; reference: string }[],
  sparePartsView: SparePartsViewType,
  showPriceless: boolean,
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  brandCategoriesMap: SearchData<Map<string, TireBrandDetailLocal>>,
  t: TFunction,
): TireItem[] => {
  const textFilteredReferences = availableTires.filter((tire) => {
    const stock = stocks.get(tire.partNumber);
    const isTextFiltered = [...filters.textFilters.keys()].some((facetId) => {
      const filterItems = filters.textFilters.get(facetId) ?? [];
      if (facetId === FILTER_BRAND) return !filterItems.includes(tire?.brandName ?? '');
      if (facetId === FILTER_SEASON) return !filterItems.includes(tire?.season ?? '');
      if (facetId === FILTER_DELIVERY) return !isFilteredStocks(stock, filters, t);
      if (facetId === FILTER_FUEL_EFFICIENCY) return !filterItems.includes(tire?.fuelConsumption ?? '');
      if (facetId === FILTER_WET_GRIP) return !filterItems.includes(tire?.wetGrip ?? '');
      if (facetId === FILTER_NOISE_LEVEL) return !filterItems.includes(tire?.exteriorSound ?? '');
      if (facetId === FILTER_FEATURES) {
        return filterItems.every((filter) => {
          if (filter === SELF_SEAL_LABEL) {
            return tire.selfSeal ? !POSITIVE_FEATURE_VALUES.includes(tire.selfSeal) : true;
          }
          if (filter === PMSF_LABEL) {
            return tire.pmsf ? !POSITIVE_FEATURE_VALUES.includes(tire.pmsf) : true;
          }
          if (filter === EXTRA_LOAD_LABEL) {
            return tire.extraLoad ? tire.extraLoad.length === 0 : true;
          }
          if (filter === RUN_FLAT_LABEL) {
            return tire.runFlat ? tire.runFlat.length === 0 : true;
          }
          return true;
        });
      }
      if (facetId === FILTER_PRODUCT_RANGE && brandCategoriesMap.searchStatus === FOUND && tire.brandId) {
        const tireBrandDetail = brandCategoriesMap.data?.get(tire.brandId)?.category;
        return !filterItems.includes(tireBrandDetail ?? '');
      }
      return false;
    });
    return !isTextFiltered;
  });
  const priceFilterRange = filters.rangeFilters.get('prices');
  const pricesInRange = prices.filter((price) => {
    if (!priceFilterRange) {
      return true;
    }
    return !(
      isInRange([String(refPrice(sparePartsView, price))], {
        min: priceFilterRange.min,
        max: priceFilterRange.max,
      }) ||
      (!hasData(price) && showPriceless)
    );
  });
  return textFilteredReferences.filter((i) =>
    pricesInRange.find((priceInRange) => priceInRange.reference === i.partNumber),
  );
};
