import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { getCurrentEstimateId, getSelectedTab, setCurrentEstimateId } from 'domains/estimate/Estimate.store';
import { ESTIMATE_TAB, EstimateIdParam, EstimateTabParam } from 'domains/estimate/Estimate.types';
import EstimatePage from 'pages/EstimatePage/EstimatePage';

export const EstimateBase = () => {
  const selectedTab = useSelector(getSelectedTab);
  const currentEstimateId = useSelector(getCurrentEstimateId);
  const params = useMemo(
    () => new URLSearchParams(location.search),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentEstimateId, location.search],
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabKey, setTabKey] = useState<ESTIMATE_TAB>((params.get(EstimateTabParam) as ESTIMATE_TAB) || selectedTab);

  const estimateIdParam = params.get(EstimateIdParam);

  // set estimateId parameter properly in the URL and in store - do not set it in history and settings
  useEffect(() => {
    const estimateTabParam = params.get(EstimateTabParam);
    if (estimateTabParam === null) {
      setTabKey(params.get(EstimateTabParam) as ESTIMATE_TAB);
      history.push(`${ROUTER_ESTIMATE}?${EstimateTabParam}=estimate`);
      return;
    }
    if (tabKey !== params.get(EstimateTabParam)) {
      setTabKey(params.get(EstimateTabParam) as ESTIMATE_TAB);
      return;
    }
    if (tabKey !== 'estimate') {
      return;
    }
    if (estimateIdParam && estimateIdParam !== currentEstimateId) {
      dispatch(setCurrentEstimateId(estimateIdParam));
      return;
    }
    if (!estimateIdParam && currentEstimateId) {
      history.push(`${ROUTER_ESTIMATE}?${EstimateTabParam}=estimate&${EstimateIdParam}=${currentEstimateId}`);
    }
  }, [estimateIdParam, currentEstimateId, dispatch, history, tabKey, selectedTab, params]);
  return <EstimatePage estimateId={estimateIdParam ?? currentEstimateId} tabKey={tabKey} setTabKey={setTabKey} />;
};
