/* eslint-disable max-len */
import { GetGarageInfo } from '@1po/1po-bff-fe-spec/generated/garage/response/GetGarageInfo';
import { put, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { sendChangeGarageEmail, sendGetGarageInfo } from 'domains/garage/Garage.api';
import { setGarage, setGarageEmail, setGarageNoDataStatus } from 'domains/garage/Garage.store';
import { getTradingProfile } from 'domains/user';
import { LOADING, sagaGuard, select } from 'utils';
import * as actions from './Garage.store';
import { WsResponse } from '../webSockets/WebSocket.types';

export function* fetchGarageInfoRequest({ payload }: ReturnType<typeof actions.fetchGarageInfoRequest>): SagaIterator {
  const { garageId } = payload;
  if (!garageId) {
    return;
  }

  yield put(setGarageNoDataStatus({ garageId, status: LOADING }));
  yield put(sendGetGarageInfo({ garageId }));
}

export function* fetchGarageInfoResponse(action: WsResponse<GetGarageInfo>): SagaIterator {
  const { payload } = action;
  yield put(setGarage({ garageId: payload.garageId, garage: payload.garageInfo }));
}

export function* fetchChangeGarageEmailRequest({
  payload,
}: ReturnType<typeof actions.fetchChangeGarageEmailRequest>): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile?.buyerId) return;
  yield put(setGarageEmail({ garageId: tradingProfile.buyerId, email: payload }));
  yield put(sendChangeGarageEmail({ garageId: tradingProfile.buyerId, email: payload }));
}

export function* GarageSagas(): SagaIterator {
  yield takeLatest(actions.fetchGarageInfoRequest.type, sagaGuard(fetchGarageInfoRequest));
  yield takeLatest(actions.fetchGarageInfoResponse.type, sagaGuard(fetchGarageInfoResponse));
  yield takeLatest(actions.fetchChangeGarageEmailRequest.type, sagaGuard(fetchChangeGarageEmailRequest));
}
