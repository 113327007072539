import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// eslint-disable-next-line max-len
import { UniversalProductsCategory } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsTreeResponse';
import { ROUTER_UNIVERSAL_PRODUCTS } from 'app/AppRouter';
import Filter from 'components/Filter';
import { getFiltersMap, RangeFacetLocal, TextFacetLocal } from 'components/Filter/Filter.types';
import { useFetchUniversalProducts, useReloadUniversalProducts } from 'domains/catalog/Catalog.requests';
import {
  getAllUniversalProducts,
  getUniversalProductsFilter,
  getUniversalProductsReferencesLevel3,
  getUniversalProductsSelectionL1,
  resetUniversalProductsFilters,
  setUniversalProductSelection,
  setUniversalProductsRangeFilter,
  setUniversalProductsTextFilter,
} from 'domains/catalog/Catalog.store';
import { UniversalProductsSelection } from 'domains/catalog/Catalog.types';
import { Accordion, CenteredEmpty, Flex, MarginBox, Panel, Pipeline, Radio, RadioGroup, Space, Text } from 'UI';
import { getData, hasData, isLoading, LOADING, NO_DATA } from 'utils';
import { SContainer } from './Category.styled';

const CategoryMenu = ({
  universalProductsTreeLevel2,
  level1sel,
}: {
  universalProductsTreeLevel2: UniversalProductsCategory[] | NO_DATA;
  level1sel: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { level1: level1url, level2: level2url, level3: level3url } = useParams<{
    level1: string;
    level2: string;
    level3: string;
  }>();
  const [level1, setLevel1] = useState<string | undefined>(level1url || level1sel);
  const [level2, setLevel2] = useState<string | undefined>(level2url);
  const [level3, setLevel3] = useState<string | undefined>(level3url);
  const [lastLevel3, setLastLevel3] = useState<string | undefined>(level3url);
  const selectionL1 = useSelector(getUniversalProductsSelectionL1);
  const toBeFetchedReferences = useSelector(getUniversalProductsReferencesLevel3);
  const allUniversalProducts = useSelector(getAllUniversalProducts);

  useEffect(() => {
    if (level3) {
      setLastLevel3(level3);
    }
  }, [level3]);

  useEffect(() => {
    setLevel1(level1url || level1sel);
  }, [level1url, level1sel]);

  const firtsBatchOfReferences = isLoading(toBeFetchedReferences)
    ? LOADING
    : toBeFetchedReferences?.slice(0, 15).filter((ref) => !allUniversalProducts.get(ref)) ?? [];

  useFetchUniversalProducts(firtsBatchOfReferences);
  useReloadUniversalProducts(level1, level2, level3);

  useEffect(() => {
    if (!selectionL1 || selectionL1 === LOADING) {
      return;
    }
    setLevel2(level2url);
    setLevel3(level3url);
  }, [selectionL1, level1url, level2url, level3url]);

  const onChangeLevel3 = (nodeId: string) => {
    setLevel3(nodeId);
    history.push(`${ROUTER_UNIVERSAL_PRODUCTS}/${level1}/${level2}/${nodeId}`);
    const selection: UniversalProductsSelection = { level1, level2, level3: nodeId };
    dispatch(setUniversalProductSelection(selection)); //TODO remove, use from url
  };

  const onChangeLevel2 = (level2NodeId: any) => {
    const level3s = getData(universalProductsTreeLevel2)?.find((item) => item.nodeId === level2NodeId)?.childCategories;
    const hasAnyLevel3 = level3s && level3s.length > 0;
    if (level2NodeId) {
      setLevel2(level2NodeId);
    } else {
      history.push(`${ROUTER_UNIVERSAL_PRODUCTS}/${level1}`);
    }

    if (isLevel3CountOnlyOne(level2NodeId)) {
      const firstItem = getOnlyOneInLevel3(level2NodeId);
      setLevel3(firstItem);
      history.push(`${ROUTER_UNIVERSAL_PRODUCTS}/${level1}/${level2NodeId}/${firstItem}`);
      const selection: UniversalProductsSelection = { level1, level2: level2NodeId, level3: firstItem };
      dispatch(setUniversalProductSelection(selection)); //TODO remove, use from url
    } else {
      setLevel3(undefined);
      if (level2NodeId && hasAnyLevel3) {
        history.push(`${ROUTER_UNIVERSAL_PRODUCTS}/${level1}/${level2NodeId}`);
      }
    }
  };

  const isLevel3CountOnlyOne = (level2: string) => {
    if (!hasData(universalProductsTreeLevel2)) {
      return false;
    }
    const level2Obj = universalProductsTreeLevel2.find((item) => item.nodeId === level2);
    return level2Obj && level2Obj.childCategories && level2Obj.childCategories.length === 1;
  };

  const getOnlyOneInLevel3 = (level2: string) => {
    if (!hasData(universalProductsTreeLevel2)) {
      return undefined;
    }
    const level2Obj = universalProductsTreeLevel2.find((item) => item.nodeId === level2);
    if (!level2Obj) {
      return undefined;
    }
    return level2Obj.childCategories ? level2Obj.childCategories[0].nodeId : undefined;
  };
  return (
    <Flex direction={'column'}>
      <MarginBox ml={20} mb={20}>
        <Text type={'h5_bold'}>{t('catalog.universal_products.select_categories', 'Select category')}</Text>
      </MarginBox>
      <Pipeline size={'100%'} horizontal={true} color={'rgba(151,151,151,0.25)'} />
      <Accordion onChange={onChangeLevel2} activeKey={level2}>
        {hasData(universalProductsTreeLevel2) &&
          universalProductsTreeLevel2.map((level2Category) => {
            const isEmptyCategory = (level2Category.childCategories ?? []).length === 0;
            return (
              <Panel
                showArrow={false}
                header={
                  <MarginBox ml={10} mb={10} key={level2Category.nodeId}>
                    <Text type={'light_14_black_85'} cursor={'pointer'}>
                      {level2Category.name}
                    </Text>
                  </MarginBox>
                }
                key={level2Category.nodeId}
              >
                {isEmptyCategory ? (
                  <>
                    <CenteredEmpty />
                  </>
                ) : (
                  <RadioGroup key={level2Category.nodeId} value={lastLevel3}>
                    <Space direction={'vertical'}>
                      {hasData(level2Category.childCategories) &&
                        level2Category.childCategories?.map((level3Category) => {
                          return (
                            <Radio
                              key={level3Category.nodeId}
                              value={level3Category.nodeId}
                              onChange={() => onChangeLevel3(level3Category.nodeId)}
                              label={
                                <Text type={'light_14_black_65'} cursor={'pointer'}>
                                  {level3Category.name}
                                </Text>
                              }
                            />
                          );
                        })}
                    </Space>
                  </RadioGroup>
                )}
              </Panel>
            );
          })}
      </Accordion>
    </Flex>
  );
};

export const Category = ({
  universalProductsTreeLevel2,
  level1sel,
  showPriceless,
  setShowPriceless,
  stockFilterOptions,
  pricesFacet,
  pricesRange,
}: {
  universalProductsTreeLevel2: UniversalProductsCategory[] | NO_DATA;
  level1sel: string;
  showPriceless: boolean;
  setShowPriceless: (b: boolean) => void;
  stockFilterOptions: TextFacetLocal;
  pricesFacet: RangeFacetLocal;
  pricesRange: [number, number] | undefined;
}) => {
  const filters = useSelector(getUniversalProductsFilter);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Flex direction={'column'}>
      <SContainer>
        <CategoryMenu level1sel={level1sel} universalProductsTreeLevel2={universalProductsTreeLevel2} />
      </SContainer>
      <Filter
        filters={filters}
        textFilters={[stockFilterOptions]}
        rangeFilters={pricesRange ? [pricesFacet] : []}
        setTextFilters={(id, item) => dispatch(setUniversalProductsTextFilter({ id, item }))}
        setRangeFilters={(id, range) => dispatch(setUniversalProductsRangeFilter({ id, range }))}
        resetFilters={() => dispatch(resetUniversalProductsFilters())}
        loading={!pricesRange}
        facetsMap={getFiltersMap(t)}
        usePrice={{
          showPriceless,
          setShowPriceless,
        }}
      />
    </Flex>
  );
};
