import { TextFacetItem } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/response/GetIAMReferencesResponse';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { TFunction } from 'i18next';
import { Filters, isFilteredStocks, isInRange, RangeFacetLocal, TextFacetLocal } from 'components/Filter/Filter.types';
import { IAMReferenceLocal } from 'domains/references';
import { SearchData } from 'utils';

export const getUpdatedIAMTextFilters = (
  textFilters: TextFacetLocal[],
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  filtersMap: Map<string, { label: string; ids: string[] }>,
  filteredReferences: IAMReferenceLocal[],
  t: TFunction,
): TextFacetLocal[] => {
  return textFilters.map((tf) => {
    return {
      ...tf,
      items: tf.items.map((tfi) => {
        const resultItems = filteredReferences.filter((reference) => {
          const stock = stocks.get(reference.referenceNumber);
          const filterKeys = filtersMap.get(tf.id)?.ids ?? [];
          if (tf.id === 'supplierLabel') {
            return tfi.label === (reference?.supplier ?? '');
          }
          if (tf.id === 'delivery') {
            const tfiTextFilter = new Map();
            tfiTextFilter.set('delivery', [tfi.label]);
            return isFilteredStocks(stock, { textFilters: tfiTextFilter, rangeFilters: new Map() }, t);
          }
          const relevantAdditionalInfo = (reference.additionalInformation ?? [])
            .filter((i) => filterKeys.includes(i.id))
            .map((i) => i.description);
          return relevantAdditionalInfo.includes(tfi.label);
        });
        return { ...tfi, numberOfItems: resultItems.length };
      }),
    };
  });
};

export const getUpdatedIamRangeFilters = (
  rangeFilters: RangeFacetLocal[],
  filtersMap: Map<string, { label: string; ids: string[] }>,
  filteredReferences: IAMReferenceLocal[],
): RangeFacetLocal[] => {
  return rangeFilters.map((rf) => {
    const filterKeys = filtersMap.get(rf.id)?.ids ?? [];
    const rangeValues = filteredReferences
      .map((reference) => {
        return (reference.additionalInformation ?? [])
          .filter((i) => filterKeys.includes(i.id))
          .map((i) => i.description)
          .map((i) => {
            const num = i?.match(/(-?[\d,]+)([a-z%]*)/);
            if (num) {
              return Number(num[1].replace(',', '.'));
            }
            return Number(i ?? 0);
          });
      })
      .flat();
    return {
      ...rf,
      numberOfItems: rangeValues.length,
    };
  });
};

export const getIamReferencesByFilters = (
  allReferences: IAMReferenceLocal[],
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  filter: Filters,
  filtersMap: Map<string, { label: string; ids: string[] }>,
  t: TFunction,
): IAMReferenceLocal[] => {
  return allReferences.filter((reference) => {
    const stock = stocks.get(reference.referenceNumber);
    return (
      ![...filter.textFilters.keys()].some((facetId) => {
        const filterItems = filter.textFilters.get(facetId) ?? [];
        const filterKeys = filtersMap.get(facetId)?.ids ?? [];
        if (facetId === 'supplierLabel') {
          return !filterItems.includes(reference?.supplier ?? '');
        }
        if (facetId === 'delivery') {
          return !isFilteredStocks(stock, filter, t);
        }
        const relevantAdditionalInfo = (reference.additionalInformation ?? [])
          .filter((i) => filterKeys.includes(i.id))
          .map((i) => i.description);
        return !filterItems.some((i) => relevantAdditionalInfo.includes(i));
      }) &&
      ![...filter.rangeFilters.keys()].some((facetId) => {
        const filterItems = filter.rangeFilters.get(facetId);
        const filterKeys = filtersMap.get(facetId)?.ids ?? [];
        const relevantAdditionalInfo = (reference.additionalInformation ?? [])
          .filter((i) => filterKeys.includes(i.id))
          .map((i) => i.description);
        return isInRange(relevantAdditionalInfo, filterItems);
      })
    );
  });
};

export const getIamDeliveryFilterOptions = (
  stocksFilterOptions: TextFacetLocal,
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  filteredReferences: IAMReferenceLocal[],
  t: TFunction,
): TextFacetLocal => {
  const getItems: TextFacetItem[] = stocksFilterOptions.items.filter((soi) => {
    return [...stocks.values()].some((s) => {
      const soiTextFilter = new Map();
      soiTextFilter.set('delivery', [soi.label]);
      return isFilteredStocks(s, { textFilters: soiTextFilter, rangeFilters: new Map() }, t);
    });
  });
  const newItemsMap = new Map<string, TextFacetItem>();
  getItems.forEach((tfi) => {
    const resultItems = filteredReferences.filter((reference) => {
      const stock = stocks.get(reference.referenceNumber);
      const tfiTextFilter: Map<string, string[]> = new Map();
      tfiTextFilter.set('delivery', [tfi.label]);
      return isFilteredStocks(stock, { textFilters: tfiTextFilter, rangeFilters: new Map() }, t);
    });
    newItemsMap.set(tfi.label, { label: tfi.label, numberOfItems: resultItems.length });
  });

  return {
    id: stocksFilterOptions.id,
    active: stocksFilterOptions.active,
    type: stocksFilterOptions.type,
    items: Array.from([...newItemsMap], ([label, value]) => ({
      numberOfItems: value.numberOfItems,
      label,
    })),
  };
};
