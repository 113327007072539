import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { BreakpointKeyType, BreakpointSelectableFull, useBreakpointSelectorFull } from 'utils/hooks/useBreakpoint';

const SContainerOuter = styled.div<
  {
    padding: string;
    minW?: number;
    maxW?: number;
    alignText?: AlignTextType;
    height?: string | number;
    justify?: JustifyFlexType;
  } & BackgroundStyles
>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => (justify ? justify : 'center')};
  text-align: ${({ alignText }) => alignText};
  min-width: ${({ minW }) => (minW ? `${minW}px` : 0)};
  max-width: ${({ maxW }) => `${maxW}px`};
  width: 100%;
  height: ${({ height }) => (!height ? 'fit-content' : typeof height === 'number' ? `${height}px` : height)};
  padding: ${({ padding }) => padding};
  margin: 0 auto 0 auto;

  background-color: ${({ bgColor }) => bgColor};
  background-image: ${({ bgImage }) => bgImage};
  background-repeat: no-repeat;
  background-position: ${({ bgPosition }) => bgPosition};
  background-size: cover;
`;

type AlignTextType = 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
type JustifyFlexType = 'center' | 'left' | 'right' | 'inherit' | 'space-between' | 'flex-start' | 'flex-end';

export interface WithChildren {
  children?: ReactNode;
}

export interface BackgroundStyles {
  bgImage?: string;
  bgColor?: string;
  bgPosition?: string;
}

export type AdaptiveSize = 'adaptive';

export interface ContainerProps extends BackgroundStyles, PropsWithChildren {
  padding?: BreakpointSelectableFull<string>;
  size?: BreakpointKeyType | AdaptiveSize | number;
  minSize?: BreakpointKeyType | number;
  alignText?: AlignTextType;
  height?: number | string;
  justify?: JustifyFlexType;
}

const sizes = { xs: 0, sm: 360, md: 766, lg: 1024, xl: 1440, xxl: 1920 };

const emptyPaddingLocal = '0 0 0 0';
const basicPaddingLocal = '15px 15px 15px 15px';
const indentPaddingLocal = '0 15px 0 15px';
const midWidePaddingLocal = '50px 48px 15px 50px';

export const emptyPadding = {
  xs: emptyPaddingLocal,
  sm: emptyPaddingLocal,
  md: emptyPaddingLocal,
  lg: emptyPaddingLocal,
  xl: emptyPaddingLocal,
  xxl: emptyPaddingLocal,
};

export const defaultPadding = {
  xs: basicPaddingLocal,
  sm: basicPaddingLocal,
  md: basicPaddingLocal,
  lg: basicPaddingLocal,
  xl: basicPaddingLocal,
  xxl: basicPaddingLocal,
};

export const indentPadding = {
  xs: indentPaddingLocal,
  sm: indentPaddingLocal,
  md: indentPaddingLocal,
  lg: indentPaddingLocal,
  xl: indentPaddingLocal,
  xxl: indentPaddingLocal,
};

export const assistancePadding = {
  xs: '15px 15px 15px 15px',
  sm: '15px 15px 15px 15px',
  md: '30px 30px 30px 30px',
  lg: '60px 140px 60px 140px',
  xl: '60px 140px 60px 140px',
  xxl: '60px 140px 60px 140px',
};
export const myStorePadding = {
  xs: midWidePaddingLocal,
  sm: midWidePaddingLocal,
  md: midWidePaddingLocal,
  lg: midWidePaddingLocal,
  xl: midWidePaddingLocal,
  xxl: midWidePaddingLocal,
};

export const Container = ({
  children,
  padding,
  size,
  minSize,
  bgImage,
  bgColor,
  bgPosition,
  alignText,
  height,
  justify,
}: ContainerProps) => {
  const bs = useBreakpointSelectorFull();
  const fixedSizes = bs(sizes);
  const isAdaptive = size === 'adaptive';
  const fixedSize = size && isAdaptive ? fixedSizes : undefined;
  const selectedSize = size && size !== 'adaptive' && typeof size !== 'number' ? sizes[size] : undefined;
  const customSize = size && size !== 'adaptive' && typeof size === 'number' ? size : undefined;
  const maxW = selectedSize || fixedSize || customSize;
  const minW = typeof minSize === 'string' ? sizes[minSize] : minSize;
  return (
    <SContainerOuter
      padding={bs(padding) ?? basicPaddingLocal}
      minW={minW}
      maxW={maxW}
      bgImage={bgImage}
      bgColor={bgColor}
      bgPosition={bgPosition}
      alignText={alignText}
      height={height}
      justify={justify}
    >
      {children}
    </SContainerOuter>
  );
};
