import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextFilterItem } from 'components/Filter/Filter.types';
import FilterQuantity from 'components/Filter/FilterQuantity';
import FilterTags from 'components/Filter/FilterTags';
import { useFetchTireBrandCategories } from 'domains/tires/Tire.requests';
import { getTireCatalogFilters, setTireRangeFilter, setTireTextFilter } from 'domains/tires/Tire.store';
import { getSparePartsView } from 'domains/user';
import { TireReferencesContainer } from 'pages/TiresPage/TireReferencesSection/TireReferencesContainer/TireReferencesContainer';
import { Flex, MarginBox } from 'UI';
import ViewTabs from 'UI/Tabs/ViewTabs';

interface TireReferencesSectionProps {
  searchParamsBase64: string;
  initialTextFilters?: TextFilterItem[];
}

const TireReferencesSection = ({ searchParamsBase64, initialTextFilters }: TireReferencesSectionProps) => {
  const dispatch = useDispatch();
  const [numOfProducts, setNumOfProducts] = useState<number>(0);
  const filters = useSelector(getTireCatalogFilters);
  const sparePartsView = useSelector(getSparePartsView);

  useFetchTireBrandCategories();

  return (
    <Flex direction={'column'}>
      <FilterTags
        filters={filters}
        setTextFilters={(id, item) => dispatch(setTireTextFilter({ id, item }))}
        setRangeFilters={(id, range) => dispatch(setTireRangeFilter({ id, range }))}
      />
      <MarginBox my={15} />
      <MarginBox mb={-30}>
        <FilterQuantity numberOfProducts={numOfProducts} />
      </MarginBox>
      <ViewTabs />
      <TireReferencesContainer
        searchParamsBase64={searchParamsBase64}
        sparePartsView={sparePartsView}
        setNumOfProducts={setNumOfProducts}
        initialTextFilters={initialTextFilters}
      />
    </Flex>
  );
};

export default TireReferencesSection;
