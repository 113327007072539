import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ROUTER_HOME, UNIVERSAL_PRODUCTS_LINK } from 'app/AppRouter';
import { DataContainer } from 'components/DataContainer';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { useFetchUniversalProductsTree } from 'domains/catalog/Catalog.requests';
import { getUniversalProductsTree } from 'domains/catalog/Catalog.store';
import { Flex, MarginBox, Text } from 'UI';
import { getData } from 'utils';
import { SButtonTabs } from './HomePageCategories.styled';

const HomePageCategories = () => {
  const { t } = useTranslation();
  const universalProductsTree = useSelector(getUniversalProductsTree);
  useFetchUniversalProductsTree(universalProductsTree);

  return (
    <DataContainer data={universalProductsTree} noDataMarginY={20} NotFound={() => <></>} ErrorState={() => <></>}>
      <Flex direction={'column'}>
        <Text type={'h2'}>{t('common.link.browse_by_category', 'Browse by Category')}</Text>
        <FirstHelpPopin streamId={ROUTER_HOME} popinId={`${ROUTER_HOME}_universal_products`} placement={'top'}>
          <Flex justify={'space-evenly'}>
            <SButtonTabs
              items={getData(universalProductsTree)?.map((item, i) => {
                return { label: item.name, link: `${UNIVERSAL_PRODUCTS_LINK}/${item.nodeId}`, key: i };
              })}
              variant={'greyOutlined'}
              shape={'default'}
              textType={'text_bold'}
            />
          </Flex>
        </FirstHelpPopin>
        <MarginBox mt={10} />
      </Flex>
    </DataContainer>
  );
};

export default HomePageCategories;
