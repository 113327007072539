import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { ImageSourceType, imageSrcSwitch } from 'UI/Image';
import { BreakpointSelectable, useBreakpointSelector } from 'utils/hooks/useBreakpoint';

export type BoxAlignType = 'left' | 'right' | 'center';

const SBox = styled.div<BoxProps>`
  display: block;
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  background-color: ${({ background }) => background};
  align-items: ${({ align }) => align};
  text-align: ${({ align }) => align};
  justify-content: center;
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url('${backgroundImage}')` : undefined)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export interface BoxProps {
  children?: React.ReactNode;
  height?: BreakpointSelectable<number | string>;
  maxHeight?: BreakpointSelectable<number | string>;
  width?: BreakpointSelectable<number | string>;
  maxWidth?: BreakpointSelectable<number | string>;
  backgroundImage?: string;
  backgroundImageType?: ImageSourceType;
  background?: string;
  align?: BoxAlignType;
  className?: string;
  onClick?: () => void;
}

// eslint-disable-next-line react/display-name
export const Box = forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      className,
      children,
      height,
      maxHeight,
      width,
      maxWidth,
      backgroundImage,
      backgroundImageType,
      background,
      align,
      onClick,
    }: BoxProps,
    ref,
  ) => {
    const breakpointSelector = useBreakpointSelector();

    return (
      <SBox
        height={breakpointSelector(height)}
        maxHeight={breakpointSelector(maxHeight)}
        width={breakpointSelector(width)}
        maxWidth={breakpointSelector(maxWidth)}
        backgroundImage={backgroundImageType && imageSrcSwitch(backgroundImageType, backgroundImage)}
        background={background}
        align={align}
        className={className}
        ref={ref}
        onClick={onClick}
      >
        {children}
      </SBox>
    );
  },
);
