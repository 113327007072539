/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/Reference';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { VehicleBrandType, VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { DealerType } from '@1po/1po-bff-fe-spec/generated/user/response/ConnectAsUserProfileResponse';
import { ROUTER_CATALOG_DH_L3 } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { getLastVehicle, getPlateReferences } from 'domains/catalog/Catalog.store';
import { MarketingReferencesWrapper, PlateReferencesDetail } from 'domains/catalog/Catalog.types';
import { getFirstGenericPartWithLaborTimes } from 'domains/laborTime/LaborTime.store';
import {
  DHReferenceLocal,
  getDHReference,
  getDHReferences,
  getPricesMap,
  getStocksMap,
  ReferencePriceType,
  SORTING_MATRIX,
} from 'domains/references';

import {
  useFetchCrossSellingReferences,
  useFetchPrices,
  useFetchReferencesStocks,
} from 'domains/references/References.requests';
import { getCatalogReferenceView, getDealerType, getTokenUser, SparePartsViewType, StandardView } from 'domains/user';
import { IndexHeader } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/IndexHeader/IndexHeader';
import PlateReferenceCard from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard';
import { HighlightWrapper } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCardsContainer.styled';
import { SelectedIndexType } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/SparePartsSection';
import { theme } from 'styles';
import { MarginBox, sentenceCase, Text } from 'UI';
import {
  FOUND,
  getArrayWithoutUndefinedMembers,
  getData,
  hasData,
  isLoading,
  isNotFound,
  LOADING,
  NO_DATA,
  SEARCH_STATUS,
  SearchData,
} from 'utils';
import { OFFSET_LINK_TABS, useOffsetTop } from 'utils/hooks/useOffsetTop';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';
import { Country } from 'utils/i18n/Country';
import { IndexType } from 'utils/svg/common';

export interface PlateReferencesHeaderProps {
  detail: PlateReferencesDetail;
  vehicleKey: string | undefined;
  isSelected?: boolean;
  setSelectedIndex: (value: ((prevState: SelectedIndexType) => SelectedIndexType) | SelectedIndexType) => void;
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void;
  refIndex: number;
}

const PlateReferencesHeader = ({
  detail,
  vehicleKey,
  isSelected,
  setSelectedIndex,
  setHighlightedIndex,
  refIndex,
}: PlateReferencesHeaderProps) => {
  const offsetTop = useOffsetTop('RENAULT_CATALOG');
  const handleScrollHighlight = (indexKey: string) => {
    setSelectedIndex(indexKey);
    setHighlightedIndex(indexKey);
    scrollToTopSmooth(
      (document.getElementById('subcategory_section_container')?.offsetTop ?? 0) - offsetTop - OFFSET_LINK_TABS,
    );
  };

  const reference = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber: detail.referenceNumber, vehicleKey }),
  );

  return (
    <FirstHelpPopin
      streamId={ROUTER_CATALOG_DH_L3}
      popinId={`${ROUTER_CATALOG_DH_L3}_plate_index`}
      placement={'top'}
      skip={refIndex !== 0}
    >
      <IndexHeader id={`plate-reference-index-${detail.index}`} isSelected={isSelected} data-cy={'bar-reference-index'}>
        <Text
          type={isSelected ? 'h6_white' : 'h6'}
          cursor={'pointer'}
          disableGutter
          onClick={() => handleScrollHighlight(detail.index.toString())}
        >
          {`${detail.index}. ${sentenceCase(getData(reference)?.name ?? '')}`}
        </Text>
      </IndexHeader>
    </FirstHelpPopin>
  );
};

export interface PlateReferencesContainerProps {
  detail: PlateReferencesDetail;
  vehicleBrand: VehicleBrandType;
  sparePartsView: SparePartsViewType;
  marketingReferencesWrapper?: MarketingReferencesWrapper;
  isSelected?: boolean;
  setSelectedIndex: (value: ((prevState: SelectedIndexType) => SelectedIndexType) | SelectedIndexType) => void;
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void;
  plateId?: string;
  firstRefWithPrice: string | undefined;
  firstRefWithPriceNotInCart: string | undefined;
  refIndex: number;
  index?: number;
}

const getMainReferenceToDisplay = (
  mainReferenceNumber: string,
  oldReferenceNumber: string | undefined,
  prices: Map<string, NO_DATA | ReferencePriceType>,
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
) => {
  if (oldReferenceNumber === undefined) {
    return mainReferenceNumber;
  }

  const mainPrice = getData(prices.get(mainReferenceNumber));
  const mainStock = stocks.get(mainReferenceNumber)?.data;
  const oldPrice = getData(prices.get(oldReferenceNumber));
  const oldStock = stocks.get(oldReferenceNumber)?.data;

  // if main reference has price and r1 stock, display main reference
  if ((mainPrice !== undefined && mainStock?.warehouses?.some((w) => w.type === 'LOCAL')) ?? false) {
    return mainReferenceNumber;
  }

  // if old reference has price and central stock, display old reference
  if ((oldPrice !== undefined && oldStock?.warehouses?.some((w) => w.type === 'CENTRAL')) ?? false) {
    return oldReferenceNumber;
  }

  // otherwise return main reference
  return mainReferenceNumber;
};

const RENAULT_AGE_THRESHOLD = 4;
const DACIA_AGE_THRESHOLD = 3;
export const getFilteredAndSortedReferences = (
  references: DHReferenceLocal[],
  dealerType: DealerType,
  vehicle: VehicleDetail,
  prices: Map<string, NO_DATA | ReferencePriceType>,
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
) => {
  const ageThreshold = vehicle.vehicleBrand === 'RENAULT' ? RENAULT_AGE_THRESHOLD : DACIA_AGE_THRESHOLD;
  const vehicleAge = vehicle.dataHubVehicle?.vehicleAge ?? 99;
  const age = vehicleAge < ageThreshold ? 'NEW' : 'OLD';
  const dealerMap = SORTING_MATRIX.get(age);
  const brandArray = dealerMap?.get(dealerType);

  const refsWithPrice = references.reduce((acc, ref) => (hasData(prices.get(ref.referenceNumber)) ? acc + 1 : acc), 0);

  const filteredReferences = references.filter((ref) => {
    if (
      refsWithPrice <= 1 &&
      (ref.brand !== 'MOTRIO' ||
        stocks.get(ref.referenceNumber)?.searchStatus === LOADING ||
        isLoading(prices.get(ref.referenceNumber)))
    ) {
      return true;
    }
    const refStock = stocks.get(ref.referenceNumber);
    const refPrice = prices.get(ref.referenceNumber);
    return (isLoading(refStock?.searchStatus) || hasData(refStock?.searchStatus)) && hasData(refPrice);
  });

  if (!brandArray) {
    return filteredReferences;
  }

  const refNumbersWithoutPrices = filteredReferences
    .filter((ref) => isNotFound(prices.get(ref.referenceNumber)))
    .map((ref) => ref.referenceNumber);

  const brandToReferenceNumbers = filteredReferences.reduce((acc, next) => {
    const brand = next.brand ?? 'OTHER';
    acc.set(brand, [...(acc.get(brand) ?? []), next]);
    return acc;
  }, new Map<ReferenceBrandType, DHReferenceLocal[]>());

  const filteredReferenceMap = Array.from(brandToReferenceNumbers.entries()).reduce(
    (acc, [_brand, brandReferences]) => {
      // ONEPORTAL-3376 - if we have at least one reference from given brand with price, hide all without prices
      const referencesWithPrices = brandReferences.filter(
        (ref) => !refNumbersWithoutPrices.includes(ref.referenceNumber),
      );
      const referencesToDisplay = referencesWithPrices.length > 0 ? referencesWithPrices : filteredReferences;
      referencesToDisplay.forEach((ref) => acc.set(ref.referenceNumber, ref));
      return acc;
    },
    new Map<string, DHReferenceLocal>(),
  );

  return Array.from(filteredReferenceMap.values())
    .filter((ref) => ref.brand && (brandArray.includes(ref.brand) || ref.brand === 'OTHER'))
    .sort((ref1, ref2) => {
      const ref1Index = brandArray.indexOf(ref1.brand === 'OTHER' ? 'CENTRAL' : ref1.brand ?? 'CENTRAL');
      const ref2Index = brandArray.indexOf(ref2.brand === 'OTHER' ? 'CENTRAL' : ref2.brand ?? 'CENTRAL');
      return ref1Index > -1 && ref1Index < ref2Index ? -1 : 1;
    });
};

const PlateReferencesContainer = ({
  detail,
  isSelected = false,
  setSelectedIndex,
  setHighlightedIndex,
  plateId,
  sparePartsView,
  vehicleBrand,
  firstRefWithPrice,
  firstRefWithPriceNotInCart,
  refIndex,
  index,
}: PlateReferencesContainerProps) => {
  const vehicle = useSelector(getLastVehicle);
  const vehicleKey = vehicle?.vehicleDetail?.vehicleKey;
  const dealerType = useSelector(getDealerType);
  const referencePlateView = useSelector(getCatalogReferenceView);
  const tokenUserCountry = useSelector(getTokenUser)?.country;
  const mainReference = useSelector((state: RootState) =>
    getDHReference(state, { vehicleKey, referenceNumber: detail.referenceNumber }),
  );
  const mainReferenceData = getData(mainReference);
  const initialReferenceNumber = mainReferenceData?.initialReferenceNumber;
  const initialReference = useSelector((state: RootState) =>
    getDHReference(state, { vehicleKey, referenceNumber: initialReferenceNumber }),
  );
  const initialReferenceData = getData(initialReference);

  const supersessionReferenceNumbers = initialReferenceNumber
    ? [initialReferenceNumber, detail.referenceNumber]
    : [detail.referenceNumber];
  const mainAndInitialReferencesPrices = useSelector((state: RootState) =>
    getPricesMap(state, supersessionReferenceNumbers),
  );
  const mainAndInitialReferencesStocks = useSelector((state: RootState) =>
    getStocksMap(state, supersessionReferenceNumbers),
  );

  const mainReferenceNumberToDisplay = getMainReferenceToDisplay(
    detail.referenceNumber,
    initialReferenceData?.referenceNumber,
    mainAndInitialReferencesPrices,
    mainAndInitialReferencesStocks,
  );

  const referenceNumbers = [mainReferenceNumberToDisplay, ...(detail.alternativeReferenceNumbers ?? [])];
  const plateReferences = useSelector((state: RootState) => getPlateReferences(state, { plateId, vehicleKey }));
  const plateReferencesData = getData(plateReferences)?.filter((p) => p.index === index);
  //replace linkedReferences from plate references if reference exists on plate index
  const references = useSelector((state: RootState) => getDHReferences(state, { vehicleKey, referenceNumbers })).map(
    (r) => {
      const plateReference = plateReferencesData?.find((p) => p.referenceNumber === r.referenceNumber);
      return {
        ...r,
        linkedReferences: plateReference !== undefined ? plateReference.linkedReferences : r.linkedReferences,
      };
    },
  );

  const prices = useSelector((state: RootState) => getPricesMap(state, referenceNumbers));
  const stocks = useSelector((state: RootState) => getStocksMap(state, referenceNumbers));
  const motrioReferenceNumbers = references.filter((r) => r.brand === 'MOTRIO').map((r) => r.referenceNumber);
  const [isMotrioStockPriceLoaded, setIsMotrioStockPriceLoaded] = useState<boolean>(false);
  const isAnyMotrioStockPriceLoading = motrioReferenceNumbers.some((ref) => {
    const motrioStock = stocks.get(ref)?.searchStatus;
    const motrioPrice = prices.get(ref);
    return isLoading(motrioStock) || isLoading(motrioPrice);
  });
  const genericParts = getArrayWithoutUndefinedMembers(references.map((r) => r.genericPart));

  const firstGenericPartWithLaborTimes = useSelector((state: RootState) =>
    getFirstGenericPartWithLaborTimes(state, {
      vehicleKey,
      genericParts,
    }),
  );

  const firstRefWithLaborTimes = firstGenericPartWithLaborTimes
    ? references.find((r) => r.genericPart === firstGenericPartWithLaborTimes)?.referenceNumber
    : undefined;

  useFetchCrossSellingReferences([...(detail.alternativeReferenceNumbers ?? [])], vehicleKey);
  useFetchPrices(motrioReferenceNumbers, vehicleKey);
  useFetchReferencesStocks(motrioReferenceNumbers);

  useEffect(() => {
    if (!isMotrioStockPriceLoaded && !isAnyMotrioStockPriceLoading) {
      setIsMotrioStockPriceLoaded(true);
    }
  }, [isMotrioStockPriceLoaded, isAnyMotrioStockPriceLoading]);

  const referenceStatus: SEARCH_STATUS = useMemo(() => {
    const loadingRefs = references.some((ref) => isLoading(ref.detailStatus));
    return isMotrioStockPriceLoaded && !loadingRefs ? FOUND : LOADING;
  }, [references, isMotrioStockPriceLoaded]);

  const getReferencesByCountry = () =>
    tokenUserCountry === Country.RO.key ? references.filter((r) => r.brand !== 'VALUE_PLUS') : references;

  const referencesToDisplay =
    hasData(vehicle) && hasData(vehicle?.vehicleDetail)
      ? getFilteredAndSortedReferences(
          getReferencesByCountry(),
          dealerType ?? 'R2',
          vehicle.vehicleDetail,
          prices,
          stocks,
        )
      : references;

  return (
    <MarginBox mt={15} mb={referencePlateView === StandardView ? 30 : 0}>
      <PlateReferencesHeader
        detail={detail}
        vehicleKey={vehicleKey}
        setHighlightedIndex={setHighlightedIndex}
        setSelectedIndex={setSelectedIndex}
        isSelected={isSelected}
        refIndex={refIndex}
      />
      <HighlightWrapper isSelected={isSelected} background={theme.color.white}>
        <MarginBox mx={15} my={15}>
          <DataContainer data={referenceStatus}>
            {referencesToDisplay.map((ref, i) => (
              <PlateReferenceCard
                key={`plate-ref-${ref.referenceNumber}`}
                reference={ref}
                vehicleBrand={vehicleBrand}
                sparePartsView={sparePartsView}
                displayAdditionalInfo={referencesToDisplay.length > 1}
                plateId={plateId}
                firstRefWithPrice={firstRefWithPrice}
                firstRefWithLaborTimes={firstRefWithLaborTimes}
                firstRefWithPriceNotInCart={firstRefWithPriceNotInCart}
                refIndex={refIndex + i}
                displaySupersessionTooltip={
                  initialReferenceNumber !== undefined && supersessionReferenceNumbers.includes(ref.referenceNumber)
                }
                index={index}
              />
            ))}
          </DataContainer>
        </MarginBox>
      </HighlightWrapper>
    </MarginBox>
  );
};

export default PlateReferencesContainer;
