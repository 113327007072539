import { ListItemProps } from 'antd/lib/list';
import styled from 'styled-components';
import { theme } from 'styles';
import { Flex, Select } from 'UI';

export const SearchButton = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  height: 32px;
  width: 32px;
  background-color: ${({ theme, disabled }) => (disabled ? theme.color.disabled : theme.color.brand)};
  border-radius: 4px;
  text-align: center;
  margin: 20px 0 0 20px;
  padding: 5px 0 5px 0;
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const SSelect: new <T>() => Select<T> = styled(Select)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
` as typeof Select;

export const SServiceCard = styled(Flex)<{ active: boolean; data?: boolean }>`
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);
  background-color: ${({ active }) => (active ? theme.color.info : theme.color.white)};
  padding: 0 0 0 0;

  :hover {
    cursor: ${({ data }) => (data ? 'initial' : 'pointer')};
  }
`;

export const RowDivider = styled(Flex)`
  width: 100%;
  padding: 15px 30px 15px 30px;
  border-bottom: ${({ theme }) => `${theme.color.grey95} 1px solid`};
`;

export const SListItem = styled.div<ListItemProps>`
  width: 360px;
  padding-left: 0px;
  margin-right: 15px;
  margin-top: 0px;

  ::before {
    content: '•';
    margin-right: 8px;
  }
`;
