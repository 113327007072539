import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FileUpload, FileUploadModal } from 'components/FileUpload';
import {
  API_CALL_ERROR,
  FILE,
  FILE_FORMAT_ERROR,
  FILE_STRUCTURE_ERROR,
  MAX_SIZE_ERROR,
  ModalStatusType,
  NO_FILE,
  UPLOADING_FILE,
} from 'components/FileUpload/paramsByState';
import { useFetchElectronicPartsRepair } from 'domains/electronicPartsRepair/ElectronicPartsRepair.requests';
import {
  getElectronicPartsRepairErrorRows,
  getElectronicPartsRepairFileIsModalOpen,
  getElectronicPartsRepairFileModalStatus,
  getElectronicPartsRepairFileUploadStatus,
  setElectronicPartsRepairFileInformationForRequest,
  setElectronicPartsRepairFileIsModalOpen,
  setElectronicPartsRepairFileModalStatus,
  uploadFileElectricPartsRepairSaga,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.store';
import {
  FETCHING_FILE,
  FILE_FETCHED,
  FILE_REPLACED,
  FILE_UPLOADED,
  REPLACING_FILE,
  SAME_FILE,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import FilePreview from 'pages/BackOfficePage/BackOfficeCategories/ElectronicPartsRepair/FilePreview/FilePreview';
// eslint-disable-next-line max-len
import { FileRowsErrors } from 'pages/BackOfficePage/BackOfficeCategories/ElectronicPartsRepair/FileRowsErrors/FileRowsErrors';
import { Box, CenteredSpin, Flex, MarginBox, Text } from 'UI';

const ElectronicPartsRepair = () => {
  const status = useSelector(getElectronicPartsRepairFileUploadStatus);
  const modalStatus = useSelector(getElectronicPartsRepairFileModalStatus);
  const modalIsOpen = useSelector(getElectronicPartsRepairFileIsModalOpen);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errorRows = useSelector(getElectronicPartsRepairErrorRows);

  useFetchElectronicPartsRepair();

  function validateFile(file: File) {
    if (!file.name.match('.*\\.csv$')) {
      dispatch(setElectronicPartsRepairFileModalStatus(FILE_FORMAT_ERROR));
      return false;
    } else if (file.size > 2000 * 1024) {
      dispatch(setElectronicPartsRepairFileModalStatus(MAX_SIZE_ERROR));
      return false;
    } else {
      dispatch(setElectronicPartsRepairFileModalStatus(FILE));
      return true;
    }
  }

  const uploadFile = (fileName: string, fileSize: number, fileBase64: string) => {
    dispatch(
      uploadFileElectricPartsRepairSaga({
        fileBase64,
      }),
    );
    dispatch(setElectronicPartsRepairFileModalStatus(UPLOADING_FILE));
    dispatch(
      setElectronicPartsRepairFileInformationForRequest({
        fileName,
        fileSize,
        uploadTime: undefined, // generated on backend, comes in BACKOFFICE_ELECTRONIC_PARTS_REPAIR_GET_FILE_RESPONSE
      }),
    );
  };

  const handleCancel = () => dispatch(setElectronicPartsRepairFileIsModalOpen(false));
  const handleNoFile = () => dispatch(setElectronicPartsRepairFileModalStatus(NO_FILE));

  const getDescriptions = () => {
    switch (modalStatus) {
      case FILE_FORMAT_ERROR: {
        return [t('common.file_upload.error.format', 'Only CSV file is supported, please try again')];
      }
      case MAX_SIZE_ERROR: {
        return [
          t(
            'common.file_upload.error.max_size_error_second_line',
            'Support extension: CSV-Maximum file size: {{size}} {{unit}}',
            { size: 0.5, unit: 'KB' },
          ),
        ];
      }
      case FILE_STRUCTURE_ERROR: {
        return [t('common.file_upload.error.wrong_format_structure', 'File has wrong structure')];
      }
      default: {
        return [t('common.file_upload.extension.csv', 'Supported extension: CSV')];
      }
    }
  };

  const descriptions = getDescriptions();

  const getText1Override = (modalStatusType: ModalStatusType) => {
    switch (modalStatusType) {
      case FILE_FORMAT_ERROR: {
        return t(
          'electronic_parts_repair.file_error.format_error',
          "Sorry, looks like you've uploaded a non-compatible file",
        );
      }
      case MAX_SIZE_ERROR: {
        return t('common.file_upload.image.max_size_error', 'File size exceeds 2 MB');
      }
      default:
        return '';
    }
  };

  const fileUpload = () => {
    return (
      <Flex direction={'column'} align={'center'}>
        <MarginBox mt={64}>
          <Text type={'h2'}>{t('electronic_parts_repair.paragraph.heading', 'Electronic reparable parts list')}</Text>
          <MarginBox mt={15} />
          <Box width={600}>
            <Text type={'text_dim'}>
              {t(
                'electronic_parts_repair.paragraph.text',
                'Drag and drop your CSV files here or browse your computer to upload electronics parts that are reparable.',
              )}
            </Text>
          </Box>
          <MarginBox mt={35} />
          <FileUpload
            template={{
              link: '/fileTemplates/electronic_parts_repair_template.csv',
              fileName: 'Electronic parts repair.csv',
            }}
            handleFileBase64={uploadFile}
            validateFile={validateFile}
            handleNoFile={handleNoFile}
            status={modalStatus}
            descriptions={descriptions}
            getText1Override={getText1Override}
          />
        </MarginBox>
        <MarginBox mt={35} />
      </Flex>
    );
  };

  const resolveComponent = () => {
    switch (status) {
      case FETCHING_FILE:
      case REPLACING_FILE:
        return (
          <MarginBox mt={64}>
            <CenteredSpin size={'large'} />
          </MarginBox>
        );
      case FILE_UPLOADED:
        if (errorRows.length > 0) {
          return <FileRowsErrors />;
        } else {
          return <FilePreview fileStatus={status} />;
        }
      case FILE_REPLACED:
      case FILE_FETCHED:
      case SAME_FILE:
        return <FilePreview fileStatus={status} />;
      case API_CALL_ERROR:
        return (
          <Text type={'text_dim_bold'}>
            {t('common.file_upload.drag_n_drop.api_call_error', 'No response from API')}
          </Text>
        );
      default:
        return fileUpload();
    }
  };

  return (
    <>
      {resolveComponent()}
      <FileUploadModal
        template={{
          link: '/fileTemplates/electronic_parts_repair_template.csv',
          fileName: 'Electronic parts repair.csv',
        }}
        handleFileBase64={uploadFile}
        validateFile={validateFile}
        handleNoFile={handleNoFile}
        handleCancel={handleCancel}
        isOpen={modalIsOpen}
        status={modalStatus}
        descriptions={descriptions}
        getText1Override={getText1Override}
      />
    </>
  );
};

export default ElectronicPartsRepair;
