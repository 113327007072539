import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// eslint-disable-next-line max-len
import { UniversalProductsCategory } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsTreeResponse';
import { ROUTER_UNIVERSAL_PRODUCTS } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import {
  FILTER_PRICES,
  getStockFilterOptions,
  RANGE_FILTER_TYPE,
  RangeFacetLocal,
} from 'components/Filter/Filter.types';
import { useFetchUniversalProductsTree } from 'domains/catalog/Catalog.requests';
import {
  getUniversalProductsReferencesLevel3,
  getUniversalProductsSelection,
  getUniversalProductsSelectionL1,
  getUniversalProductsTree,
  setUniversalProductSelection,
} from 'domains/catalog/Catalog.store';
import { UniversalProductsSelection } from 'domains/catalog/Catalog.types';
import { getLoadedPrices, getStocksMap } from 'domains/references';
import { GarageView, getSparePartsView } from 'domains/user';
import { AltTabs, Flex, MarginBox, Tab } from 'UI';
import { getData, hasData, isLoading, LOADING } from 'utils';
import { Category } from './Category';
import { StretchWidth, UniversalProductsContainer } from './UniversalProductsContainer';

export const Level1 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPriceless, setShowPriceless] = useState<boolean>(false);
  const { level1: level1url, level2: level2url, level3: level3url } = useParams<{
    level1: string;
    level2: string;
    level3: string;
  }>();
  const selectionL1 = useSelector(getUniversalProductsSelectionL1);

  const references = useSelector(getUniversalProductsReferencesLevel3);
  const selectedLevels = useSelector(getUniversalProductsSelection);
  const selectedL3 = getData(selectedLevels)?.level3;
  const storedReferences = getData(references);
  const sparePartsView = useSelector(getSparePartsView);
  const prices = useSelector((state: RootState) => getLoadedPrices(state, storedReferences ?? []));
  const somePriceMissing = prices.some((p) => isLoading(p.prices));
  const pricesRange: [number, number] | undefined = useMemo(() => {
    const pricesData = prices.map((p) => {
      const price = getData(p);
      return sparePartsView === GarageView
        ? Number(getData(price?.prices)?.garageView?.vatExcludedPrice ?? 0)
        : Number(getData(price?.prices)?.clientView?.recommendedPriceVatIncluded ?? 0);
    });
    const productPrices = pricesData.filter((p) => p > 0);
    return productPrices.length > 0 ? [Math.min(...productPrices), Math.max(...productPrices)] : undefined;
  }, [prices, sparePartsView]);
  const pricesFacetDefault: RangeFacetLocal = {
    id: FILTER_PRICES,
    type: RANGE_FILTER_TYPE,
    min: pricesRange ? pricesRange[0] : 0,
    max: pricesRange ? pricesRange[1] : 0,
    numberOfItems: storedReferences?.length ?? 0,
    active: true,
  };
  const stocks = useSelector((state: RootState) => getStocksMap(state, storedReferences ?? []));
  const stockStatuses = useMemo(() => [...stocks.values()].map((s) => s?.searchStatus), [stocks]);
  const someStockMissing = stockStatuses.some((sp) => isLoading(sp));
  const [stockFilterOptions, setStockFilterOptions] = useState(
    getStockFilterOptions('UNIVERSAL_PRODUCTS', !someStockMissing),
  );
  const [pricesFacet, setPricesFacet] = useState(pricesFacetDefault);
  const universalProductsTree = useSelector(getUniversalProductsTree);
  const getDefaultItem = () => {
    if (!hasData(universalProductsTree)) return undefined;
    return universalProductsTree[0].nodeId;
  };
  const [level1, setLevel1] = useState<string | undefined>(level1url || getDefaultItem());
  const [level2, setLevel2] = useState<string | undefined>(level2url);
  const [level3, setLevel3] = useState<string | undefined>(level3url);

  useFetchUniversalProductsTree(universalProductsTree);

  function setSelection(
    selection: UniversalProductsSelection,
    level2FirstCategory: UniversalProductsCategory | undefined,
    level3FirstCategory: UniversalProductsCategory | undefined,
  ) {
    dispatch(setUniversalProductSelection(selection));

    if (level2FirstCategory?.nodeId && level3FirstCategory?.nodeId) {
      history.push(
        `${ROUTER_UNIVERSAL_PRODUCTS}/${level1}/${level2FirstCategory?.nodeId}/${level3FirstCategory?.nodeId}`,
      );
      return;
    }

    if (level2FirstCategory?.nodeId) {
      history.push(`${ROUTER_UNIVERSAL_PRODUCTS}/${level1}/${level2FirstCategory?.nodeId}`);
      return;
    }
  }

  useEffect(() => {
    setPricesFacet(pricesFacetDefault);
    // eslint-disable-next-line
  }, [somePriceMissing, selectedL3]);

  // todo: temporary solution to fix URLs before bigger refactor
  useEffect(() => {
    if (!selectionL1 || selectionL1 === LOADING) {
      return;
    }
    setLevel2(level2url);
    setLevel3(level3url);
  }, [selectionL1, level1url, level2url, level3url]);

  useEffect(() => {
    if (hasData(universalProductsTree) && !level1) {
      setLevel1(level1url || getDefaultItem());
    }
    const level1Category = getData(universalProductsTree)?.find((c) => c.nodeId === level1);
    const level2FirstCategory = level1Category?.childCategories && level1Category.childCategories[0];
    const level3FirstCategory = level2FirstCategory?.childCategories && level2FirstCategory.childCategories[0];
    const selection: UniversalProductsSelection = {
      level1,
      level2: level2FirstCategory?.nodeId,
      level3: level3FirstCategory?.nodeId,
    };

    // todo: temporary solution to fix URLs before bigger refactor
    if (!level1) {
      return;
    }

    function goToFirstLevel3(level2Category: UniversalProductsCategory) {
      if (!level2Category?.childCategories) {
        return;
      }

      const level3Id = level2Category.childCategories[0].nodeId;
      dispatch(
        setUniversalProductSelection({
          level1,
          level2,
          level3: level3Id,
        }),
      );
      history.push(`${ROUTER_UNIVERSAL_PRODUCTS}/${level1}/${level2}/${level3Id}`);
    }

    if (hasData(selectedLevels) && selectedLevels.level3 && selectedLevels.level1 === level1) {
      if (level3) {
        dispatch(
          setUniversalProductSelection({
            level1,
            level2,
            level3,
          }),
        );
      } else {
        const level2Category = level1Category?.childCategories?.find((c) => c.nodeId === level2);
        if (!level2Category?.childCategories) {
          setSelection(selection, level2FirstCategory, level3FirstCategory);
          return;
        }
        goToFirstLevel3(level2Category);
      }
      return;
    }

    if (hasData(selectedLevels)) {
      if (!selectedLevels.level2 && level2 && !level3) {
        const level2Category = level1Category?.childCategories?.find((c) => c.nodeId === level2);

        if (!level2Category?.childCategories) {
          return;
        }
        goToFirstLevel3(level2Category);
        return;
      }

      if (!selectedLevels.level3 && level3) {
        dispatch(
          setUniversalProductSelection({
            level1,
            level2,
            level3,
          }),
        );
        return;
      }
    }

    setSelection(selection, level2FirstCategory, level3FirstCategory);
    // eslint-disable-next-line
  }, [dispatch, history, level1, level2, level3, universalProductsTree]);
  return (
    <DataContainer data={universalProductsTree} noDataMarginY={50}>
      {hasData(universalProductsTree) && (
        <AltTabs
          onChange={(value: string) => setLevel1(value)}
          defaultActiveKey="universal_products"
          activeKey={level1}
        >
          {hasData(universalProductsTree) &&
            universalProductsTree.map((level1Category) => (
              <Tab
                tab={
                  <MarginBox mr={'10px'} ml={'10px'}>
                    {level1Category.name}
                  </MarginBox>
                }
                key={level1Category.nodeId}
              >
                <Flex align={'flex-start'}>
                  <Flex size={0}>
                    <Category
                      universalProductsTreeLevel2={level1Category.childCategories}
                      level1sel={level1Category.nodeId}
                      showPriceless={showPriceless}
                      setShowPriceless={setShowPriceless}
                      stockFilterOptions={stockFilterOptions}
                      pricesFacet={pricesFacet}
                      pricesRange={pricesRange}
                    />
                  </Flex>
                  <StretchWidth>
                    <UniversalProductsContainer
                      showPriceless={showPriceless}
                      stockFilterOptions={stockFilterOptions}
                      pricesFacet={pricesFacet}
                      setStockFilterOptions={setStockFilterOptions}
                      setPricesFacet={setPricesFacet}
                    />
                  </StretchWidth>
                </Flex>
              </Tab>
            ))}
        </AltTabs>
      )}
    </DataContainer>
  );
};
