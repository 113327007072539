import { TFunction } from 'i18next';
import { notifyTop } from 'UI/Notification/notification';

export const validateFile = (t: TFunction) => (file: File): boolean => {
  if (!file.name.match('.*\\.csv$')) {
    notifyTop(
      'error',
      t('common.file_upload.error', 'Upload error') as string,
      t('common.file_upload.error.wrong_format_csv', 'File is not in .csv format') as string,
    );
    return false;
  }
  if (file.size > 100 * 1024) {
    notifyTop(
      'error',
      t('common.file_upload.error', 'Upload error') as string,
      t('common.file_upload.error.big_size_100_KB', 'File is larger then 100KB') as string,
    );
    return false;
  }
  return true;
};
