import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { CheckCircleIcon, ClipboardClassIcon, HourglassIcon, LocationIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import {
  getLastSearchedVehicleKey,
  getLastSearchVehicleTechnicalData,
  getLastVehicleDetail,
} from 'domains/catalog/Catalog.store';
import {
  FLUIDS_ID,
  FLUIDS_TAB,
  INTERVALS_ID,
  INTERVALS_TAB,
  MaintenanceTabsType,
  OEM_BRAND_KEY,
  OTHER_BRAND_KEY,
} from 'domains/catalog/Catalog.types';
import { fillEstimateFromMaintenancePlan, getEstimateSettings } from 'domains/estimate/Estimate.store';
import { EstimateTabName, EstimateTabParam } from 'domains/estimate/Estimate.types';
import {
  getMaintenancePlanLaborTimes,
  getMaintenancePlanReferences,
} from 'domains/maintenancePlan/MaintenancePlan.mapper';
import {
  fetchIAMChecklistRequestSaga,
  fetchIAMServiceOperationsRequestSaga,
  getIAMServiceChecklist,
  getLastSearchServiceOperations,
  getServiceOperationsReferences,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { getIAMReferences, getPricesMap, ReferencePriceType } from 'domains/references';
import { getCurrency, getIamCatalogBrandsView, getUserRights, setIamCatalogBrandsView, UserRole } from 'domains/user';
import { BottomStickyBox } from 'pages/CatalogPage/IAM/MaintenancePlan/BottomStickyBox';
import { printChecklist } from 'pages/CatalogPage/IAM/MaintenancePlan/ChecklistPdf';
import { ContentOverview } from 'pages/CatalogPage/IAM/MaintenancePlan/MaintenancePlanOverview';
import { printMaintenancePlan } from 'pages/CatalogPage/IAM/MaintenancePlan/MaintenancePlanPdf';
import MaintenancePlanTable from 'pages/CatalogPage/IAM/MaintenancePlan/MaintenancePlanTable';
import RenderOperation from 'pages/CatalogPage/IAM/MaintenancePlan/Operation';
import VehicleCriterias from 'pages/CatalogPage/IAM/MaintenancePlan/VehicleCriterias';
import { theme } from 'styles';
import {
  Box,
  CenteredSpin,
  CenterFlex,
  EstimateButtonRound,
  Flex,
  Icon,
  IconType,
  imageSrcSwitch,
  MarginBox,
  PrintButtonRound,
  Space,
  Steps,
  Text,
  URL,
} from 'UI';
import { getData, hasUserAnyRight, useSmall } from 'utils';
import { MaintenanceCard, SText } from './MaintenancePlan.styled';

interface MaintenancePlanProps {
  setCatalogShowMenu: (v: boolean) => void;
}

interface MaintenanceTabsDataType {
  id: MaintenanceTabsType | 'new';
  icon: IconType;
  title: string;
  description: string;
  action: () => void;
  requiredRights: UserRole[];
}

const getHeader = (tab: MaintenanceTabsType) => {
  const description = (
    <Text type={'text_dim'}>
      <Trans i18nKey={'catalog.maintenance.intervals_fluids.description'}>
        Please, acknowledge fluids and their change intervals information to ensure the longevity and performance of
        your vehicle. All listed information are equivalent to what exists in the paper maintenance log of the vehicle.
      </Trans>
    </Text>
  );
  switch (tab) {
    case INTERVALS_TAB:
      return (
        <Flex>
          <Flex direction={'column'}>
            <Text type={'h1_banner_light'}>
              <Trans i18nKey={'catalog.maintenance.change_intervals.title'}>Change intervals</Trans>
            </Text>
            {description}
          </Flex>
          <Flex size={1} />
        </Flex>
      );
    case FLUIDS_TAB:
      return (
        <Flex>
          <Flex direction={'column'}>
            <Text type={'h1_banner_light'}>
              <Trans i18nKey={'catalog.maintenance.fluids.title'}>Fluids</Trans>
            </Text>
            {description}
          </Flex>
          <Flex size={1} />
        </Flex>
      );
  }
};

const MaintenancePlan = ({ setCatalogShowMenu }: MaintenancePlanProps) => {
  const small = useSmall();
  const { t } = useTranslation();
  const [tab, setTab] = useState<MaintenanceTabsType>(INTERVALS_TAB);
  const [currentStep, setCurrentStep] = useState(0);
  const technicalData = useSelector(getLastSearchVehicleTechnicalData);
  const operations = useSelector(getLastSearchServiceOperations);
  const operationsData = getData(operations);
  const fluids = technicalData?.find((d) => d.id === FLUIDS_ID);
  const intervals = technicalData?.find((d) => d.id === INTERVALS_ID);
  const history = useHistory();
  const dispatch = useDispatch();
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const allReferences = Array.from(getData(useSelector(getServiceOperationsReferences))?.values() ?? []).flat();
  const estimateSettings = useSelector(getEstimateSettings);
  const estimateSettingsData = getData(estimateSettings);
  const serviceChecklist = useSelector(getIAMServiceChecklist);
  const carImageUrl = imageSrcSwitch(URL, vehicleDetail?.imageUrl);
  const userRights = getData(useSelector(getUserRights));
  const selectedReferencesByOperation = useSelector(getServiceOperationsReferences);
  const referencesPrices = useSelector((state: RootState) =>
    getPricesMap(
      state,
      allReferences.map((r) => r.referenceNumber),
    ),
  );
  const referencesPricesData = new Map<string, undefined | ReferencePriceType>(
    Array.from(referencesPrices.entries()).map(([key, value]) => [key, getData(value)]),
  );

  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const referencesData = useSelector((state: RootState) =>
    getIAMReferences(state, {
      referenceNumbers: allReferences.map((r) => r.referenceNumber),
      vehicleKey,
    }),
  );
  const currency = useSelector(getCurrency);
  const iamCatalogBrandsView = useSelector(getIamCatalogBrandsView);

  useEffect(() => {
    if (iamCatalogBrandsView === OEM_BRAND_KEY) {
      dispatch(setIamCatalogBrandsView(OTHER_BRAND_KEY));
    }
  }, [dispatch, iamCatalogBrandsView]);

  useEffect(() => {
    //when returning back to step 0 set first tab
    if (currentStep === 0) {
      setTab(INTERVALS_TAB);
    }
  }, [currentStep]);

  useEffect(() => {
    setCatalogShowMenu(false);
    return () => {
      setCatalogShowMenu(true);
    };
  }, [setCatalogShowMenu]);

  useEffect(() => {
    serviceChecklist &&
      serviceChecklist.searchStatus === 'found' &&
      printChecklist(serviceChecklist?.data, vehicleDetail, carImageUrl);
  }, [serviceChecklist, vehicleDetail, carImageUrl]);

  function handleValidate() {
    if (!vehicleDetail) return;
    const references = getMaintenancePlanReferences(allReferences, referencesData, referencesPrices);
    const laborTimes = getMaintenancePlanLaborTimes(operationsData, estimateSettings);
    dispatch(fillEstimateFromMaintenancePlan({ vehicleDetail, references, laborTimes }));
    const params = new URLSearchParams();
    params.set(EstimateTabParam, EstimateTabName);
    history.push(`${ROUTER_ESTIMATE}?${params}`);
  }

  function handlePrintChecklist() {
    dispatch(fetchIAMChecklistRequestSaga());
  }

  function handlePrintMaintenance() {
    printMaintenancePlan(
      operationsData,
      selectedReferencesByOperation,
      referencesPricesData,
      referencesData,
      estimateSettingsData,
      vehicleDetail,
      carImageUrl,
      currency,
    );
  }

  function startMaintenancePlan() {
    if (
      hasUserAnyRight([UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS], userRights)
    ) {
      dispatch(fetchIAMServiceOperationsRequestSaga());
    }
    setCurrentStep(1);
  }

  const maintenanceTabsData: MaintenanceTabsDataType[] = [
    {
      id: INTERVALS_TAB,
      icon: HourglassIcon,
      title: t('catalog.maintenance.change_intervals.title', 'Change intervals'),
      description: t(
        'catalog.maintenance.change_intervals.description',
        'Replacement intervals recommended by manufacturer.',
      ),
      action: () => setTab(INTERVALS_TAB),
      requiredRights: [],
    },
    {
      id: FLUIDS_TAB,
      icon: LocationIcon,
      title: t('catalog.maintenance.fluids.title', 'Fluids'),
      description: t(
        'catalog.maintenance.fluids.description',
        'Car fluids that need to be flushed and replenished in your vehicle.',
      ),
      action: () => setTab(FLUIDS_TAB),
      requiredRights: [UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
    },
    {
      id: 'new',
      icon: ClipboardClassIcon,
      title: t('catalog.maintenance.new_maintenance_plan.title', 'New Maintenance plan'),
      description: t(
        'catalog.maintenance.new_maintenance_plan.description',
        'Create a complete maintenance plan for your car.',
      ),
      action: () => startMaintenancePlan(),
      requiredRights: [UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
    },
  ];

  const getTechnicalData = () => {
    switch (tab) {
      case FLUIDS_TAB:
        return fluids;
      default:
        return intervals;
    }
  };

  const renderContent = () => {
    return (
      <>
        {getHeader(tab)}
        <Box height={50} />
        <Space size={30} direction={small ? 'vertical' : 'horizontal'}>
          {maintenanceTabsData.map((item) => {
            const isActive = item.id === tab;
            return (
              hasUserAnyRight(item.requiredRights, userRights) && (
                <MaintenanceCard
                  key={item.id}
                  active={isActive}
                  onClick={item.action}
                  minWidth={250}
                  maxWidth={250}
                  minHeight={250}
                  maxHeight={250}
                  direction={'column'}
                  align={'center'}
                >
                  <CenterFlex
                    style={{
                      width: '90px',
                      height: '90px',
                      backgroundColor: isActive ? theme.color.info : theme.color.ice_blue,
                    }}
                  >
                    <Icon IconComponent={item.icon} size={24} color={isActive ? theme.color.white : theme.color.info} />
                  </CenterFlex>
                  <MarginBox mt={15} />
                  <Text type={isActive ? 'dark_14_white_bold' : 'text_dim_bold'}>{item.title}</Text>
                  <MarginBox mt={15} />
                  <SText type={isActive ? 'dark_14_white_65' : 'search_result_base'}>{item.description}</SText>
                </MaintenanceCard>
              )
            );
          })}
        </Space>
        <MarginBox mt={30} />
        <MaintenancePlanTable tab={tab} technicalData={getTechnicalData()} />
        {hasUserAnyRight(
          [UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
          userRights,
        ) && (
          <BottomStickyBox
            text1={t('catalog.maintenance.new_maintenance_plan.start_title', 'Start new maintenance plan')}
            text2={t('catalog.maintenance.new_maintenance_plan.validate_maintenance', 'Validate Maintenance')}
            callback={() => startMaintenancePlan()}
            buttonLabel={t('catalog.maintenance.new_maintenance_plan.start', 'Start')}
          />
        )}
      </>
    );
  };

  const renderContentServiceOperations = () => {
    return (
      <>
        <Text type={'h1_banner_light'}>
          <Trans i18nKey={'catalog.maintenance.operations.title'}>Operations</Trans>
        </Text>
        <Box>
          <Text type={'light_14_black_85'}>
            <Trans i18nKey={'catalog.maintenance.operations.description'}>
              You’ll find below, recommended Maintenance Plan according to manufacturer specifications for the selected
              time period or kilometers, whichever occurs first.
            </Trans>
          </Text>
        </Box>
        <MarginBox mt={38} />
        <VehicleCriterias vehicleDetail={vehicleDetail} />

        <DataContainer
          data={operations}
          NotFound={() => (
            <Text type={'light_14_black_85'}>
              <Trans i18nKey={'catalog.maintenance.operations.not_found'}>Operations not found</Trans>
            </Text>
          )}
          ErrorState={() => (
            <Text type={'light_14_black_85'}>
              <Trans i18nKey={'catalog.maintenance.operations.error_loading'}>Error loading operations</Trans>
            </Text>
          )}
          Loading={() => (
            <>
              <MarginBox mt={38} />
              <CenteredSpin size={'large'} />
              <MarginBox mt={38} />
            </>
          )}
        >
          <Text type={'h1_banner_light'}>
            <Trans i18nKey={'catalog.maintenance.operations.selected'}>Selected operations</Trans>
          </Text>
          <MarginBox mt={20} mb={10}>
            <Text type={'light_14_black_85'}>
              <Trans i18nKey={'catalog.maintenance.operations.description_selected'}>
                Operations which were selected by the proposal service.
              </Trans>
            </Text>
          </MarginBox>

          {/* main selected operations */}
          {operationsData?.mainOperations?.map((o) =>
            o.operations
              .filter((op) => op.selected)
              .map((operation) => (
                <RenderOperation isMain key={operation.id} operation={operation} categoryLabel={o.label} />
              )),
          )}
          {/* additional selected operations */}
          <MarginBox mt={38} />
          {operationsData?.additionalOperations?.map((o) =>
            o.operations
              .filter((op) => op.selected)
              .map((operation) => (
                <RenderOperation isMain={false} key={operation.id} operation={operation} categoryLabel={o.label} />
              )),
          )}
          <MarginBox mt={20} />
          <Text type={'h1_banner_light'}>
            <Trans i18nKey={'catalog.maintenance.operations.other'}>Other operations</Trans>
          </Text>
          <MarginBox mt={20} mb={10}>
            <Text type={'light_14_black_85'}>
              <Trans i18nKey={'catalog.maintenance.operations.description_other'}>
                Operations which were not selected by the proposal service.
              </Trans>
            </Text>
          </MarginBox>

          {/* main unselected operations */}
          {operationsData?.mainOperations?.map((o) =>
            o.operations
              .filter((op) => !op.selected)
              .map((operation) => (
                <RenderOperation isMain key={operation.id} operation={operation} categoryLabel={o.label} />
              )),
          )}
          {/* additional unselected operations */}
          <MarginBox mt={38} />
          {operationsData?.additionalOperations?.map((o) =>
            o.operations
              .filter((op) => !op.selected)
              .map((operation) => (
                <RenderOperation isMain={false} key={operation.id} operation={operation} categoryLabel={o.label} />
              )),
          )}
          <MarginBox mt={38} />
          <BottomStickyBox
            text1={t('catalog.maintenance.new_maintenance_plan.validate_maintenance_plan', 'Validate Maintenance plan')}
            text2={t(
              'catalog.maintenance.new_maintenance_plan.selected_operations_added',
              'Selected Operations and parts will automatically be added to new estimate.',
            )}
            callback={() => setCurrentStep(2)}
            buttonLabel={t('catalog.maintenance.new_maintenance_plan.validate', 'Validate')}
          />
        </DataContainer>
      </>
    );
  };

  return (
    <>
      <MarginBox my={30}>
        <Box height={60}>
          <Flex size={1} justify={'flex-end'}>
            {currentStep === 2 && (
              <>
                <MarginBox my={'auto'} mr={30}>
                  <PrintButtonRound onClick={handlePrintMaintenance} />
                </MarginBox>
                <MarginBox my={'auto'} mr={30}>
                  <EstimateButtonRound onClick={handleValidate} />
                </MarginBox>
              </>
            )}
          </Flex>
          <MarginBox mb={10} />
        </Box>
        <Steps
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          clickable
          data={[
            { title: t('catalog.maintenance.maintenance_data', 'Maintenance data') },
            { title: t('catalog.maintenance.operations', 'Operations') },
            { title: t('catalog.maintenance.overview', 'Overview') },
            {
              title: ' ',
              icon: <Icon IconComponent={CheckCircleIcon} display={'flex'} />,
            },
          ]}
        />
      </MarginBox>
      {currentStep === 0 && renderContent()}
      {currentStep === 1 && renderContentServiceOperations()}
      {currentStep === 2 && <ContentOverview handlePrint={handlePrintChecklist} handleValidate={handleValidate} />}
    </>
  );
};
export default MaintenancePlan;
