import styled from 'styled-components';
import { defaultBoxShadow } from 'UI';

//todo: universal products are broken now, fix positioning - margins, paddings etc., create HOC - @Jakub
export const SContainer = styled.div`
  display: flex;
  flex: 0;
  min-width: 300px;
  padding: 23px 0 0;
  border-radius: 4px;
  ${defaultBoxShadow};
  background-color: ${({ theme }) => theme.color.white};
`;
