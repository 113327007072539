import React from 'react';
import { useSelector } from 'react-redux';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { CATALOG, ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { CarSmileIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { MenuItem } from 'components/Page/PageSideBar/PageSideBar.styled';
import PartsCatalog from 'components/Page/PageSideBar/SideBarCarCatalog/PartsCatalog';
import { TextWrapper } from 'components/ProfileInfo/ProfileInfo';
import { getExplodedIAMTree, getExplodedOEMTree, getExplodedTree } from 'domains/catalog/Catalog.store';
import { OEM_BRAND_KEY } from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { getIamCatalogBrandsView } from 'domains/user';
import { Flex, Icon, Link, MarginBox, Text, WithTooltip } from 'UI';

const getTitle = (vehicleDetail: VehicleDetail) => {
  const isIAM = vehicleDetail.catalogSource === 'IAM';
  if (isIAM) {
    return `${vehicleDetail.model} ${vehicleDetail.iamVehicle?.version ?? ''}`;
  }
  if (vehicleDetail.vehicleIdentifiedBy.length > 17) {
    return `${vehicleDetail.model} ${vehicleDetail.dataHubVehicle?.engine ?? ''}`;
  }
  return vehicleDetail.vehicleIdentifiedBy;
};

const VehicleHeader = ({ vehicleDetail }: { vehicleDetail: VehicleDetail }) => {
  return (
    <TextWrapper gap={5}>
      <Icon IconComponent={CarSmileIcon} size={24} ml={10} />
      <Text className={'menu-item-title'} type={'text_dim'} transform={'capitalize'} noWrap ellipsis>
        {getTitle(vehicleDetail)}
      </Text>
    </TextWrapper>
  );
};

const SideBarCarCatalog = ({ vehicleDetail }: { vehicleDetail: VehicleDetail }) => {
  const isIAM = vehicleDetail.catalogSource === 'IAM';
  const iamCatalogView = useSelector(getIamCatalogBrandsView);
  const isOEM = iamCatalogView === OEM_BRAND_KEY;

  const tree = useSelector((state: RootState) => {
    if (isIAM && isOEM) {
      return getExplodedOEMTree(state, vehicleDetail.vehicleKey);
    }
    if (isIAM) {
      return getExplodedIAMTree(state, vehicleDetail.vehicleKey);
    }
    return getExplodedTree(state, vehicleDetail.vehicleKey);
  });

  const getCatalogLink = () => {
    return vehicleDetail
      ? `${getCatalogSourceUrl(vehicleDetail.catalogSource)}/${vehicleDetail.vehicleKey}${ROUTER_CATALOG_VEHICLE}`
      : '/';
  };

  return (
    <DataContainer data={tree?.searchStatus}>
      <Flex direction={'column'}>
        <WithTooltip title={getTitle(vehicleDetail)} placement={'right'}>
          <div>
            <Link to={getCatalogLink()} dataCy={'vin-banner'}>
              <MenuItem $active={location.pathname.includes(CATALOG)}>
                <VehicleHeader vehicleDetail={vehicleDetail} />
              </MenuItem>
            </Link>
          </div>
        </WithTooltip>
        <MarginBox mt={25} />
        <PartsCatalog treeData={tree?.data ?? []} vehicleDetail={vehicleDetail} />
      </Flex>
    </DataContainer>
  );
};

export default SideBarCarCatalog;
