export const breakpoint = {
  xs: 0,
  sm: 360,
  md: 766,
  lg: 1024,
  xl: 1440,
  xxl: 1920,
};

export const breakpointXlMid = 1600;
