import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import {
  getStockFilterOptions,
  isFilteredStocks,
  isInRange,
  RangeFacetLocal,
  TextFacetLocal,
} from 'components/Filter/Filter.types';
import FilterQuantity from 'components/Filter/FilterQuantity';
import FilterTags from 'components/Filter/FilterTags';
import Loader from 'components/Loader';
import {
  getUniversalProductsFilter,
  getUniversalProductsReferencesLevel3,
  setUniversalProductsRangeFilter,
  setUniversalProductsTextFilter,
} from 'domains/catalog/Catalog.store';
import { getLoadedPrices, getStocksMap, ReferencePriceType } from 'domains/references';
import { useFetchReferencesStocks } from 'domains/references/References.requests';
import { GarageView, getSparePartsView } from 'domains/user';
import { isConnected } from 'domains/webSockets/WebSocket.store';
import { UniversalProducts } from 'pages/UniversalProductsPage/UniversalProducts';
import { Container, defaultPadding, MarginBox } from 'UI';
import ViewTabs from 'UI/Tabs/ViewTabs';
import { getData, hasData, isLoading, NO_DATA } from 'utils';

export const StretchWidth = styled.div`
  width: 100%;
`;

export const UniversalProductsContainer = ({
  showPriceless,
  stockFilterOptions,
  pricesFacet,
  setStockFilterOptions,
  setPricesFacet,
}: {
  showPriceless: boolean;
  stockFilterOptions: TextFacetLocal;
  pricesFacet: RangeFacetLocal;
  setStockFilterOptions: (s: TextFacetLocal) => void;
  setPricesFacet: (p: RangeFacetLocal) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sparePartsView = useSelector(getSparePartsView);
  const products = useSelector(getUniversalProductsReferencesLevel3);
  const isWsConnected = useSelector(isConnected);
  const productsData = getData(products);
  const loadedPrices = useSelector((state: RootState) => getLoadedPrices(state, productsData ?? []));
  const loadedStocks = useSelector((state: RootState) => getStocksMap(state, productsData ?? []));
  const filters = useSelector(getUniversalProductsFilter);
  const stocks = useSelector((state: RootState) => getStocksMap(state, productsData ?? []));
  const stockStatuses = useMemo(() => [...stocks.values()].map((s) => s?.searchStatus), [stocks]);
  const [someStockMissingFirstLoad, setSomeStockMissingFirstLoad] = useState(true);
  const [displayReferences, setDisplayReferences] = useState<string[]>([]);

  useEffect(() => {
    if (someStockMissingFirstLoad && stockStatuses.length > 0) {
      setSomeStockMissingFirstLoad(stockStatuses.some((sp) => !sp || isLoading(sp)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockStatuses]);

  useEffect(() => {
    if (!isWsConnected) {
      setSomeStockMissingFirstLoad(true);
    }
  }, [isWsConnected]);

  useFetchReferencesStocks(getData(products) ?? []);

  useEffect(() => {
    const numberFilter = (item: { reference: string; prices?: ReferencePriceType | NO_DATA }) => {
      return Number(
        sparePartsView === GarageView
          ? getData(getData(item)?.prices)?.garageView?.vatExcludedPrice
          : getData(getData(item)?.prices)?.clientView?.recommendedPriceVatIncluded,
      );
    };
    const priceFilterRange = filters.rangeFilters.get('prices');
    const pricesInRange = loadedPrices
      .filter((price) => {
        const viewPrice = numberFilter(price);
        if (!priceFilterRange) {
          return true;
        }
        return !(
          isInRange([String(viewPrice)], { min: priceFilterRange.min, max: priceFilterRange.max }) ||
          (!hasData(price) && showPriceless)
        );
      })
      .map((p) => p.reference);
    const filteredItems = pricesInRange.filter((ref) => {
      const stock = loadedStocks.get(ref);
      const filterStocks = filters.textFilters.get('delivery');
      return filterStocks ? isFilteredStocks(stock, filters, t) : true;
    });
    setDisplayReferences(filteredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, products, loadedPrices]);

  useEffect(() => {
    const updStockOptions = {
      ...stockFilterOptions,
      items: stockFilterOptions.items.map((sfo) => {
        const resultItems = displayReferences.filter((reference) => {
          const stock = stocks.get(reference);
          const tfiTextFilter = new Map();
          tfiTextFilter.set('delivery', [sfo.label]);
          return isFilteredStocks(stock, { textFilters: tfiTextFilter, rangeFilters: new Map() }, t);
        });
        return { ...sfo, numberOfItems: resultItems.length };
      }),
    };
    const updPricesFacet = {
      ...pricesFacet,
      numberOfItems: displayReferences.filter((ref) => stocks.get(ref)).length,
    };

    setStockFilterOptions(updStockOptions);
    setPricesFacet(updPricesFacet);
    // eslint-disable-next-line
  }, [displayReferences]);

  useEffect(() => {
    const stocksFilterOptions = getStockFilterOptions('UNIVERSAL_PRODUCTS', true);
    const getItems = () => {
      return stocksFilterOptions.items.filter((soi) => {
        return [...stocks.values()].some((s) => {
          const soiTextFilter = new Map();
          soiTextFilter.set('delivery', [soi.label]);
          return isFilteredStocks(s, { textFilters: soiTextFilter, rangeFilters: new Map() }, t);
        });
      });
    };
    const options = {
      ...stocksFilterOptions,
      items: getItems().map((sfo) => {
        const resultItems = displayReferences.filter((reference) => {
          const stock = stocks.get(reference);
          const tfiTextFilter = new Map();
          tfiTextFilter.set('delivery', [sfo.label]);
          return isFilteredStocks(stock, { textFilters: tfiTextFilter, rangeFilters: new Map() }, t);
        });
        return { ...sfo, numberOfItems: resultItems.length };
      }),
    };
    setStockFilterOptions(options);
    // eslint-disable-next-line
  }, [someStockMissingFirstLoad, isWsConnected]);

  const getProductsStatus = () => {
    if (!products || !(displayReferences.length > 0 && displayReferences.every((r) => products?.includes(r)))) {
      return undefined;
    }
    return products;
  };
  return (
    <Container padding={defaultPadding} alignText={'left'} size={undefined}>
      {hasData(displayReferences) ? (
        <MarginBox mt={30}>
          <FilterTags
            filters={filters}
            setTextFilters={(id, item) => dispatch(setUniversalProductsTextFilter({ id, item }))}
            setRangeFilters={(id, range) => dispatch(setUniversalProductsRangeFilter({ id, range }))}
          />
          <MarginBox my={15} />
          <MarginBox mb={-30}>
            <FilterQuantity numberOfProducts={displayReferences.length} />
          </MarginBox>
          <StretchWidth>
            <ViewTabs />
            <DataContainer data={getProductsStatus()}>
              <UniversalProducts sparePartsView={sparePartsView} references={displayReferences} />
            </DataContainer>
          </StretchWidth>
        </MarginBox>
      ) : (
        <Loader />
      )}
    </Container>
  );
};
