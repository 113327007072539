import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { VehicleIAMPartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehicleIAMPartCategoryTreeItem';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Popover } from 'antd';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { MenuItem } from 'components/Page/PageSideBar/PageSideBar.styled';
import Megamenu from 'components/Page/PageSideBar/SideBarCarCatalog/Megamenu';
import { TextWrapper } from 'components/ProfileInfo/ProfileInfo';
import { formatCategoryLabelToDataCy, getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { theme } from 'styles';
import { Flex, Text } from 'UI';

export interface PartsCatalogType {
  treeData: VehiclePartCategoryTreeItem[] | VehicleIAMPartCategoryTreeItem[];
  vehicleDetail: VehicleDetail;
}

export interface CarPartsSubmenuCategoriesType extends PartsCatalogType {
  level1Label: string;
  level1NodeId: string;
  setActiveCategory: (s: string) => void;
}

const PartsCatalog = ({ treeData, vehicleDetail }: PartsCatalogType) => {
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState<string>('');
  const history = useHistory();

  const handleLevel1Click = (nodeId: string) => {
    const link = `${getCatalogSourceUrl(vehicleDetail.catalogSource)}/${
      vehicleDetail.vehicleKey
    }${ROUTER_CATALOG_VEHICLE}/${nodeId}`;
    history.push(link);
  };
  return (
    <Flex direction={'column'} gap={10} dataCy={'parts-catalog'}>
      <Text type={'h5_bold'}>{t('catalog.catalog_parts.title', 'Parts Catalog')}</Text>
      {treeData.map((level1) => {
        return (
          <Popover
            key={level1.nodeId}
            id={level1.nodeId}
            placement={'right'}
            destroyTooltipOnHide
            content={
              activeCategory !== '' && (
                <Megamenu
                  vehicleDetail={vehicleDetail}
                  treeData={level1.items}
                  level1Label={level1.label}
                  level1NodeId={level1.nodeId}
                  setActiveCategory={setActiveCategory}
                />
              )
            }
            color={theme.color.grey95_light_120}
            onVisibleChange={(v) => !v && setActiveCategory('')}
            visible={activeCategory === level1.nodeId}
            overlayInnerStyle={{ boxShadow: `0px 4px 18px 4px ${theme.color.grey70}` }}
          >
            <MenuItem
              key={level1.nodeId}
              $hoverActive={activeCategory === level1.nodeId}
              onMouseEnter={() => setActiveCategory(level1.nodeId)}
              submenu
              onClick={() => handleLevel1Click(level1.nodeId)}
              data-cy={formatCategoryLabelToDataCy(level1.label, 'tab')}
            >
              <TextWrapper maxHeight={44}>
                <Text type={'text_dim'} transform={'capitalize'}>
                  {level1.label}
                </Text>
              </TextWrapper>
            </MenuItem>
          </Popover>
        );
      })}
    </Flex>
  );
};

export default PartsCatalog;
