/* eslint-disable max-len */
import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UniversalProduct as UniversalProductType } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsResponse';
import { ROUTER_CART } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { addReferenceByRefNumberRequest } from 'domains/basket/Basket.store';
import { getIsStockAvailable } from 'domains/references';
import { SparePartsViewType } from 'domains/user';
import { NotificationLink, notifyTop } from 'UI';
import { ProductPreview } from 'UI/ProductPreview';
import { getData, NO_DATA } from 'utils';
import { TRACKING_EVENT_GO_TO_CART_SHORTCUT } from 'utils/eventTracker/EventTracker.types';

export interface UniversalProductProps {
  sparePartsView: SparePartsViewType;
  refLocal: UniversalProductType | NO_DATA;
}

export const UniversalProduct = ({ refLocal, sparePartsView }: UniversalProductProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const universalProductReference = getData(refLocal);
  const referenceNumber = getData(refLocal)?.referenceNumber;
  const imageUrl = universalProductReference?.imageKey;
  const availableInUserCountry = useSelector((state: RootState) => getIsStockAvailable(state, referenceNumber));

  if (!universalProductReference) return null;

  const handleAddToCartClick = () => {
    if (referenceNumber) {
      notifyTop(
        'success',
        <Trans i18nKey={'catalog.universal_products.reference_card.added_to_basket.description'}>
          {'Universal product has been added to your cart'}
        </Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            trackAppEvent(TRACKING_EVENT_GO_TO_CART_SHORTCUT);
            history.push(ROUTER_CART);
          }}
        >
          <Trans i18nKey={'catalog.reference_card.added_to_basket.go_to_cart'}>{'Go to cart'}</Trans>
        </NotificationLink>,
      );
      dispatch(
        addReferenceByRefNumberRequest({
          reference: referenceNumber,
        }),
      );
    }
  };

  return (
    <ProductPreview
      imageUrl={imageUrl}
      productName={universalProductReference.productName}
      referenceNumber={referenceNumber}
      sparePartsView={sparePartsView}
      handleAddToCart={handleAddToCartClick}
      availableInUserCountry={availableInUserCountry}
    />
  );
};
