import React from 'react';
import { Modal as AntModal } from 'antd-v5';
import styled from 'styled-components';
import { MarginBox } from 'UI/Box';
import { useBreakpointSelectorFull } from 'utils';

export const SModal = styled(AntModal)`
  .ant-modal-header {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .ant-modal-footer {
    border-top: 0;
  }

  padding: 15px 0;
`;

export interface CustomModalProps {
  children?: React.ReactNode;
  title?: React.ReactNode | string;
  open?: boolean;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  footer?: React.ReactNode;
  width?: string | number;
  closable?: boolean;
  maskClosable?: boolean;
  className?: string;
  zIndex?: number;
  getContainer?: HTMLElement | string;
}

export const Modal = ({
  children,
  title,
  open,
  onCancel,
  footer,
  width,
  closable = false,
  maskClosable = true,
  className,
  zIndex = 9000,
  getContainer,
}: CustomModalProps) => {
  return (
    <SModal
      title={title}
      open={open}
      onCancel={onCancel}
      footer={footer}
      width={width}
      zIndex={zIndex}
      centered
      closable={closable}
      className={className}
      destroyOnClose
      maskClosable={maskClosable}
      getContainer={getContainer}
    >
      <MarginBox mx={20}>{children}</MarginBox>
    </SModal>
  );
};

export const ModalConstWidth = ({
  children,
  title,
  open,
  onCancel,
  footer,
  closable = false,
  className,
}: CustomModalProps) => {
  const width = useBreakpointSelectorFull()({ xs: 720, sm: 720, md: 720, lg: 924, xl: 1340, xxl: 1820 });
  return (
    <SModal
      title={title}
      open={open}
      onCancel={onCancel}
      footer={footer}
      width={width}
      zIndex={9000}
      centered
      closable={closable}
      className={className}
      style={{ minWidth: width }}
      destroyOnClose
    >
      <MarginBox mx={20}>{children}</MarginBox>
    </SModal>
  );
};
