import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, emptyPadding, Flex, indentPadding, MarginBox, Text } from 'UI';
import { Level1 } from './Level1';
import { SBackground } from './UniversalProductsPage.styled';

const UniversalProductsPage = () => {
  const { t } = useTranslation();

  return (
    <Container padding={emptyPadding} size={'xxl'}>
      <Flex direction={'column'}>
        <SBackground image={'universalProducts/universal-products.jpg'}>
          <Flex direction={'row'} justify={'flex-end'}>
            <MarginBox mr={'60px'} mt={'95px'}>
              <Text type={'h1_white'}>
                {t('catalog.universal_products.universal_accessories', 'Universal accessories')}
              </Text>
            </MarginBox>
          </Flex>
        </SBackground>
        <Container padding={indentPadding} size={'xxl'}>
          <Level1 />
        </Container>
      </Flex>
    </Container>
  );
};

export default UniversalProductsPage;
