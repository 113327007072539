import { useSelector } from 'react-redux';
import { TargetPage } from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/InformationBanner';
import { RootState } from 'app/AppStore';
import { BANNER_HEIGHT } from 'components/Page/Header/AdvertisementSpace/InformationBanner';
import { getBannersCount } from 'domains/information/Information.store';

export const OFFSET_HEADER = 90;
export const OFFSET_SUB_HEADER = 48;
export const OFFSET_LINK_TABS = 40;
export const OFFSET_VEHICLE_INFO = 70;
export const OFFSET_PADDING = 15;

export const useOffsetTop = (page: TargetPage | 'ESTIMATE' | 'MY_STORE') => {
  const isBanners = useSelector((state: RootState) => getBannersCount(state, page)) > 0;

  return OFFSET_HEADER + (isBanners ? BANNER_HEIGHT : 0);
};
