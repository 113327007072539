import React, { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Image } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Promotion';
import { RootState } from 'app/AppStore';
import { getPicture } from 'domains/pictures/Pictures.store';
import { PromotionBackgroundImage } from 'pages/PromotionPage/PromotionCardsContainer/PromotionCard/PromotionCard.styled';
import { hasData } from 'utils';

const PREFIX = 'promotion/default-images';

const PROMOTION_DEFAULT_IMAGES = [`${PREFIX}/image-0.png`, `${PREFIX}/image-1.png`, `${PREFIX}/image-2.png`];

const PROMOTION_DEFAULT_THUMBNAIL_IMAGES = [
  `${PREFIX}/thumbnail-image-0.png`,
  `${PREFIX}/thumbnail-image-1.png`,
  `${PREFIX}/thumbnail-image-2.png`,
];

export const useGetPromotionImageFromUrl = ({ imageUrl, thumbnail }: { imageUrl?: string; thumbnail?: boolean }) => {
  const backgroundSrc = useMemo(
    () =>
      hasData(imageUrl)
        ? imageUrl
        : getRandomImage(thumbnail ? PROMOTION_DEFAULT_THUMBNAIL_IMAGES : PROMOTION_DEFAULT_IMAGES),
    [imageUrl, thumbnail],
  );

  if (imageUrl) {
    return `'${imageUrl}'`;
  }
  return `'/images/${backgroundSrc}'`;
};
export const useGetPromotionImage = ({ image, thumbnail }: { image?: Image; thumbnail?: boolean }) => {
  const promotionImage = useSelector((state: RootState) => getPicture(state, image?.key ?? undefined));
  const backgroundSrc = useMemo(
    () =>
      hasData(promotionImage)
        ? promotionImage
        : getRandomImage(thumbnail ? PROMOTION_DEFAULT_THUMBNAIL_IMAGES : PROMOTION_DEFAULT_IMAGES),
    [promotionImage, thumbnail],
  );
  return `'${hasData(promotionImage) ? promotionImage : '/images/'}${backgroundSrc}'`;
};

function getRandomImage(imagesArray: string[]): string {
  const randomImageIndex = Math.floor(Math.random() * 3);
  return imagesArray[randomImageIndex];
}

interface PromotionCardImage extends PropsWithChildren {
  imageUrl?: string;
}

const PromotionCardImage = ({ imageUrl, children }: PromotionCardImage) => {
  const background = useGetPromotionImageFromUrl({ imageUrl });
  return <PromotionBackgroundImage background={background}>{children}</PromotionBackgroundImage>;
};

export default PromotionCardImage;
